import React, { useEffect } from "react";
import Lottie from "lottie-web";
import { useNavigate } from "react-router-dom";

import Transaction_success from "../../assets/subscription-check/Transaction_success.json";
import "./style.css";

const TransactionSuccess = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#transaction-success"),
      animationData: Transaction_success
    });
  }, []);

  const handleClick = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <div className="transaction-animation">
        <div className="transaction-success-page">
          <div id="transaction-success" className="transaction-success-animation col-6 col-sm-6"></div>
          {/* <div className=" col-6 col-sm-6 d-flex align-items-center justify-content-center">
          <div> */}
          <h3 className="transaction-success-header pt-30 my-5 text-center">
            "Thank you for subscribing!" We are happy to have you on board.
          </h3>
          <button className="orange-gradient-auth-button mt-2 transaction-success-btn" onClick={handleClick}>
            Go to Dashboard
          </button>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default TransactionSuccess;
