import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import FacebookAuth from "react-facebook-auth";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import Select from "react-select";

import Google from "../assets/auth/Google.png";
import Facebook from "../assets/auth/Facebook.png";
import countryCode from "../pages/profile/data/countryCode";
import "./style.css";
import PasswordChecklist from "./PasswordChecklist";

const Form = ({ onSubmit, handleLogin, googlelogin, supportUs, signUpDisableStatus }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="d-flex align-items-center justify-content-start gap-2">
      <img src={data.flagUrl} alt={label} className="sign-up-flag-icon" />
      <span className="country-code-lable">{label}</span>
    </div>
  );

  // validation schema start
  const validationSchema = Yup.object().shape({
    // type: Yup.string()
    //   .required("Please select an account type")
    //   .oneOf(["business", "individual"], "Please select a valid account type"),
    full_name: Yup.string()
      .test("is-not-empty", "Please enter Full name", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your Full name"),
    email: Yup.string()
      .email("Please enter email address")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter email address")
      .required("Please enter your email address"),
    country_code: Yup.string().required("Please enter the country code"),
    mobile_number: Yup.string()
      .test("is-not-empty", "Please enter mobile number.", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your mobile number"),
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^&#])[A-Za-z\d@$!%*^&#]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character [!, @, #, $, %, ^, &, or *]"
      )
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match")
  });

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#80bdff" : "#ced4da", // Customize border color when focused
      borderRadius: "10px",
      height: "48px",
      width: "100%"
    }),
    indicatorsContainer: (base) => ({
      ...base
      // display: "none",
    })
  };

  const togglePasswordVisibility = (state) => {
    if (state === "password") setShowPassword((prev) => !prev);
    else setShowConfirmPassword((prev) => !prev);
  };

  const FacebookLoginButton = ({ onClick }) => (
    <button type="button" className="gbtn" onClick={onClick}>
      <div className="d-flex align-items-center gap-2">
        <img src={Facebook} alt="facebook-logo" className="facebookbutton googlebutton" />
        <span className="pt-16">Facebook</span>
      </div>
    </button>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row justify-content-center">
        <div className={`${!supportUs ? "col-9 col-lg-6" : "col-10"}`}>
          {/* title start */}
          <h2 className="mb-3 formheading text-black">{supportUs ? "Free Trial" : "Sign Up"}</h2>
          {/* title end */}

          {/* <div className="my-4 d-flex gap-3">
          <div className="form-check">
            <input
              autoComplete="off"
              type="radio"
              name="type"
              id="business"
              value="business"
              {...register("type")}
              className={`form-check-input ${errors.type ? "is-invalid" : ""}`}
            />
            <label className="form-check-label pt-12">Business</label>
          </div>
          <div className="form-check">
            <input
              autoComplete="off"
              type="radio"
              name="type"
              id="individual"
              value="individual"
              {...register("type")}
              className={`form-check-input ${errors.type ? "is-invalid" : ""}`}
            />
            <label className="form-check-label pt-12">Individual</label>
          </div>
          {errors.type && <div className="invalid-feedback">{errors.type.message}</div>}
        </div> */}

          {/* Full name text field start */}
          <div className="formbox">
            <label className="form-label pt-16">Full Name</label>
            <input
              autoComplete="off"
              type="text"
              id="full_name"
              placeholder="i.e. John"
              {...register("full_name")}
              className={`form-control form-field pt-16 ${errors.full_name ? "is-invalid" : ""}`}
            />
            {errors.full_name && <div className="invalid-feedback pt-16">{errors.full_name.message}</div>}
          </div>
          {/* Full name text field end */}

          {/* Email field start */}
          <div className="formbox">
            <label className="form-label pt-16">Email</label>
            <input
              autoComplete="off"
              type="text"
              className={`form-control form-field pt-16 ${errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder="i.e. example@gmail.com"
              {...register("email", { onBlur: () => trigger("email") })}
              inputMode="email"
            />
            {errors.email && <div className="invalid-feedback pt-16">{errors.email.message}</div>}
          </div>
          {/* Email field end */}

          {/* Mobile number field start */}
          <div className="formbox">
            <div className="row bs-gutter-x g-3 d-flex justify-content-center">
              {/* country code start  */}
              <div className="col-5">
                <label className="form-label pt-16">Code</label>
                <div className="code-dropdown w-100 col-4">
                  <Controller
                    name="country_code"
                    control={control}
                    shouldValidate
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        // options={countryCode}
                        options={countryCode.map((option) => ({
                          ...option,
                          label: (
                            <CustomOption
                              innerProps={{}}
                              label={option.label}
                              data={{ flagUrl: option.flagUrl }} // Pass flag URL to custom option
                            />
                          )
                        }))}
                        onChange={(selectedOption) => {
                          setSelectedCountryCode(selectedOption);
                          field.onChange(selectedOption?.value, selectedOption?.flagUrl);
                        }}
                        value={selectedCountryCode}
                        classNamePrefix="react-select"
                        className={`pt-16  ${errors.country_code ? "CountryCode is-invalid" : ""}`}
                        placeholder="i.e. +1"
                        styles={customSelectStyles}
                      />
                    )}
                  />
                  {errors.country_code && (
                    <div className="invalid-feedback pt-16 mt-3">{errors.country_code.message}</div>
                  )}
                </div>
              </div>
              {/* country code end  */}

              {/* mobile number text-box start */}
              <div className="col-7">
                <label className="form-label pt-16">Mobile Number</label>
                <Controller
                  name="mobile_number"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <input
                      autoComplete="off"
                      type="text"
                      className={`form-control form-field pt-16 ${errors.mobile_number ? "is-invalid" : ""}`}
                      placeholder="i.e. 201 1556 122"
                      {...field}
                      maxLength="12"
                      onChange={(e) => {
                        // Remove any non-digit characters from the input
                        const formattedValue = e.target.value.replace(/\D/g, "");

                        // Perform your custom formatting logic here
                        let formattedMobileNumber = "";
                        if (formattedValue.length > 0) {
                          formattedMobileNumber = formattedValue.match(/(\d{0,3})(\d{0,4})(\d{0,3})/);
                          formattedMobileNumber = !formattedMobileNumber[2]
                            ? formattedMobileNumber[1]
                            : `${formattedMobileNumber[1]} ${formattedMobileNumber[2]}` +
                              (formattedMobileNumber[3] ? ` ${formattedMobileNumber[3]}` : "");
                        }

                        // Update the field value with the formatted mobile number
                        field.onChange(formattedMobileNumber);
                      }}
                      inputMode="numeric"
                    />
                  )}
                />
                {errors.mobile_number && <div className="invalid-feedback pt-16">{errors.mobile_number.message}</div>}
              </div>
              {/* mobile number text-box end */}
            </div>
          </div>
          {/* Mobile number field end */}

          {/*Password field start */}

          <div className="formbox">
            <label className="form-label pt-16">Password</label>

            <div className="password-wrapper">
              <input
                autoComplete="off"
                type={showPassword ? "text" : "password"}
                className={`form-control form-field pt-16 ${errors.password ? "is-invalid" : ""}`}
                id="sign-up-password"
                placeholder="at least 8 characters"
                {...register("password", { onBlur: () => trigger("password") })}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => {
                  togglePasswordVisibility("password");
                }}
              >
                {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
              {/* {errors.password && <div className="invalid-feedback pt-16">{errors.password.message}</div>} */}
            </div>
            <PasswordChecklist password={watch("password", "")} />
          </div>
          {/* Password field end */}

          {/*Confirm Password field start */}

          <div className="formbox">
            <label className="form-label pt-16">Confirm Password</label>

            <div className="password-wrapper">
              <input
                autoComplete="off"
                type={showConfirmPassword ? "text" : "password"}
                className={`form-control form-field pt-16 ${errors.confirmPassword ? "is-invalid" : ""}`}
                id="sign-up-password"
                placeholder="at least 8 characters"
                {...register("confirmPassword", { onBlur: () => trigger("confirmPassword") })}
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => togglePasswordVisibility("confirmPassword")}
              >
                {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
              </button>
              {errors.confirmPassword && <div className="invalid-feedback pt-16">{errors.confirmPassword.message}</div>}
            </div>
          </div>
          {/* Confirm Password field end */}
          {/* Referral code field start */}
          <div className="formbox">
            <label className="form-label pt-16">
              Referral Code (optional, only if you have been provided with one)
            </label>
            <input
              autoComplete="off"
              type="text"
              id="refferal_code"
              placeholder="i.e. John10"
              {...register("refferal_code")}
              className={`form-control form-field pt-16 ${errors.refferal_code ? "is-invalid" : ""}`}
            />
            {errors.refferal_code && <div className="invalid-feedback pt-16">{errors.refferal_code.message}</div>}
          </div>
          {/* Referral code field end */}
          {/* Sign Up button start */}
          <div className="formbox">
            <button
              type="submit"
              className="signup-tracking orange-gradient-auth-button pt-20"
              disabled={signUpDisableStatus}
            >
              Sign Up
            </button>
          </div>
          {/* Sign Up button end */}

          {/* or text between hr line start */}
          <div className="d-flex my-4 text-center align-items-center">
            <hr className="flex-grow-1" />
            <span className=" mx-3 pt-16">or</span>
            <hr className="flex-grow-1" />
          </div>
          {/* or text between hr line end */}

          {/* social button section start */}
          <div className="mb-3 text-center d-flex d-none-sm justify-content-center gap-4">
            {/* google button start */}
            <button type="button" className="gbtn" onClick={() => googlelogin()}>
              <div className="d-flex align-items-center gap-2">
                <img src={Google} alt="google-logo" className="googlebutton" />
                <span className="pt-16">Google</span>
              </div>
            </button>
            {/* google button end */}

            {/*Facebook button start  */}
            {/* <FacebookAuth
              appId={process.env.REACT_APP_FACEBOOK_APP_ID}
              autoLoad={true}
              fields="name,email,picture"
              callback={handleLogin}
              cssClass="facebook-button"
              textButton="Facebook"
              component={FacebookLoginButton}
              scope="public_profile, email, user_birthday"
            /> */}
          </div>
          {/*Facebook button end  */}

          {/* social button section end */}

          {/* sign in link start */}
          {!supportUs && (
            <div className=" d-flex justify-content-center accountlink pt-16">
              <span>
                have an account?{" "}
                <Link to="/sign-in" className="link">
                  Sign in
                </Link>
              </span>
            </div>
          )}
          {/* sign in link end */}
          <div className="copyright footer d-flex justify-content-center mb-3">
            &copy; {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
