import apiClient, { setHeader } from "../utils/apiClient";

// const getHistory = (token) => {
//   return apiClient.get("/user_audio_request/", token);
// };

const getHistory = (token, query = null) => {
  if (query) {
    return apiClient.get(`/user_audio_request?${query}`, token);
  } else {
    return apiClient.get("/user_audio_request", token);
  }
};

const getUserProcessDetails = (token, query) => {
  return apiClient.get(`/user_status?interval=${query}`, token);
};

const deleteAudioRequest = (token, query) => {
  return apiClient.delete(`/user_audio_request?audio_id=${query}`, token);
};

const DashboardService = {
  getHistory,
  getUserProcessDetails,
  deleteAudioRequest
};

export default DashboardService;
