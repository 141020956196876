import React from "react";

import CardShape from "../../../assets/home/card-shape.svg";

const SalesWhatsNew = ({ data }) => {
  return (
    <>
      <section className="whatnewsec bg-orange-gradient p-lg-5 px-2 py-3">
        <div
          className="container text-white text-center aos-init"
          data-aos="fade"
          data-aos-duration="1500"
          data-aos-delay="150"
        >
          <h1 className="fs-60 p-lg-4 p-3 text-capitalize">What's New in Our AI Sales Coach?</h1>
          <div className="row d-flex justify-content-center">
            {data.map((cardData, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12">
                <div className="whatsnewcard">
                  <div className="bgShape">
                    <img src={CardShape} alt="CardShape" className="img-fluid" />
                  </div>
                  <div className="Topicon">
                    <img src={cardData.card_image} alt="CardShape" className="img-fluid" />
                  </div>
                  <div className="cardCnt">
                    <h3>{cardData.title}</h3>
                    <p>{cardData.discription}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default SalesWhatsNew;
