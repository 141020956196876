import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import EditProfile from "../pages/profile/components/EditProfile";
import Notifications from "../pages/profile/components/Notifications";
import Transaction from "../pages/profile/components/Transaction";
import ChangePassword from "../pages/profile/components/ChangePassword";
import SubscriptionDetails from "../pages/profile/components/SubscriptionDetails";
import Integration from "../pages/profile/components/Integration";

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="edit-profile" />} />
      <Route path="/edit-profile" element={<EditProfile />} />
      <Route path="/notifications" element={<Notifications />} />
      <Route path="/transaction" element={<Transaction />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/mysubscription" element={<SubscriptionDetails />} />
      <Route path="/integration" element={<Integration />} />
    </Routes>
  );
};

export default ProfileRoutes;
