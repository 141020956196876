/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import UserNameFilter from "./UserNameFilter";
import StatusFilter from "./StatusFilter";
import TitleFilter from "./TitleFilter";
import DashboardService from "../../../../services/DashboardService";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../redux/authSlice";

const FilterButton = ({ title, setTitle, selectedStatus, setSelectedStatus, userName, setUserName, handleFilter }) => {
  return (
    <div className="filterButton history-filter-pop-up">
      <div className="filterButton dropdown">
        <button
          className="btn btn-secondary text-black dropdown-toggle bg-transparent border-0"
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Filters
        </button>

        <div
          className="dropdown-menu w-auto rounded-3 p-4"
          style={{ minWidth: "350px" }}
          aria-labelledby="dropdownMenuLink"
        >
          <div className="mb-2">
            <UserNameFilter userName={userName} setUserName={setUserName} />
          </div>

          <div className="mb-2">
            <TitleFilter title={title} setTitle={setTitle} />
          </div>

          <div className="mb-2">
            <StatusFilter selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
          </div>

          <div className="d-flex justify-content-between float-end">
            <button className="btn upload-btn primary-orange-gradient-ai-assistant-button" onClick={handleFilter}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
