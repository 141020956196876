import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";

import AuthLayout from "../../../layouts/OuterLayout/AuthLayout";
import "../style.css";
import "../../../constants/styles.css";
import AuthService from "../../../services/Auth";

const ForgotPasswordPage = () => {
  const [forgotPasswordDisableStatus, setForgotPasswordDisableStatus] = useState(false);

  const navigate = useNavigate();

  // validation schema start
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a email")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a email")
      .required("Please enter your email")
  });
  // validation schema end

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    setForgotPasswordDisableStatus(true);
    AuthService.forgetPassword(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success(response?.data?.message);
          navigate("/enter-otp", {
            state: { response: response?.data, email: data.email, type: "FORGOT_PASSWORD_OTP" }
          });
          setForgotPasswordDisableStatus(false);
        } else {
          navigate("/forgot-password");
          setForgotPasswordDisableStatus(false);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error[0]);
        setForgotPasswordDisableStatus(false);
      });
  };
  return (
    <AuthLayout>
      {/* Forgot password page start */}

      <div className="row justify-content-center">
        <h1 className="my-5 d-flex justify-content-center formheading">Forgot password?</h1>
        <div className="mt-5 col-9 col-lg-6">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* Email field start */}
            <div className="mb-3">
              <label className="form-label pt-16">Email</label>
              <input
                type="text"
                className={`form-control form-field pt-16${errors.email ? "is-invalid" : ""}`}
                id="forgot-password-email"
                placeholder="Email"
                {...register("email")}
                inputMode="email"
              />
              {errors.email && <div className="invalid-feedback pt-16">{errors.email.message}</div>}
            </div>
            {/* Email field end */}

            {/* otp send button start */}
            <div className="formbox">
              <button
                type="submit"
                className="orange-gradient-auth-button pt-20"
                disabled={forgotPasswordDisableStatus}
              >
                Send
              </button>
            </div>
            {/* otp send button end */}
          </form>
          <div className="copyrighttext-position">
            <div className="copyright   footer d-flex justify-content-center mb-3">
              &copy; {new Date().getFullYear()} All rights reserved.
            </div>
          </div>
        </div>
      </div>

      {/* Forgot password page end */}
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
