import React from "react";
import { toast } from "react-toastify";

import "./forms.css";
import PCSBook from "../../../assets/Forms/PCSBook.jpeg";
import CommomForm from "./CommomForm";

const CheatSheet = () => {
  return (
    <div className="cheat-sheet-bg p-4">
      <div className="container ">
        <div className="cheat-sheet-content  d-flex justify-content-center align-items-center">
          <div className="row  flex-lg-row ">
            <div className="col-lg-6 col-12 d-flex justify-content-center">
              <div className="d-flex flex-column">
                <img src={PCSBook} alt="" className="img-fluid mb-3 cheat-sheat-image" />
                <h1 className="cheat-sheet-subheading text-center">Cheat-Sheet</h1>
              </div>
            </div>
            <div className="cheat-sheet-form col-lg-6 col-12">
              <h1>Download The Perfect Closing Script Cheat-Sheet</h1>
              <CommomForm element="cheat-sheet" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheatSheet;
