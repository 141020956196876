import React, { useState, createRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import FacebookAuth from "react-facebook-auth";
import axios from "axios";
import { Helmet } from "react-helmet";

import AuthLayout from "../../../layouts/OuterLayout/AuthLayout";
import Google from "../../../assets/auth/Google.png";
import Facebook from "../../../assets/auth/Facebook.png";
import "../style.css";
import { signInUser } from "../../../redux/authSlice";
import AuthService from "../../../services/Auth";
import { setHeader } from "../../../utils/apiClient";
import SupportUs from "../../../layouts/OuterLayout/SupportUs";

const SignIn = () => {
  const [signInDisableStatus, setSignInDisableStatus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  let popupRef = createRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  // Google login function start
  const googlelogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // Fetch additional user details from Google's API
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`
          }
        })
        .then((response) => {
          const { email, given_name, email_verified, sub, family_name, picture } = response.data;

          if (tokenResponse?.access_token !== "") {
            const socialLoginData = {
              login_type: "2",
              email: email,
              password: "",
              full_name: given_name,
              last_name: family_name,
              social_media_id: sub,
              social_media_type: "google",
              is_supporter: true,
              profile_pic: picture
            };

            AuthService.socialLogin(socialLoginData, setHeader())
              .then((response) => {
                const token = response?.data?.data?.token;
                const user = response?.data?.data?.user;
                dispatch(signInUser({ token: token, user: user }));
                // navigate("/pricing");
                // if (response?.data?.data?.user?.active_subscription) {
                // If subscription_id is present, navigate to dashboard
                navigate("/dashboard");
                // } else {
                //   // If subscription_id is null, navigate to pricing
                //   navigate("/pricing");
                // }
                // toast.success(response?.data?.message);
              })
              .catch((error) => {
                if (error?.response?.status === 403) {
                  toast.error(error.response.data.message);
                } else {
                  toast.error(error?.response?.data?.email[0]);
                }
              });
          }
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }
  });
  // Google login function end

  // validation schema start
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Please enter a email")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a  email")
      .required("Please enter your email"),
    password: Yup.string().required("Please enter your password")
  });
  // validation schema end

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onSubmit = (formData) => {
    setSignInDisableStatus(true);
    AuthService.signIn(formData.email, formData.password, setHeader())
      .then((response) => {
        const { status, message, data } = response.data;
        if (status) {
          // Dispatch the login action to store the token and user data in Redux
          dispatch(signInUser({ token: data.token, user: data.user, transaction: data?.transaction }));
          // Navigate to the desired page after successful login
          // navigate("/pricing");
          // if (response?.data?.data?.user?.active_subscription) {
          // If subscription_id is present, navigate to dashboard
          navigate("/dashboard");
          // } else {
          //   // If subscription_id is null, navigate to pricing
          //   navigate("/pricing");
          // }
          // toast.success("Login Successful.");
          setSignInDisableStatus(false);
        } else {
          // Handle the case when login is not successful
          toast.error("Login Unsuccessful.");
          setSignInDisableStatus(false);
        }
      })
      .catch((error) => {
        if (error?.response && error?.response.status === 403) {
          // If the error code is 403, navigate to the "enter-otp" page
          const is_supporter = error?.response?.data?.data?.is_supporter;
          if (is_supporter) {
            toast.error(error.response.data.message);
          } else {
            navigate("/activate-account", {
              state: { email: formData.email, type: "EMAIL_ACTIVATION" }
            });
            toast.error(error.response.data.message);
          }
        } else {
          toast.error("Invalid credentials");
        }
        setSignInDisableStatus(false);
      });
  };

  // Facebook login function start
  // const handleLogin = (response) => {
  //   const { name, email, id } = response;

  //   const [firstName, ...lastNameArray] = name.split(" ");
  //   const lastName = lastNameArray.join(" ");

  //   // Fetch additional user details from Facebook's API
  //   axios
  //     .get(
  //       `https://graph.facebook.com/${response.userID}?fields=id,email,first_name,last_name&access_token=${response.accessToken}`
  //     )
  //     .then((response) => {
  //       if (response.accessToken !== "") {
  //         const socialLoginData = {
  //           login_type: "2",
  //           email: email,
  //           password: "",
  //           last_name: lastName,
  //           first_name: firstName,
  //           social_media_id: id,
  //           social_media_type: "facebook",
  //           is_supporter: true
  //         };
  //         AuthService.socialLogin(socialLoginData, setHeader())
  //           .then((response) => {
  //             const token = response?.data?.data?.token;
  //             const user = response?.data?.data?.user;
  //             dispatch(signInUser({ token: token, user: user }));
  //             // navigate("/pricing");
  //             // if (response?.data?.data?.user?.active_subscription) {
  //             // If subscription_id is present, navigate to dashboard
  //             navigate("/dashboard");
  //             // } else {
  //             // If subscription_id is null, navigate to pricing
  //             // navigate("/pricing");
  //             // }
  //             toast.success("Login Successful.");
  //           })
  //           .catch((error) => {
  //             if (error?.response?.status === 403) {
  //               toast.error(error.response.data.message);
  //             }
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user details:", error);
  //     });
  // };

  // Facebook login function end

  // const FacebookLoginButton = ({ onClick }) => (
  //   <button type="button" className="gbtn" onClick={onClick}>
  //     <div className="d-flex align-items-center gap-2">
  //       <img src={Facebook} alt="facebook-logo" className="facebookbutton googlebutton" />
  //       <span className="pt-16">Facebook</span>
  //     </div>
  //   </button>
  // );

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const setRef = (ref) => {
    popupRef.current = ref;
  };

  return (
    <>
      <Helmet>
        <title>AI Sales Coach: Sign In to Boost Sales</title>
        <meta
          name="description"
          content="Unlock the potential of AI Sales Coach by signing in. Optimize your sales
          approach and reach new heights of sales excellence."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <AuthLayout>
        {showPopup && <SupportUs popupref={setRef} handleClosePopup={handleClosePopup} />}
        <div className="container">
          {/* Sign up form start */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row justify-content-center">
              <div className="col-9 col-lg-6">
                {/* title start */}
                <h2 className="formheading">Sign in</h2>
                {/* title end */}

                {/* description of Sign in page start */}
                <h3 className="formheading2">Welcome Back 👋</h3>
                <p className="pt-16 p-text">
                  Today is a new day. It's your day. You shape it. Sign in to start managing your projects.
                </p>
                {/* description of Sign in page end */}

                {/* Email field start */}
                <div className="formbox">
                  <label className="form-label pt-16">Email</label>
                  <input
                    autoComplete="off"
                    type="text"
                    className={`form-control form-field ${errors.email ? "is-invalid" : ""}`}
                    id="sign-in-email"
                    placeholder="Email"
                    {...register("email")}
                    inputMode="email"
                  />
                  {errors.email && <div className="invalid-feedback pt-16 ">{errors.email.message}</div>}
                </div>
                {/* Email field end */}

                {/*Password field start */}
                <div className="formbox">
                  <label className="form-label pt-16">Password</label>
                  <div className="password-wrapper">
                    <input
                      autoComplete="off"
                      type={showPassword ? "text" : "password"}
                      className={`form-control form-field ${errors.password ? "is-invalid" : ""}`}
                      id="signIn-password"
                      placeholder="Password"
                      {...register("password")}
                    />
                    <button type="button" className="password-toggle-btn" onClick={togglePasswordVisibility}>
                      {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                    {errors.password && <div className="invalid-feedback pt-16 ">{errors.password.message}</div>}
                  </div>
                </div>
                {/* Password field end */}

                {/* forgot password link start */}
                <div className="d-flex justify-content-end mb-3">
                  <Link to="/forgot-password" className="link pt-16">
                    Forgot Password?
                  </Link>
                </div>
                {/* forgot password link end */}

                {/* Sign in button start */}
                <div className="mb-3 formbox">
                  <button type="submit" className="orange-gradient-auth-button " disabled={signInDisableStatus}>
                    Sign in
                  </button>
                </div>
                {/* Sign in button end */}

                {/* or text between hr line start */}
                <div className="d-flex my-3 text-center align-items-center">
                  <hr className="flex-grow-1" />
                  <span className=" mx-3 pt-16">or</span>
                  <hr className="flex-grow-1" />
                </div>
                {/* or text between hr line end */}

                {/* social button section start */}
                <div className="mb-3 text-center d-flex d-none-sm justify-content-center gap-4">
                  {/* Google button start */}
                  <button type="button" className="gbtn" onClick={() => googlelogin()}>
                    <div className="d-flex align-items-center gap-2">
                      <img src={Google} alt="google-logo" className="googlebutton" />
                      <span className="pt-16">Google</span>
                    </div>
                  </button>
                  {/* Google button end */}

                  {/* facebook button start */}
                  {/* <FacebookAuth
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={true}
                  fields="name,email,picture"
                  callback={handleLogin}
                  cssClass="facebook-button"
                  textButton="Facebook"
                  component={FacebookLoginButton}
                  scope="public_profile, email, user_birthday"
                /> */}
                  {/* facebook button end */}
                </div>
                {/* social button section end */}

                {/* sign up link start */}
                <div className="d-flex justify-content-center accountlink pt-16">
                  <span>
                    Don't you have an account?{" "}
                    {/* <Link to="/sign-up" className="link">
                    Sign up
                  </Link> */}
                  </span>
                  &nbsp;
                  <span
                    className="nav-link text-primary"
                    style={{ cursor: "pointer" }}
                    href="#"
                    onClick={handleViewMore}
                    id="supportus_signin"
                  >
                    Sign Up
                  </span>
                </div>
                {/* sign up link start */}
                <div className="copyright footer d-flex justify-content-center mb-3">
                  &copy; {new Date().getFullYear()} All rights reserved.
                </div>
              </div>
            </div>
          </form>
          {/* Sign up form end */}
        </div>
      </AuthLayout>
    </>
  );
};

export default SignIn;
