import React, { createRef } from "react";
import Header from "./header";
import Footer from "./footer";
import "./style.css";

const InnerLayout = ({ children }) => {

  let supportUs = createRef();

  const setSupportUsRef = (ref) => {
    supportUs = ref
  }

  return (
    <>
      <Header setSupportUsRef={setSupportUsRef} />
      {children}
      <Footer />
    </>
  );
};

export default InnerLayout;
