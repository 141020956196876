import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import DashboardService from "../../../services/DashboardService";
import { selectAuth, selectLatestStatus } from "../../../redux/authSlice";
import "../style.css";

const DashboardChart2 = () => {
  const latestStatus = useSelector(selectLatestStatus);
  const [selectedInterval, setSelectedInterval] = useState(null);

  useEffect(() => {
    // Call API using latestStatus whenever it changes

    handleFilter(selectedInterval);
  }, [latestStatus]);

  useEffect(() => {
    setSelectedInterval("week");
    handleFilter("week");
  }, []);

  const user = useSelector(selectAuth);

  const token = user?.token;
  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
    handleFilter(interval);
  };

  const handleFilter = (selectedInterval) => {
    DashboardService.getUserProcessDetails(
      {
        headers: {
          Authorization: `token ${token}`
        }
      },
      selectedInterval
    )
      .then((response) => {
        const data = response?.data?.data;

        const dates = Object.keys(data).filter(
          (key) => key !== "total_completed" && key !== "total_in_process" && key !== "total_user"
        );
        const solutionGeneratedData = dates.map((date) => data[date].total_solution_generated);
        const problemGeneratedData = dates.map((date) => data[date].total_problem_genrated);

        solutionGeneratedData.reverse();
        problemGeneratedData.reverse();
        dates.reverse();

        setChartData({
          ...chartData,
          series: [
            { name: "Problem Generated", data: problemGeneratedData },
            { name: "Solution Generated", data: solutionGeneratedData }
          ],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: dates
            }
          }
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [chartData, setChartData] = useState({
    series: [
      { name: "Problem Generated", data: [] },
      { name: "Solution Generated", data: [] }
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "25px",
          endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value; // Append "k" to the value for the Y-axis labels
          }
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      colors: ["#EF9246", "#4682B4"]
    }
  });

  return (
    <div id="chart">
      <div className=" d-flex  align-items-center justify-content-center p-3 graph-tab-navigation">
        <button
          className={`graph-tab-link week ${selectedInterval === "week" ? "active" : ""}`}
          onClick={() => handleIntervalChange("week")}
        >
          Week
        </button>
        <button
          className={` graph-tab-link month  ${selectedInterval === "month" ? "active" : ""}`}
          onClick={() => handleIntervalChange("month")}
        >
          Month
        </button>
        <button
          className={`graph-tab-link year ${selectedInterval === "year" ? "active" : ""}`}
          onClick={() => handleIntervalChange("year")}
        >
          Year
        </button>
      </div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
    </div>
  );
};

export default DashboardChart2;
