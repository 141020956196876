import React from "react";
import { Helmet } from "react-helmet";

import "./style.css";
import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import { ReactComponent as TitleDesign } from "../../assets/pricing/TitleDesign.svg";
import UseCasesContent from "./components/UseCasesContent";

const UseCases = () => {
  return (
    <>
      <Helmet>
        <title>AI Sales Coach Usecases: Drive Sales Growth</title>
        <meta
          name="description"
          content="Discover how AI Sales Coach use cases empower your team, enhance customer
          engagement, and maximize revenue. Explore success stories and strategies!"
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <section className="pricing-title-background text-white">
          <div className="pricing-card-padding  d-flex align-items-center justify-content-center">
            <div>
              <h1 className=" prising-title d-flex  align-items-center justify-content-center">Use Cases</h1>
              <br />
              <span className="d-flex  align-items-center justify-content-center">Home/Use Cases</span>
            </div>
            <TitleDesign className="pricing-title-image px-20px" />
          </div>
        </section>
        <UseCasesContent />
      </InnerLayout>
    </>
  );
};

export default UseCases;
