import React from "react";

const RefundpolicyContent = () => {
  return (
    <section className="refund-policy-main-section   pt-16 ">
      <div className="container-fluid refund-policy-section refund-policy-container container d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-start px-lg-5">
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">General</h3>
            <p>
              At AI Sales Coach, we are committed to offering the highest quality in our services. We understand that
              there may be circumstances where users might need to reconsider their decisions, and we aim to be flexible
              in accommodating such scenarios.
            </p>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">Eligibility for Refund</h3>
            <p>To be eligible for a refund:</p>
            <ul>
              <li>Initial Trial: The user must request a refund within 3 days of the purchase date.</li>
              <li>
                Technical Issues: If the user experienced technical issues that prevented them from accessing or
                properly using the AI Sales Coach services, and our support team was unable to resolve the problem.
              </li>
              <li>
                Accidental Purchase: If the purchase was made accidentally, e.g., by a child or without the user's
                knowledge. In these cases, users should provide a detailed explanation.
              </li>
              <li>
                Service Downtime: If the user was unable to access AI Sales Coach services for prolonged periods due to
                issues on our side, excluding scheduled maintenance.
              </li>
              <li>
                Billing Errors: If the user was billed an incorrect amount or billed multiple times due to system
                errors.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">How to Request a Refund</h3>
            <p>To request a refund:</p>
            <ul>
              <li>
                Contact our support team at{" "}
                <a className="refund-policy-mailto" href="mailto:support@aisalescoach.ai">
                  support@aisalescoach.ai
                </a>
                .
              </li>
              <li>Provide your account details and purchase receipt.</li>
              <li>Clearly state the reason for requesting a refund.</li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">Processing the Refund</h3>
            <ul>
              <li>Upon receiving your refund request, our team will review it within 3-5 business days.</li>
              <li>
                If your refund is approved, it will be processed, and a credit will be applied to your original method
                of payment within a certain number of days, depending on your card issuer's policies.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">Late or Missing Refunds</h3>
            <p>If you haven't received a refund after a stipulated period:</p>
            <ul>
              <li>First, check your bank account or credit card statement.</li>
              <li>
                Then, contact your bank or credit card company, as it may take some time before your refund is
                officially posted.
              </li>
              <li>
                If you've done all of this and still haven't received your refund, please contact us at{" "}
                <a className="refund-policy-mailto" href="mailto:support@aisalescoach.ai">
                  support@aisalescoach.ai
                </a>
                .
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">Changes to the Refund Policy</h3>
            <p>
              AI Sales Coach Ltd reserves the right to modify this Refund Policy at any time, so please review it
              frequently. Changes to this policy will be effective upon posting on our website.
            </p>
          </div>
          <div className="mt-1 fs-16">
            <h3 className="fw-bold">Contact Information</h3>
            <p>
              For any questions about this Refund Policy or any other inquiries, please contact us at{" "}
              <a className="refund-policy-mailto" href="mailto:support@aisalescoach.ai">
                support@aisalescoach.ai
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RefundpolicyContent;
