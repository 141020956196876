import React from "react";
import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";

const Notifications = () => {
  return (
    <ProfileLayout>
      <div className="profileform responsive-padding">Notifications</div>
    </ProfileLayout>
  );
};

export default Notifications;
