import React, { useState } from "react";

import "./forms.css";
import QuestionMark from "../../../assets/Forms/questionMark.png";
import CommomForm from "./CommomForm";

const Qualify = () => {
  const [proceedStatus, setProceedStatus] = useState(false);

  return (
    <div className="qualify-questions-bg">
      <div className="cheat-sheet-content container d-flex justify-content-center align-items-center p-5">
        <div className="qualify-content row d-flex justify-content-center align-items-center flex-lg-row">
          <div className="col-lg-6 col-12 d-flex justify-content-center mb-3">
            <img src={QuestionMark} alt="qualify-visual" className="qualify-visual-image " />
          </div>
          <div className="col-lg-6 col-12">
            <p className="qualify-form-tag-line">Download Qualifying Questions Outline</p>
            <CommomForm proceedStatus={proceedStatus} element="qualify" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qualify;
