import React from "react";
import { Helmet } from "react-helmet";

import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import TermsOfServiceTitle from "./components/TermsOfServiceTitle";
import TermsOfServiceContent from "./components/TermsOfServiceContent";

const TermsOfService = () => {
  return (
    <>
      <Helmet>
        <title>Terms of Service: Guidelines for AI Sales Coach Users</title>
        <meta
          name="description"
          content="Explore the detailed terms of service for AI Sales Coach, providing essential
          guidelines and policies for users. Stay informed and compliant with our user
          agreement."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <TermsOfServiceTitle />
        <TermsOfServiceContent />
      </InnerLayout>
    </>
  );
};

export default TermsOfService;
