import React from "react";

import "../style.css";

const PrivacyPolicyContent = () => {
  return (
    <section className="privacy-policy-main-section  text-white pt-16 ">
      <div className="container-fluid privacy-policy privacy-policy-container container d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-start px-lg-5">
          <div className=" fs-16">
            <h5 className="fw-bold">Last Updated: 11/09/2023</h5>
            <h4 className="fw-bold">Welcome to AI Sales Coach!</h4>

            <p>
              At AI Sales Coach, we are committed to protecting your privacy and providing a secure environment for our
              users. This Privacy Policy outlines the information we collect, how we use it, and your choices regarding
              your personal data. Please read this Privacy Policy carefully to understand our practices.
            </p>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">1. Information We Collect</h4>
            <p>We collect the following information when you use our services:</p>
            <p>1.1 User Data</p>
            <ul>
              <li>
                Information you provide during registration and profile setup. Audio files (MP3, WAV, AAC, AIFF, FLAC,
                M4A, WMA) uploaded for analysis and feedback.
              </li>
              <li>Personalized monthly progress tracking data.</li>
            </ul>
            <p>1.2 Usage Data</p>
            <ul>
              <li>
                Data related to your interactions with our AI-powered sales call analysis, performance insights, and
                sales strategies.
              </li>
              <li>Information about your participation in community forums and discussions.</li>
            </ul>
            <p>1.3 External Data</p>
            <ul>
              <li>Files imported from external sources such as Google Drive and Dropbox.</li>
            </ul>
          </div>

          <div className="mt-1 fs-16">
            <h4 className="fw-bold">2. How We Use Your Information</h4>
            <p>We use your information for the following purposes:</p>
            <p>2.1 Service Provision</p>
            <ul>
              <li>To provide unlimited AI-powered sales call analysis with detailed feedback.</li>
              <li>To offer AI-generated performance insights and sales strategies.</li>
              <li>To enable access to community forums for discussions and learning.</li>
              <li>To facilitate role-play features and engage in simulated sales conversations.</li>
              <li>To provide personalized monthly progress tracking with detailed recommendations.</li>
              <li>To support audio formats for analysis and feedback.</li>
            </ul>
            <p>2.2 Affiliate Program</p>
            <ul>
              <li>To administer the affiliate program, if you choose to participate.</li>
              <li>To track referrals and provide credits for future payment deductions.</li>
              <li>To provide you with an affiliate link for promotion.</li>
            </ul>
            <p>2.3 Communication</p>
            <ul>
              <li>To send you updates, newsletters, and information about our quarterly live masterclasses.</li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">3. Sharing of Information</h4>
            <p>
              We do not sell, trade, or rent your personal information to third parties. We may share your information
              with:
            </p>
            <ul>
              <li>Service providers who help us with data analysis, hosting, and other operational services.</li>
              <li>Other users within the community forums (only information you choose to share).</li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">4. Your Choices</h4>
            <p>You have the following choices regarding your information:</p>
            <ul>
              <li>You can access, correct, or delete your profile information.</li>
              <li>You can opt-out of receiving promotional emails.</li>
              <li>You can choose not to participate in our affiliate program.</li>
              <li>You can disable cookies through your browser settings.</li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">5. Security</h4>
            <ul>
              <li>
                We implement reasonable security measures to protect your data. However, no data transmission over the
                internet can be guaranteed as 100% secure.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">6. Changes to this Privacy Policy</h4>
            <ul>
              <li>We may update this Privacy Policy from time to time. Please review it periodically for changes.</li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">7. Contact Us</h4>
            <ul>
              <li>
                If you have any questions or concerns about this Privacy Policy, please contact us at{" "}
                <a className="refund-policy-mailto" href="mailto:support@aisalescoach.ai">
                  support@aisalescoach.ai
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicyContent;
