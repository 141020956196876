import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../redux/authSlice";
import ProfileHeaderBackground from "../../../../assets/profile/ProfileHeaderBackground.png";
import "../style.css";

const ProfileHeader = () => {
  return (
    <>
      <div className="profile-card-header">
        <img className="card-img-top mt-3" src={ProfileHeaderBackground} alt="background-image" />
      </div>
    </>
  );
};

export default ProfileHeader;
