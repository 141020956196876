import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Modal from "react-modal";

import "../style.css";
import { selectAuth, updateActiveSubscription, signOutUser } from "../../../redux/authSlice";
import ProfileService from "../../../services/ProfileService";
import { BallTriangle } from "react-loader-spinner";

const SubscriptionDetails = () => {
  const [subscription, setSubscription] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const data = useSelector(selectAuth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    ProfileService.getProfile({
      headers: {
        Authorization: `token ${data.token}`
      }
    })
      .then((response) => {
        const subscriptionData = response?.data?.data || [];
        setSubscription(subscriptionData);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      });
  }, []);

  const token = data.token;

  const handleCancelSubscription = () => {
    setShowModal(true); // Open the modal when cancel button is clicked
  };

  const confirmCancelSubscription = () => {
    ProfileService.cancelSubscription({
      headers: {
        Authorization: `token ${token}`
      }
    })
      .then((response) => {
        let free_plan_status;
        if (data.user.active_subscription === "Free Trial") {
          free_plan_status = "cancel";
        } else {
          free_plan_status = "paid";
        }
        const updatedUser = { ...data.user, active_subscription: null, free_plan_status: free_plan_status };
        dispatch(updateActiveSubscription(updatedUser));

        navigate("/pricing");
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        toast.error(error?.response?.data?.message);
      });

    setShowModal(false); // Close the modal after confirming cancellation
  };

  const handleUpgrade = () => {
    navigate("/pricing");
  };

  return (
    <ProfileLayout>
      <section className="subscription profileform mt-5">
        <div className="container">
          <div className="col-lg-9 ">
            <h2 className="my-subscriptions__title mb-4">My subscription</h2>
            {isLoading ? (
              <BallTriangle
                className=""
                height={30}
                width={30}
                radius={5}
                color="orange"
                ariaLabel="ball-triangle-loading"
                visible={true}
                wrapperStyle={{
                  display: " flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "50px",
                  marginBottom: "50px"
                }}
              />
            ) : subscription.active_subscription ? (
              <>
                <h6 className="subtext">Current Plan</h6>
                <div className="current-plan__container">
                  <div className="row d-flex flex-wrap">
                    <div className="plan__info-container">
                      <strong className="plan__name mb-3">{subscription?.active_subscription}</strong>
                      <p className="paratext">
                        Next payment on <span>{new Date(subscription.next_bill_date).toLocaleDateString()}</span>.{" "}
                      </p>
                    </div>
                    <div className="card-button">
                      <Link to="/pricing" className="upgrade-plan-link" onClick={handleUpgrade}>
                        Upgrade Plan
                      </Link>
                      <Link className="cancel-plan-link" onClick={handleCancelSubscription}>
                        Cancel renewal
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <p className="pt-20">You have not purchased any plan.</p>
            )}
          </div>
        </div>
      </section>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Confirm Cancellation"
        className="cancel-model"
      >
        <h2 className="pt-24">Confirm Cancellation</h2>
        <p className="pt-18">Are you sure you want to cancel the subscription?</p>
        <div className="d-flex gap-2 flex-row">
          <button onClick={confirmCancelSubscription} className="orange-gradient-auth-button pt-18">
            Yes, Cancel
          </button>
          <button onClick={() => setShowModal(false)} className="orange-gradient-auth-button pt-18">
            No, Keep Subscription
          </button>
        </div>
      </Modal>
    </ProfileLayout>
  );
};

export default SubscriptionDetails;
