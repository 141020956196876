import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "./style.css";
import AIAssistantLayout from "./components/AIAssistantLayout";
import AIAssitantStep1 from "./components/AIAssistantStep1";
import AIAssitantStep2 from "./components/AIAssistantStep2";
import AIAssitantStep3 from "./components/AIAssistantStep3";
import ZoomService from "../../services/ZoomService";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";

const AIAssistantSteppers = () => {
  const [activeStep, setActiveStep] = useState();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useSelector(selectAuth);

  const receivedData = location.state;

  const queryParams = new URLSearchParams(location.search);

  const zoom_code = queryParams.get("code");

  const handleaccessTokenGenerator = () => {
    const data = { code: zoom_code };
    ZoomService.accessTokenGenerator(data, token)
      .then((response) => {
        toast.success("Authorization successful! You can now proceed to upload your Zoom meeting recordings.");
        queryParams.delete("code");
        navigate({ search: queryParams.toString() });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (zoom_code) {
      handleaccessTokenGenerator();
    }
  }, [location]);

  useEffect(() => {
    setActiveStep(id - 1);

    if ((Number(id) === 1 || Number(id) === 2) && !receivedData && !zoom_code) {
      navigate("/dashboard");
    }
  }, [id]);

  // Function to handle moving to the next step
  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  // Function to handle moving to the previous step
  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Function to handle form submission in Step 3 (if needed)
  const handleSubmit = () => {
    // Handle the form submission logic here (e.g., sending data to the server)
  };

  let currentStep = null;
  switch (activeStep) {
    case 0:
      currentStep = <AIAssitantStep1 onNext={handleNextStep} />;
      break;
    case 1:
      currentStep = <AIAssitantStep2 onNext={handleNextStep} onPrevious={handlePreviousStep} data={receivedData} />;
      break;
    case 2:
      currentStep = <AIAssitantStep3 onPrevious={handlePreviousStep} onSubmit={handleSubmit} data={receivedData} />;
      break;
    default:
      currentStep = <AIAssitantStep1 onNext={handleNextStep} />;
  }

  return <AIAssistantLayout element={currentStep} active={activeStep} />;
};

export default AIAssistantSteppers;
