import React, { createRef, useEffect, useState } from "react";
import CountUp from "react-countup";

import Ellipse1 from "../../../assets/home/Ellipse1.webp";
import Ellipse2 from "../../../assets/home/Ellipse2.webp";
import Ellipse3 from "../../../assets/home/Ellipse3.webp";
import Ellipse4 from "../../../assets/home/Ellipse4.webp";
import SupportUs from "../../../layouts/OuterLayout/SupportUs";
import "../../../constants/styles.css";

const SalesCoach = ({ data, setSupportUsRef }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [totalUserCount, setTotalUserCount] = useState();

  let popupRef = createRef(null);

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const setRef = (ref) => {
    popupRef.current = ref;
  };

  useEffect(() => {
    setTotalUserCount(data[0]?.total_user);
  }, [data]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  return (
    <section className="sales-coach text-white">
      {showPopup && <SupportUs popupref={setRef} handleClosePopup={handleClosePopup} />}
      <div className="sales-coach-content d-flex justify-content-lg-start justify-content-md-start justify-content-center align-items-center text-center">
        <div className="aos-init" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="150">
          <p className="fs-32 lh-1">Get More Closed Sales with</p>
          <p className="fs-62 bannerHeading">
            THE PERFECT
            <br />
            CLOSING SCRIPT
          </p>
          <p className="fs-38 lh-1">{data[0]?.title}</p>
          <p className="fs-32 lh-1">Elevate Your Sales Success </p>

          <div className="mt-5">
            <div className="d-lg-flex justify-content-lg-center gap-4 align-items-center">
              <span>
                <button
                  className="try-now-button fs-24 px-4 py-2"
                  onClick={handleViewMore}
                  id="supportus_landingpage"
                  ref={setSupportUsRef}
                >
                  Try Now!
                </button>
              </span>
            </div>
            <div className="mt-5 mt-2 d-flex justify-content-center align-items-center">
              <span>
                <img src={Ellipse1} alt="Ellipse1" className="ellipse" />
              </span>
              <span>
                <img src={Ellipse2} alt="Ellipse2" className="ellipse" />
              </span>
              <span>
                <img src={Ellipse3} alt="Ellipse3" className="ellipse" />
              </span>
              <span>
                <img src={Ellipse4} alt="Ellipse4" className="ellipse" />
              </span>
            </div>
            <p className="fs-24 mt-3">
              <CountUp end={totalUserCount} delay={2} duration={2} /> Active Users
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SalesCoach;
