import React from "react";
import { useNavigate } from "react-router-dom";

import about1 from "../../../assets/home/about1.png";
import map from "../../../assets/home/map.png";
import address from "../../../assets/home/address.png";

import "../style.css";
import AboutTimelineCarousel from "./AboutTimelineCarousel";

const AboutUsContent = ({ coreValuePrincipleData, companyHistoryData, teamData }) => {
  const navigate = useNavigate();

  const coreValuesData = coreValuePrincipleData.filter((item) => item.section === "Core Values of AI Sales Coach");
  const corePrincipleData = coreValuePrincipleData.filter(
    (item) => item.section === "Core Principles of AI Sales Coach"
  );

  const handleContactUs = () => {
    navigate("/contact-us/");
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This provides a smooth scrolling animation
    });
  };
  return (
    <>
      <section className="about-us__area corevalues">
        <div class="container">
          <div class="row align-items-xl-start align-items-lg-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div class="about-5__left-item text-center">
                <div class="about-5__section-box pb-10">
                  <h4 class="blue-section-subtitle-2">About Us</h4>
                  <h3 class="fs-60 text-capitalize mb-4">Welcome to AI Sales Coach !</h3>
                  <p className="newp">
                    You know those moments just before a crucial sales pitch? The jittery nerves, the hope, the
                    uncertainty, the questions— "Will they say yes? Am I good enough?" We've all been there, and we get
                    it. Sales can be exhilarating one moment and nerve-wracking the next. That roller-coaster of
                    emotions, the desire to succeed, and the occasional doubts are what led us to create your most
                    reliable sales ally: AI Sales Coach.
                  </p>
                  <p className="newp">
                    Born from countless cups of coffee, scribbled notes, and those "Eureka!" moments during late-night
                    brainstorming, AI Sales Coach was more than just a project for us. It was a dream of crafting a
                    digital companion that understands those nerve-wracking moments, guides you with data-backed
                    insights, and yes, occasionally comforts you with a virtual pat on the back.
                  </p>
                  <p className="newp">
                    Our tool dives deep, analyzing patterns and nuances, offering strategies that increase your chances
                    of hearing that sweet "Yes!" from clients. We designed it to ensure that every salesperson, whether
                    a newbie or a veteran, can stride into meetings with confidence and the best sales strategies up
                    their sleeves.
                  </p>
                  <p className="newp">
                    Behind AI Sales Coach is a vibrant team of sales veterans, tech enthusiasts, and empathetic creators
                    who have experienced the uncertainties you face. We understand sales, not just as a series of
                    tactics but as an emotional journey with its highs and lows.
                  </p>
                  <p className="newp">
                    By choosing AI Sales Coach, you're not just getting a tool; you're gaining a partner. A partner
                    eager to see you succeed, celebrate your victories, and be there during the challenging times.
                  </p>
                  <p className="newp">
                    Feeling that pre-pitch anxiety or need some expert guidance on your next strategy? We're just an
                    email away at{" "}
                    <a className="about-us-mailto" href="mailto:support@aisalescoach.ai">
                      support@aisalescoach.ai
                    </a>
                    . We're not just behind the screen; we're with you, cheering you on, every step of the way.
                  </p>
                  <div className="mt-2 fw-bold warmtext">
                    Warmly, <br /> Your AI Sales Coach Family
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-5__area corevalues">
        <div class="container">
          <div class="row align-items-xl-start align-items-lg-center">
            <div class="col-xl-6 col-lg-6">
              <div class="about-5__left-item">
                <div class="about-5__section-box pb-10">
                  <h4 class="blue-section-subtitle-2">Core Values</h4>
                  <h3 class="fs-60 text-capitalize">
                    Core Values of <span>AI Sales Coach</span>
                  </h3>
                </div>
                <div class="about-5__wrapper mt-4">
                  {coreValuesData.map((item) => (
                    <div class="about-5__item d-flex align-items-center" key={item.id}>
                      <div class="about-5__icon">
                        <img src={item.image} alt={item.title} />
                      </div>
                      <div class="about-5__text">
                        <h4 class="about-5__title-sm">{item.title}</h4>
                        <p className="newp">{item.content}</p>
                      </div>
                    </div>
                  ))}
                  <div class="mt-4 bt-center">
                    <button class="btn1 btn primary-orange-gradient-button ms-4 fs-20" onClick={handleContactUs}>
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="about-5__right-item position-relative">
                <div class="about-5__top-img">
                  <img src={about1} alt="about1" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="teams_sec">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center">
            <div className="headingsec">
              <h2 className="fs-60  meet-team-text ">Meet the team</h2>
            </div>
          </div>
          <div className="row">
            {teamData.map((member) => (
              <div className="col-lg-2 col-md-6 col-sm-6 team-card-layout" key={member.id}>
                <div className="teamcard">
                  <img src={member.profile_image} alt={member.name} />
                  <h5 className="white-color mt-3 mb-0">{member.name}</h5>
                  <p className="white-color">{member.designation}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="about-5__area coreprinciples">
        <div class="container">
          <div class="row align-items-xl-start align-items-lg-center">
            <div class="col-xl-12 col-lg-12 col-sm-12">
              <div class="about-5__left-item">
                <div class="about-5__section-boxb text-center">
                  <h4 class="blue-section-subtitle-2">Core Principles</h4>
                  <h3 class="fs-60 text-capitalize">
                    Core Principles of <span>AI Sales Coach</span>
                  </h3>
                </div>
                <div class="about-5__wrapper mt-4">
                  {corePrincipleData.map((item) => (
                    <div class="about-5__item d-flex align-items-center" key={item.id}>
                      <div class="about-5__icon">
                        <img src={item.image} alt={item.title} />
                      </div>
                      <div class="about-5__text">
                        <h4 class="about-5__title-sm">{item.title}:</h4>
                        <p className="newp">{item.content}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div class="mt-5 text-center">
                  <button class="btn1 btn primary-orange-gradient-button ms-4 fs-20" onClick={handleContactUs}>
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mapsec">
        <div className="container">
          <div className="text-center headingsec">
            <h2 className="fs-60 text-capitalize">Our Headquarters</h2>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex align-items-center">
                <div className="about-5__icon">
                  <img src={address} alt="address" />
                </div>
                <div>
                  <h3>Address</h3>
                  <h4>880 3rd Ave, New York, NY 10022, US</h4>
                </div>
              </div>
              <img src={map} alt="map" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2096.2319216858264!2d-73.97195041374292!3d40.758687018200774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e361ba776f%3A0xfded3bfaf0348203!2s880%203rd%20Ave%2C%20New%20York%2C%20NY%2010022%2C%20USA!5e0!3m2!1sen!2sin!4v1696487468989!5m2!1sen!2sin"
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      <section class="historysec timeline-carousel">
        <div className="text-center headingsec">
          <h2 className="fs-60 text-capitalize text-white">Company History</h2>
        </div>
        <div className="container">
          <AboutTimelineCarousel companyHistoryData={companyHistoryData} />
        </div>
      </section>
    </>
  );
};

export default AboutUsContent;
