import React from "react";

import "../style.css";
import InstantRegistartion from "../../../assets/UseCases/instant_registration.png";
import CallAnalysis from "../../../assets/UseCases/analysis.png";
import ProblemSolving from "../../../assets/UseCases/problem-solving.png";
import Discussion from "../../../assets/UseCases/greetings.png";
import Improvement from "../../../assets/UseCases/formative.png";
import TrackProgress from "../../../assets/UseCases/progress.png";
import Zoom from "../../../assets/UseCases/zoom.png";
import PerformanceEnhancement from "../../../assets/UseCases/performance.png";
import TimeSaving from "../../../assets/UseCases/back-in-time.png";
import IntractiveChat from "../../../assets/UseCases/conversation.png";

const UseCasesContent = () => {
  return (
    <section className="usecase-main-section pt-16 ">
      <div className="use-cases-section container-fluid use-cases-container container d-lg-flex flex-column justify-content-center align-items-center">
        <div className="d-lg-flex flex-column justify-content-start px-lg-5">
          <div className="fs-16">
            <h3 className="fw-bold">Elevate Your Sales with AI Sales Coach:</h3>
            <p className="use-cases-paragraph">
              AI Sales Coach, specializes in providing AI-driven solutions to sales teams for improving their call
              handling and problem-solving skills. They want to create a platform that allows sales representatives to
              upload recorded sales calls, have AI model analyze these calls, identify potential problems, and provide
              suggested solutions. Additionally, users are able to engage in a chat with our AI to discuss the generated
              solutions and ask further questions.
            </p>
          </div>
          <div className="d-lg-flex gap-4 align-items-center">
            <div className="d-flex justify-content-center">
              <img src={InstantRegistartion} alt="instatnr-registartion" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Instant User Registration and Seamless Access</h5>
              <ul className="mt-lg-1">
                <li>Immediate account creation and login for hassle-free access.</li>
                <li>Quick access to your sales call history and analysis results.</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center justify-content-end">
            <div className="d-flex justify-content-center d-lg-none  d-block">
              <img src={CallAnalysis} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Effortless Sales Call Analysis</h5>
              <ul className="mt-lg-3">
                <li>Simple "Upload Sales Call" feature for easy file submission.</li>
                <li>In-depth AI-driven analysis highlights critical call components.</li>
                <li>Instant identification of potential issues for improvement.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center d-lg-block d-none">
              <img src={CallAnalysis} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center">
            <div className="d-flex justify-content-center">
              <img src={ProblemSolving} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Customized Problem-Solving Solutions</h5>
              <ul className="mt-lg-3">
                <li>AI-generated analysis reports for targeted problem identification.</li>
                <li>Tailored solutions provided for missed opportunities, objection handling, and more.</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center justify-content-end">
            <div className="d-flex justify-content-center d-lg-none  d-block ">
              <img src={Discussion} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Interactive Solution Discussion</h5>
              <ul className="mt-lg-3">
                <li>Interactive chat with AI for real-time solution clarification.</li>
                <li>Context-specific responses and user query addressing.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center d-lg-block d-none">
              <img src={Discussion} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center">
            <div className="d-flex justify-content-center">
              <img src={Improvement} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Continuous Improvement through User Feedback</h5>
              <ul className="mt-lg-3">
                <li>User feedback drives AI model enhancement.</li>
                <li>A learning platform that adapts to your needs.</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center justify-content-end">
            <div className="d-flex justify-content-center d-lg-none  d-block">
              <img src={TrackProgress} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Track Your Progress with Ease</h5>
              <ul className="mt-lg-3">
                <li>Comprehensive record-keeping of call analysis and solutions.</li>
                <li>Visualize your call handling skill improvement over time.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center d-lg-block d-none">
              <img src={TrackProgress} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center ">
            <div className="d-flex justify-content-center">
              <img src={PerformanceEnhancement} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Personalized Sales Performance Enhancement</h5>
              <ul className="mt-lg-3">
                <li>Receive tailored feedback and actionable insights.</li>
                <li>Elevate your sales call performance with AI assistance.</li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center justify-content-end">
            <div className="d-flex justify-content-center d-lg-none  d-block">
              <img src={TimeSaving} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Time-saving Automation</h5>
              <ul className="mt-lg-3">
                <li>Automate call evaluation and solution generation.</li>
                <li>Save valuable time and effort.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center d-lg-block d-none">
              <img src={TimeSaving} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
          </div>
          <hr />
          <div className="d-lg-flex gap-4 align-items-center ">
            <div className="d-flex justify-content-center">
              <img src={IntractiveChat} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Engaging Interactive Chat</h5>
              <ul className="mt-lg-3">
                <li>Foster engagement and deep understanding.</li>
                <li>Discuss and explore provided solutions with ease.</li>
              </ul>
            </div>
          </div>
          <hr />

          <div className="d-lg-flex gap-4 align-items-center justify-content-end">
            <div className="d-flex justify-content-center d-lg-none  d-block">
              <img src={Zoom} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
            <div className="d-flex align-items-start flex-column">
              <h5 className="fw-bold mt-3">Seamless Zoom Integration</h5>
              <h5>Authorizing Zoom</h5>
              <ul className="">
                <li>
                  Upon logging in to our website, you will be directed to the dashboard.
                  <li>Here both "Your Al Coach" and the "Upload Sales Conversation Audio" options.</li>
                </li>
                <li>By clicking one of the button. You will be directed to step-1</li>
                <li>Here zoom logo and "Select Zoom Conversation" link available</li>
                <li>
                  {" "}
                  If the user hasn't authorized then After clicking "Select Zoom Conversation" link,
                  <br />
                  redirected to Zoom's authorization page.
                </li>
                <li>After successful authorization, users are redirected back to our site.</li>
                <li>
                  They can then revisit "Select Zoom Conversation" to choose the desired <br />
                  Zoom meeting recording from a pop-up.
                </li>
              </ul>
              <h5>Usage</h5>
              <ul className="">
                <li>
                  The selected Zoom meeting recording will be utilized in our app's <br /> solution generation process.
                </li>
              </ul>

              <h5> Deauthorizing Zoom</h5>
              <ul className="">
                <li>If you ever wish to deauthorize your Zoom account from our site, you can do so.</li>
                <li>Check My Profile ➔ Integration and Deauthorized zoom.</li>
              </ul>
              <h5> Deauthorizing Using Zoom App Marketplace.</h5>
              <ul>
                <li>Login to your Zoom Account and navigate to the Zoom App Marketplace.</li>
                <li>Click Manage ➔ Added Apps or search for the "AI sales Coach" app.</li>
                <li>Click the "AI sales Coach" app.</li>
                <li>Click Remove.</li>
              </ul>
            </div>
            <div className="d-flex justify-content-center d-lg-block d-none">
              <img src={Zoom} alt="sales-call-analysis" className="img-fluid use-cases-visual" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCasesContent;
