import React, { useState } from "react";
import "../style.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";
import ProfileService from "../../../services/ProfileService";
import { useSelector, useDispatch } from "react-redux";
import { selectAuth, signOutUser } from "../../../redux/authSlice";

const ConfirmPassword = () => {
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const user = useSelector(selectAuth);
  const dispatch = useDispatch();

  const togglePasswordVisibility = (field) => {
    if (field === "old_password") {
      setShowOldPassword((prevShowOldPassword) => !prevShowOldPassword);
    } else if (field === "new_password") {
      setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };
  // validation schema start
  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^&#])[A-Za-z\d@$!%*^&#]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character [!, @, #, $, %, ^, &, or *]"
      )
      .min(8, "Password must be at least 8 characters"),
    new_password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^&#])[A-Za-z\d@$!%*^&#]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character [!, @, #, $, %, ^, &, or *]"
      )
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("new_password")], "Passwords must match")
  });
  // validation schema end

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const navigate = useNavigate();
  const onSubmit = (data) => {
    ProfileService.changePassword(data, {
      headers: {
        Authorization: `token ${user.token}`
      }
    })
      .then((response) => {
        navigate("/dashboard");
        toast.success("Password Updated Successfully");
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        toast.error(error?.response?.data?.error[0]);
      });
  };
  return (
    <ProfileLayout>
      {/* Confirm password page start */}
      <div className="row">
        <div className="resetpasswordform">
          <div className="col-lg-8 col-md-12 col-sm-12">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/*Password field start */}
              <h2 className=" formheading subHeading mb-3">Change Password</h2>
              <div className="mb-3">
                <div className="formbox">
                  <label className="form-label pt-16">Old Password</label>
                  <div className="password-wrapper">
                    <input
                      type={showOldPassword ? "text" : "password"}
                      className={`form-control form-field pt-16 ${errors.old_password ? "is-invalid" : ""}`}
                      id="old_password"
                      placeholder="Old Password"
                      {...register("old_password")}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={() => togglePasswordVisibility("old_password")}
                    >
                      {showOldPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>
                    {errors.old_password && <div className="invalid-feedback pt-16">{errors.old_password.message}</div>}
                  </div>
                </div>
              </div>
              {/* Password field end */}

              {/*Confirm Password field start */}
              <div className="mb-3">
                <div className="formbox">
                  <label className="form-label pt-16">New Password</label>
                  <div className="password-wrapper">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      className={`form-control form-field pt-16 ${errors.new_password ? "is-invalid" : ""}`}
                      id="new_password"
                      placeholder="New password"
                      {...register("new_password")}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={() => togglePasswordVisibility("new_password")}
                    >
                      {showNewPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>

                    {errors.new_password && <div className="invalid-feedback pt-16">{errors.new_password.message}</div>}
                  </div>
                </div>
              </div>

              <div className="mb-3">
                <div className="formbox">
                  <label className="form-label pt-16">Confirm Password</label>
                  <div className="password-wrapper">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className={`form-control form-field pt-16 ${errors.confirmPassword ? "is-invalid" : ""}`}
                      id="confirm-password"
                      placeholder="Confirm password"
                      {...register("confirmPassword")}
                    />
                    <button
                      type="button"
                      className="password-toggle-btn"
                      onClick={() => togglePasswordVisibility("confirmPassword")}
                    >
                      {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                    </button>

                    {errors.confirmPassword && (
                      <div className="invalid-feedback pt-16">{errors.confirmPassword.message}</div>
                    )}
                  </div>
                </div>
              </div>
              {/* Confirm Password field end */}

              {/* Confirm button start */}
              <div className="mb-3 responsive-padding">
                <button type="submit" className="orange-gradient-auth-button pt-20">
                  Change Password
                </button>
              </div>
              {/* Confirm button end */}
            </form>
          </div>
        </div>
      </div>

      {/* Confirm password page end */}
    </ProfileLayout>
  );
};

export default ConfirmPassword;
