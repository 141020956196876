import React from "react";

import MoneyBackImage from "../../../assets/pricing/money_back_14day.png";

const MoneyBack = () => {
  return (
    <div className="money-back">
      <div className="container moneyback-container d-lg-flex  gap-3 p-5 mb-2 ">
        <div className="d-flex align-items-center justify-content-center">
          <img src={MoneyBackImage} alt="money-back" />
        </div>
        <div>
          <h1 className="money-back-heading">100% 14-Day Money Back Guarantee</h1>
          <p className="money-back-subheading fs-24">
            After your purchase, you have 14 days to ensure the product is valuable to you. If you're not completely
            satisfied and don't find value in it, we will refund your payment, guaranteed!
          </p>
        </div>
      </div>
    </div>
  );
};

export default MoneyBack;
