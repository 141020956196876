import React from "react";

import "../style.css";

const TermsOfServiceContent = () => {
  return (
    <section className="terms-of-service-main-section text-white pt-16 ">
      <div className="container-fluid terms-of-service terms-of-service-container container d-flex flex-column justify-content-center align-items-center">
        <div className="d-flex flex-column justify-content-start px-lg-5">
          <div className=" fs-16">
            <h5 className="fw-bold">Last Updated: 11/09/2023</h5>
            <h4 className="fw-bold">Welcome to AI Sales Coach!</h4>

            <p>
              Please read these Terms of Use carefully before using our services. By accessing or using any part of AI
              Sales Coach, you agree to be bound by these Terms of Use. If you do not agree to all the terms and
              conditions of this agreement, then you may not access the website or use any services.
            </p>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">1. Use of Services</h4>
            <p>1.1 Eligibility</p>
            <ul>
              <li>
                You must be at least 18 years old to use our services. By using our services, you represent and warrant
                that you are of legal age and can form a binding contract with AI Sales Coach.
              </li>
            </ul>
            <p>1.2 Access and Account</p>
            <ul>
              <li>
                You may need to create an account to access certain features. You are responsible for maintaining the
                confidentiality of your account credentials and for any activities that occur under your account.
              </li>
            </ul>
          </div>

          <div className="mt-1 fs-16">
            <h4 className="fw-bold">2. Features</h4>
            <p>2.1 Unlimited AI-Powered Sales Call Analysis</p>
            <ul>
              <li>
                We provide unlimited AI-powered sales call analysis with detailed feedback to enhance your sales skills.
              </li>
            </ul>
            <p>2.2 Supported Audio and Video Formats</p>
            <ul>
              <li>
                Our platform supports a variety of audio formats (MP3, WAV, AAC, AIFF, FLAC, M4A, WMA) for analysis and
                feedback.
              </li>
            </ul>
            <p>2.3 AI-Generated Performance Insights</p>
            <ul>
              <li>
                We offer AI-generated performance insights and sales strategies to assist you in improving your sales
                techniques.
              </li>
            </ul>
            <p>2.4 Community Forums</p>
            <ul>
              <li>
                You can access community forums for discussions and learning, but please review and adhere to our
                community guidelines.
              </li>
            </ul>
            <p>2.5 Personalized Progress Tracking</p>
            <ul>
              <li>
                We provide personalized monthly progress tracking with detailed recommendations for your sales
                development.
              </li>
            </ul>
            <p>2.6 Role-Play Feature</p>
            <ul>
              <li>
                You must be at least 18 years old to use our services. By using our services, you represent and warrant
                that you are of legal age and can form a binding contract with AI Sales Coach.
              </li>
            </ul>
            <p>2.7 Import from External Sources</p>
            <ul>
              <li>
                You can import audio files from external sources such as Google Drive and Dropbox for analysis and
                feedback.
              </li>
            </ul>
            <p>2.8 Affiliate Option</p>
            <ul>
              <li>
                By subscribing, you can participate in our affiliate program, promote AI Sales Coach, and earn a 20%
                one-time credit for every new subscriber referral. These credits can be used for future payment
                deductions.
              </li>
            </ul>
            <p>2.9 Quarterly Live Masterclass</p>
            <ul>
              <li>
                As a bonus, we offer quarterly live masterclasses where we share advanced selling techniques and answer
                your sales-related questions.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">3. User Content</h4>
            <ul>
              <li>
                By using our services, you agree not to upload, share, or otherwise make available any content that
                violates our Content Guidelines.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">4. Termination</h4>
            <ul>
              <li>
                We may suspend or terminate your access to our services for violations of these Terms of Use or for any
                other reason.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">5. Changes to Terms</h4>
            <ul>
              <li>
                We may revise these Terms of Use from time to time. The most current version will be posted on our
                website, and your continued use of our services constitutes acceptance of any changes.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">6. Limitation of Liability</h4>
            <ul>
              <li>
                AI Sales Coach and its affiliates shall not be liable for any indirect, incidental, special,
                consequential, or punitive damages.
              </li>
            </ul>
          </div>
          <div className="mt-1 fs-16">
            <h4 className="fw-bold">7. Contact Us</h4>
            <ul>
              <li>
                If you have any questions or concerns about these Terms of Use, please contact us at{" "}
                <a className="refund-policy-mailto" href="mailto:support@aisalescoach.ai">
                  support@aisalescoach.ai
                </a>
                .
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfServiceContent;
