import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import PCSHubspotService from "../../../services/PCSHubspotService";

const CommomForm = ({ element }) => {
  const [proceedStatus, setProceedStatus] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test("is-not-empty", "Please enter Full name", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your Full name"),
    email: Yup.string()
      .email("Please enter email address")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter email address")
      .required("Please enter your email address")
  });

  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    watch,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async (data) => {
    try {
      let category = "";

      if (element === "cheat-sheet") {
        category = "publish_book";
      } else if (element === "qualify") {
        category = "publish_form";
      }
      const response = await PCSHubspotService.PCSHubspot({ ...data, category });
      setProceedStatus(true);
    } catch (error) {
      if (error?.response?.status === 409) {
        setProceedStatus(true);
        return;
      }
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.style.display = "none";

    if (element === "cheat-sheet") {
      fetch(process.env.REACT_APP_PCSCheatSheetURL)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "The Perfect Closing Script Cheat-Sheet.pdf"; // Change the file name if needed
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    } else {
      fetch(process.env.REACT_APP_qualifyingQuestionsURL)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          link.href = url;
          link.download = "PCS-Qualifying Questions Outline.pdf"; // Change the file name if needed
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    }
  };

  return (
    <>
      {!proceedStatus ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formbox">
            <label className="form-label text-white pt-16">Name</label>
            <input
              autoComplete="off"
              type="text"
              id="name"
              placeholder="i.e. John"
              {...register("name")}
              className={`form-control form-field pt-16 ${errors.name ? "is-invalid" : ""}`}
            />
            {errors.name && (
              <div className={`${element === "cheat-sheet" ? "text-danger" : "text-white"} pt-16`}>
                {errors.name.message}
              </div>
            )}
          </div>

          <div className="formbox">
            <label className="form-label text-white pt-16">Email</label>
            <input
              autoComplete="off"
              type="text"
              className={`form-control form-field pt-16 ${errors.email ? "is-invalid" : ""}`}
              id="email"
              placeholder="i.e. example@gmail.com"
              {...register("email", { onBlur: () => trigger("email") })}
              inputMode="email"
            />
            {errors.email && (
              <div className={`${element === "cheat-sheet" ? "text-danger" : "text-white"} pt-16`}>
                {errors.email.message}
              </div>
            )}
          </div>
          <div className="formbox">
            <button
              type="submit"
              className={`${
                element === "cheat-sheet" ? "bg-danger-forms" : "bg-danger-forms-2"
              } text-white btn pt-20 mt-3`}
              // disabled={""}
            >
              Proceed
            </button>
          </div>
        </form>
      ) : (
        <div
          className={`${
            element === "cheat-sheet" ? "d-flex align-items-center justify-content-center" : "qualify-download-button"
          }`}
        >
          <button
            type="button"
            className={`${
              element === "cheat-sheet" ? "bg-danger-forms" : "bg-danger-forms-2"
            } text-white btn  mt-5 mb-3  fs-4`}
            disabled={""}
            onClick={handleDownload}
          >
            {element === "cheat-sheet" ? (
              <>
                <i className="fa-solid fa-download" style={{ color: "#ffffff" }}></i>
                <span className="cheat-sheet-title"> &nbsp;Download your cheat-sheet</span>
              </>
            ) : (
              <>
                <i className="fa-solid fa-download" style={{ color: "#ffffff" }}></i>
                &nbsp;Download
              </>
            )}
          </button>
        </div>
      )}
    </>
  );
};

export default CommomForm;
