import React, { useRef, useState } from "react";
import AuthService from "../../../services/Auth";
import { selectAuth, updateIsVisitedUser } from "../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

const PromocodePopUp = () => {
  const [promoCodePopUp, setPromoCodePopUp] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const { token } = useSelector(selectAuth);

  const dispatch = useDispatch();

  const promocodePopupRef = useRef(null);

  const handleReferralCodeChange = (e) => {
    setReferralCode(e.target.value);
    setErrorMessage("");
  };

  const handleApplyReferralCode = () => {
    if (!referralCode.trim()) {
      setErrorMessage("Promocode can't be empty");
      return;
    }
    const referralCodeData = { refferal_code: referralCode };

    AuthService.referralCode(referralCodeData, token)
      .then((response) => {
        const updatedIsUserVisited = { refferal_code_status: 3 };

        dispatch(updateIsVisitedUser(updatedIsUserVisited));
        toast.success(response?.data?.message);
        setPromoCodePopUp(false);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.message);
      });
  };

  const handleSkipReferralCode = () => {
    const referralCodeData = { refferal_code: "" };

    AuthService.referralCode(referralCodeData, token)
      .then((response) => {
        const updatedIsUserVisited = { refferal_code_status: 3 };

        dispatch(updateIsVisitedUser(updatedIsUserVisited));
        setPromoCodePopUp(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <>
      {promoCodePopUp && (
        <div className="popup-overlay-supportus step3-popup">
          <div className="popup" ref={promocodePopupRef}>
            <div className="popheader">
              <h2>Referral Code</h2>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                onClick={handleSkipReferralCode}
              >
                Skip
              </button>
            </div>
            <div className="popup-content p-3 d-flex flex-column justify-content-center align-items-center">
              <div className="formbox">
                <label className="form-label pt-16 ">
                  Referral Code <br />
                  (optional, only if you have been provided with one)
                </label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Referral Code"
                  value={referralCode}
                  onChange={handleReferralCodeChange}
                  className={`form-control form-field pt-16 `}
                />

                <div>{errorMessage && <p className="text-danger pt-16">{errorMessage}</p>}</div>
              </div>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button mt-3"
                onClick={handleApplyReferralCode}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromocodePopUp;
