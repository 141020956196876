import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectAuth } from "../redux/authSlice";

const ProtectedRoute = ({ element }) => {
  const user = useSelector(selectAuth);

  return <>{user?.token ? element : <Navigate to="/sign-in" />}</>;
};

export default ProtectedRoute;
