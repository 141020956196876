import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { selectAuth, updateActivePlan, updateSubscriptionInfo, signOutUser } from "../../redux/authSlice";
import PricingService from "../../services/PricingService";
import Loading_Payment from "../../assets/subscription-check/Loading_Payment.json";
import Lottie from "lottie-web";
import ProfileService from "../../services/ProfileService";

const SubscriptionStatus = () => {
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);

  const user = useSelector(selectAuth);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const token = user?.token;

  const queryParams = new URLSearchParams(location.search);

  const subscriptionId = queryParams.get("subscription_id");

  useEffect(() => {
    if (!subscriptionId) {
      navigate(-1);
    }
  }, []);

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: Loading_Payment
    });
  }, [subscriptionId]);

  useEffect(() => {
    if (subscriptionId && user?.token && user?.user?.is_supporter) {
      PricingService.checkSubscriptionPlan({
        headers: {
          Authorization: `token ${token}`
        },
        params: {
          subscription_id: subscriptionId
        }
      })
        .then((response) => {
          dispatch(updateSubscriptionInfo(response?.data?.data));

          setSubscriptionStatus(response?.data?.data?.status);

          if (response?.data?.data?.status === "ACTIVE") {
            getPlanName();
          } else {
            navigate("/pricing");
            toast.error("Oops! Subscription Not Completed");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate("/sign-in");
            toast.error("Please login again.");
            dispatch(signOutUser());
            return;
          }
        });
    }
  }, [subscriptionId]);

  const getPlanName = async () => {
    try {
      const response = await ProfileService.getProfile({
        headers: {
          Authorization: `token ${token}`
        }
      });
      const updatedActivePlan = response?.data?.data?.active_subscription;
      const is_supporter = response?.data.data.is_supporter;

      if (updatedActivePlan === null) {
        getPlanName();
      } else {
        if (is_supporter) {
          const updatedUser = { active_subscription: updatedActivePlan, free_plan_status: "paid" };

          dispatch(updateActivePlan(updatedUser));
          navigate("/transaction-success");

          toast.success("Congratulations! Your Subscription Is Successful");
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
      toast.error("Failed to fetch user data.");
    }
  };

  return (
    <>
      <div className="payment-loader d-flex align-items-center justify-content-center mt-5">
        <div id="react-logo" style={{ width: 200, height: 150 }}></div>
      </div>
      <p className="d-flex align-items-center justify-content-center pt-20 mt-3">Please Wait...</p>
    </>
  );
};

export default SubscriptionStatus;
