import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Supportai from "../../assets/home/supportai.png";
import Form from "../../utils/Form";
import AuthService from "../../services/Auth";
import { setHeader } from "../../utils/apiClient";
import { signInUser } from "../../redux/authSlice";
import SignUpPopUp from "../../assets/home/SignUpPopUp.jpeg";
import { error } from "jquery";
import * as landingPageCMSService from "../../services/CMSService";

const SupportUs = ({ popupref, handleClosePopup }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [signUpDisableStatus, setSignUpDisableStatus] = useState();
  const [testimonialsData, setTestimonialsData] = useState([]);

  useEffect(() => {
    const landingPageCMSServiceCall = async () => {
      try {
        const [testimonials] = await Promise.all([landingPageCMSService.testimonials()]);
        setTestimonialsData(testimonials?.data?.data);
      } catch (error) {}
    };
    landingPageCMSServiceCall();
  }, []);

  const testimonialSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    loop: true,
    margin: 10,
    autoplaySpeed: 3000,
    dots: true,
    pauseOnHover: false
  };

  // Facebook Login function start
  const handleLogin = (response) => {
    const { name, email, id } = response;

    const [firstName, ...lastNameArray] = name.split(" ");
    const lastName = lastNameArray.join(" ");

    // Fetch additional user details from Facebook's API
    axios
      .get(
        `https://graph.facebook.com/${response.userID}?fields=id,email,first_name,last_name&access_token=${response.accessToken}`
      )
      .then((response) => {
        if (response.accessToken !== "") {
          const socialLoginData = {
            login_type: "2",
            email: email,
            password: "",
            last_name: lastName,
            full_name: firstName,
            social_media_id: id,
            social_media_type: "facebook",
            is_supporter: true
          };

          AuthService.socialLogin(socialLoginData, setHeader()).then((response) => {
            const token = response?.data?.data?.token;
            const user = response?.data?.data?.user;
            dispatch(signInUser({ token: token, user: user }));
            navigate("/dashboard");
            toast.success(response?.data?.message);
          });
        }
      })
      .catch((error) => {
        console.log("Error fetching user details:", error);
      });
  };

  const onSubmit = (data) => {
    setSignUpDisableStatus(true);
    const formattedData = {
      ...data,
      mobile_number: data.mobile_number.replace(/\s/g, ""),
      login_type: "1",
      is_supporter: true
    };

    AuthService.signUp(formattedData)
      .then((response) => {
        navigate("/activate-account", {
          state: { email: formattedData.email, type: "EMAIL_ACTIVATION", supportUs: true }
        });
        toast.success(response?.data?.message);
        setSignUpDisableStatus(false);
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          if (error?.response?.data?.data?.refferal_status === false) {
            toast.error("Referral code is not valid.");
            setSignUpDisableStatus(false);
            return;
          }
          const { is_verified, is_supporter, is_active_plan } = error?.response?.data?.errors?.data;
          if (is_supporter === "True") {
            if (is_verified === "False") {
              AuthService.resendOtp({ type: "EMAIL_ACTIVATION", email: formattedData.email })
                .then((response) => {
                  navigate("/activate-account", {
                    state: { email: formattedData.email, type: "EMAIL_ACTIVATION", supportUs: true }
                  });
                  toast.success("Otp sent successfully");
                })
                .catch((error) => {
                  toast.error("Error resending OTP for email activation.");
                });
              navigate("/activate-account", {
                state: { email: formattedData.email, type: "EMAIL_ACTIVATION", supportUs: true }
              });
              toast.error(error?.response.data?.errors?.message[0]);
            } else {
              navigate("/sign-in");
              toast.success("You have already registered. Please login.");
            }
          } else {
            toast.error(error?.response.data?.message[0]);
          }
          handleClosePopup();
          setSignUpDisableStatus(false);
        }
      });
  };

  // Google Login function start
  const googlelogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // Fetch additional user details from Google's API
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`
          }
        })
        .then((response) => {
          const { email, given_name, email_verified, sub, family_name, picture } = response.data;

          if (tokenResponse?.access_token !== "") {
            const socialLoginData = {
              login_type: "2",
              email: email,
              password: "",
              full_name: given_name,
              last_name: family_name,
              social_media_id: sub,
              social_media_type: "google",
              is_supporter: true,
              profile_pic: picture
            };

            AuthService.socialLogin(socialLoginData, setHeader())
              .then((response) => {
                const token = response?.data?.data?.token;
                const user = response?.data?.data?.user;
                dispatch(signInUser({ token: token, user: user }));
                // navigate("/pricing");
                // handleClosePopup();
                // toast.success("Registration successful! Go ahead and explore our plans!");
                navigate("/dashboard");
                toast.success(response?.data?.message);
              })
              .catch((error) => {
                handleClosePopup();
                if (error?.response?.status === 403) {
                  const active_subscription = error?.response?.data?.data?.user?.active_subscription;
                  const is_supporter = error?.response?.data?.data?.user?.is_supporter;
                  if (is_supporter && active_subscription) {
                    navigate("/");
                    toast.success(
                      "Celebration mode: ON! You're officially part of the journey, and your plan's debut is on the way."
                    );
                  } else if (is_supporter && !active_subscription) {
                    const user = error?.response?.data?.data?.user;
                    navigate("/pricing");
                    dispatch(signInUser({ user: user }));
                    toast.success("You're in! Time to uncover the magic in our plans.");
                  }
                } else {
                  toast.error(error?.response?.data?.email[0]);
                }
              });
          }

          if (response?.response?.status === 403) {
            toast.error(response?.response?.data?.message);
          }
        })
        .catch((error) => {
          toast.error("Please try again");
        });
    }
  });

  return (
    <div className="popup-overlay supportpopup">
      <div className="popup aos-init" data-aos="zoom-in" data-aos-duration="1500" data-aos-delay="150" ref={popupref}>
        <div className="popheader">
          <h2> Sign Up Now!</h2>
          <button className="btn close-chat-btn primary-orange-gradient-ai-assistant-button" onClick={handleClosePopup}>
            Close
          </button>
        </div>
        <div className="popcontent">
          <div className="poptext d-flex justify-content-center">
            <div className="container">
              <h2 className="sign-up-heading mb-4">Let's take your sales skills to a whole new level.</h2>
              <p className="sign-up-subheading">
                Our sign up proccess is quick and easy, taking no more than 5 minutes to complete.
              </p>
              <Slider {...testimonialSettings}>
                {testimonialsData.map((testimonial, index) => (
                  <div key={index} className="card card-bg  mt-5">
                    <p className="card-review">{testimonial.discription}</p>
                    <div className=" d-lg-flex d-md-flex mt-4 justify-content-center align-items-center gap-4">
                      <div className="col-lg-3 col-md-3">
                        <img
                          className="rounded-circle shadow-1-strong sign-up-review-profile mb-4"
                          src={testimonial.user_image}
                          alt="avatar"
                        />
                      </div>
                      <div className="col-lg-9 col-md-9">
                        <div className="d-lg-flex flex-lg-column ">
                          <h5 className="d-flex justify-content-center justify-content-lg-start justify-content-md-start sign-up-reviewer-name">
                            {testimonial.name}
                          </h5>
                          <p className="d-flex justify-content-center justify-content-lg-start justify-content-md-start sign-up-reviewer-designation">
                            {testimonial.title}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div className="popform mt-5 mt-lg-0">
            <Form
              onSubmit={onSubmit}
              handleLogin={handleLogin}
              googlelogin={googlelogin}
              supportUs={true}
              signUpDisableStatus={signUpDisableStatus}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportUs;
