import React, { useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import WaveSurfer from "wavesurfer.js";
import { settings } from "./Settings";
import "./Player.css";
import Download from "../../../../assets/dashboard/Download.png";

const Player = ({ title, URL, peaks, downloadAudio }) => {
  const pause = require("./icons/pause.png");
  const play = require("./icons/play.png");

  const backend = peaks ? "MediaElement" : "WebAudio";

  const waveform = useRef(null);
  const [isLoaded, setLoaded] = useState(false);

  const [playing, setPlaying] = useState(false);
  const [icon, setIcon] = useState(play);
  const [timer, setTimer] = useState("00:00");
  const [totalDuration, setTotalDuration] = useState("00:00"); // Added total duration state
  const [playbackSpeed, setPlaybackSpeed] = useState(1);

  const [isPopupVisible, setPopupVisible] = useState(false);
  const selectRef = useRef(null);
  const popupTriggerRef = useRef(null);
  const selectPlayingAudioRef = useRef(null);

  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const container = useRef();

  useEffect(() => {
    if (!waveform.current) {
      waveform.current = WaveSurfer.create(settings(container.current, backend));
      waveform.current.load(URL, peaks);
    }

    onWaveFormLoaded();
    onFinishEvent();
    onTimerUpdatedEvent();
  }, [URL, peaks]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (playing) {
        const isButton = event?.target?.tagName === "BUTTON";
        const isIMG = event?.target?.tagName === "IMG";
        const hasClassNameForButton = event?.target?.className?.includes("audio-player__button");
        const hasClassNameForIMG = event?.target?.className?.includes("audio-player__img");

        if (
          playing &&
          selectPlayingAudioRef.current &&
          !selectPlayingAudioRef.current.contains(event.target) &&
          ((isButton && hasClassNameForButton) || (isIMG && hasClassNameForIMG))
        ) {
          handlePlay();
        }
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [playing]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isPopupVisible &&
        selectRef.current &&
        !selectRef.current.contains(event.target) &&
        popupTriggerRef.current !== event.target
      ) {
        setPopupVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isPopupVisible]);

  function onWaveFormLoaded() {
    waveform.current.on("ready", () => {
      setLoaded(true);
      const totalDurationInSeconds = waveform.current.getDuration();
      const totalMinutes = Math.floor(totalDurationInSeconds / 60);
      const totalSeconds = Math.floor(totalDurationInSeconds % 60);
      const formattedTotalDuration = `${totalMinutes}:${totalSeconds < 10 ? "0" : ""}${totalSeconds}`;

      setTotalDuration(formattedTotalDuration);
    });
  }

  function onFinishEvent() {
    waveform.current.on("finish", () => {
      setPlaying(false);
      setIcon(play);
      setTimer("00:00"); // Reset timer to 0 on playback completion
    });
  }

  function onTimerUpdatedEvent() {
    waveform.current.on("audioprocess", () => {
      const seconds = waveform.current.getCurrentTime();
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);
      const formattedTime = `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;

      setTimer(formattedTime);
    });
  }

  function handlePlay() {
    setPlaying(!playing);
    playing ? setIcon(play) : setIcon(pause);

    if (isLoaded) {
      waveform.current.playPause();
    } else {
      waveform.current.on("ready", () => {
        waveform.current.playPause();
      });
    }
  }

  function handlePlayBackSpeed(newSpeed) {
    togglePopup();
    setPlaybackSpeed(newSpeed);
    waveform.current.setPlaybackRate(newSpeed);
  }

  const handleDownload = (url) => {
    // Check if the URL is not empty or null before navigating
    if (url) {
      window.open(url, "_blank");
    }
  };

  return (
    <>
      <div className="d-flex chat-audio-player">
        <div className={`${isLoaded ? "audio-player__wrapper" : "loadingaudio-player__wrapper"} d-flex flex-row`}>
          <div className={`${isLoaded ? "audio-player__header" : "loadingaudio-player__header"}`}>
            {isLoaded ? (
              <button
                ref={selectPlayingAudioRef}
                data-tooltip-id="play-pause-button"
                data-tooltip-content={`${playing ? "Pause" : "Play"}`}
                onClick={handlePlay}
                className="audio-player__button"
              >
                <img className="audio-player__img" src={icon} alt="Play/Pause" />
              </button>
            ) : (
              <span className="loadingaudio-player__button">
                <img className="audio-player__img" src={icon} alt="Play/Pause" />
              </span>
            )}

            <div className="audio-player__header-text">{title}</div>
          </div>
          <div className={`${isLoaded ? "audio-player__body" : "loadingaudio-player__body"}`}>
            <div className="WaveformContianer player">
              <div className={` wave ${isLoaded ? "loaded" : "loading"}`} ref={container} />
              {!isLoaded && (
                <ul>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              )}
            </div>
          </div>
          <div className="audio-player__footer">
            <span className="audio-player__timer ms-3 d-flex">
              <span>{timer}</span>
              <span>&nbsp;/&nbsp;</span>
              <span>{totalDuration}</span>

              {isPopupVisible && (
                <select
                  className="form-select playbackSpeed position-absolute"
                  ref={selectRef}
                  onChange={(e) => handlePlayBackSpeed(e.target.value)}
                  multiple
                  aria-label="multiple select example"
                >
                  <option value="0.25">0.25x</option>
                  <option value="0.5">0.5x</option>
                  <option value="0.75">0.75x</option>
                  <option value="1">1x</option>
                  <option value="1.25">1.25x</option>
                  <option value="1.5">1.5x</option>
                  <option value="1.75">1.75x</option>
                  <option value="2">2x</option>
                </select>
              )}
            </span>
          </div>
          <span
            data-tooltip-id={`${isLoaded && "playback-speed"}`}
            className="d-flex justify-content-center align-items-center"
            id="popup-trigger"
          >
            <span
              type={`${isLoaded ? "button" : ""}`}
              className="ms-2"
              onClick={togglePopup}
              ref={popupTriggerRef}
              style={{ color: "#ffffff", fontSize: "13px" }}
            >
              {playbackSpeed}x
            </span>
          </span>
        </div>
        {downloadAudio && (
          <div>
            <button
              data-tooltip-id="download-audio"
              className="btn ms-2 chat-audio-download"
              style={{ color: "black" }}
              onClick={() => handleDownload(URL)}
            >
              <img src={Download} alt="download" className="download-button" />
            </button>
          </div>
        )}
      </div>
      <ReactTooltip id="playback-speed" place="bottom" content="Playback Speed" />
      <ReactTooltip id="play-pause-button" place="bottom" />
      <ReactTooltip id="download-audio" place="bottom" content="Download" />
    </>
  );
};

export default Player;
