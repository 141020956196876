import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate, Link, useLocation } from "react-router-dom";
import lottie from "lottie-web";
import loader1 from "../../../assets/ai-assistant/loader1.json";
import { MdSend } from "react-icons/md";
import { IoMdPlay } from "react-icons/io";
import { MdOutlineFeedback } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Loader from "../../../utils/Loader";

import UserAiChatService from "../../../services/UserAiChatService";
import UserAudioRequestService from "../../../services/AudioRequestService";
import DashboardService from "../../../services/DashboardService";
import step3AILady from "../../../assets/ai-assistant/step3AILady.png";
import { selectAuth, signOutUser, updatedIsWalkThroughTakenStatus } from "../../../redux/authSlice";
import ChatHistory from "./ChatHistory";
import aiChat from "../../../assets/ai-assistant/aiChat.png";
import AudioRecorder from "./AudioRecorder";
import femaleAccent from "../../../assets/ai-assistant/femaleAccent.png";
import maleAccent from "../../../assets/ai-assistant/maleAccent.png";
import Download from "../../../assets/dashboard/Download.png";
import Player from "./Audioplayer/Player";
import Supportai from "../../../assets/home/supportai.png";
import AudioRequestService from "../../../services/AudioRequestService";
import success from "../../../assets/auth/Success.png";
import Joyride from "react-joyride";

const AIAssistantStep3 = ({ onNext, onPrevious, data }) => {
  const [prompt, setPrompt] = useState("");
  const [disablePrompt, setDisablePrompt] = useState(false);
  const [currentChatId, setCurrentChatId] = useState();
  const [promptType, setPromptType] = useState("text");
  const [id, setId] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [audio, setAudio] = useState(null);
  const [recordedAudioFile, setRecordingAudioFile] = useState(null);
  const [s3FileURL, setS3FileURL] = useState("");
  const [isWalkThroughTourStep3, setIsWalkThroughTourStep3] = useState();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);

  // states for audio chat
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(0);
  const [paused, setPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [TTSLoader, setTTSLoader] = useState(false);
  const [transcriptRequest, setTranscriptRequest] = useState(false);
  // states for view more for solution findings
  const maxVisibleCharacters = 800;
  const [showPopup, setShowPopup] = useState(false);
  const [content, setContent] = useState();
  const [formattedContent, setFormattedContent] = useState([]);
  // state for voice accent
  const [voiceAccent, setVoiceAccent] = useState("");
  // state for free trial popup
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [feedbackButtonVisible, setFeedbackButtonVisible] = useState(true);
  const [feedbackError, setFeedbackError] = useState("");
  const [feedbackDisableStatus, setFeedbackDisableStatus] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  // states for pagination
  const [isTopOfScroll, setIsTopOfScroll] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [scrollHeight, setScrollHeight] = useState(0);
  const [chatLoadingLoader, setChatLoadingLoader] = useState(false);

  const { token, user } = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const barsRef = useRef([]);
  const popupRef = useRef(null);
  const chatSectionRef = useRef(null);

  const location = useLocation();

  useEffect(() => {
    setIsWalkThroughTourStep3(location?.state);
  }, [location.state]);

  useEffect(() => {
    if (isWalkThroughTourStep3 === "walkthrough") {
      setIsJoyrideRunning(true);
    }
  });

  // Checks if data is available. If no, navigate to dashboard

  useEffect(() => {
    const handleBlockedNavigation = () => {
      navigate("/dashboard");
    };
    if (data === null) {
      navigate("/dashboard");
      return;
    } else if (data === "walkthrough") {
      setIsLoading(false);
    } else {
      if (!data?.solution_findings) {
        setId(data.id);
        setIsLoading(true);
        checkAudioSolutionStatus();
      } else {
        setTimeout(() => {
          if (!data?.feedback?.length) {
            setShowPopover(true);
          }
        }, 45000);
        setId(data?.id);
        setContent(data?.solution_findings);
        setVoiceAccent(data?.gender_output_audio);
        setFormattedContent(data?.solution_findings?.split("\n\n"));
        const chatId = localStorage.getItem("currentChatId");
        const audioChat = localStorage.getItem("currentAudioChat");
        const audioChatId = localStorage.getItem("currentAudioChatId");
        const parsedAudio = JSON.parse(audioChat);
        getChatHistory(data.id, pageNumber);
        // gets the response for the chat if it was not generated
        if (Number(audioChatId) === Number(data.id) && chatId) {
          setCurrentChatId(Number(chatId));
          fetchSpecificChatHistory2(Number(chatId), data.id);
          return;
        }
        // converts speech to text
        if (Number(audioChatId) === Number(data.id) && parsedAudio?.user_audio_input) {
          setDisablePrompt(true);
          setTTSLoader(true);
          const refreshedChat = true;
          speechToText(parsedAudio.user_audio_input, data.id, refreshedChat, parsedAudio);
          return;
        }
      }
    }
    window.addEventListener("popstate", handleBlockedNavigation);
    return () => {
      window.removeEventListener("popstate", handleBlockedNavigation);
    };
  }, [data, feedbackSubmitted]);

  // Pagination feature for chat

  useEffect(() => {
    const handleScroll = () => {
      setIsTopOfScroll(chatSectionRef.current.scrollTop === 0);
    };

    const container = chatSectionRef.current;

    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isTopOfScroll) {
      if (pageNumber <= totalPages) {
        setChatLoadingLoader(true);
        getChatHistory(id, pageNumber);
      }
    }
  }, [isTopOfScroll]);

  // useEffect for supporter ttotalPages after 15 seconds


  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (freeTrialPopupRef.current && !freeTrialPopupRef.current.contains(event.target)) {
  //       handleCloseFreeTrialPopup();
  //     }
  //   };

  //   if (freeTrialPopupRef) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [freeTrialPopupRef]);

  // Scroll to the bottom whenever content or chatHistory changes

  useEffect(() => {
    if (chatSectionRef.current && !isTopOfScroll) {
      chatSectionRef.current.scrollTop = chatSectionRef.current.scrollHeight;
    } else {
      chatSectionRef.current.scrollTo(0, 300);
    }
  }, [content, chatHistory]);

  // Check if the scroll has reached the end of the chat section

  useEffect(() => {
    const chatSectionElement = chatSectionRef.current;

    const handleScroll = () => {
      if (chatSectionElement.scrollTop + chatSectionElement.clientHeight === chatSectionElement.scrollHeight) {
        window.scrollTo(0, document.body.scrollHeight);
      }
    };

    chatSectionElement.addEventListener("scroll", handleScroll);

    return () => {
      chatSectionElement.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getChatHistory = async (id, page) => {
    try {
      const response = await UserAiChatService.getConversations(id, token, page);
      setTotalPages(Math.ceil(response.data.count / response.data.page_size));

      if (pageNumber === 1) {
        setChatHistory(response?.data?.results);
      } else {
        setChatHistory([...chatHistory, ...response?.data?.results]);
      }
      setPageNumber(page + 1);
      setVoiceAccent(response?.data?.results[response?.data?.results?.length - 1].voice_type);
      setChatLoadingLoader(false);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
      if (error?.response?.status === 404) {
        return;
      }
      setChatLoadingLoader(false);
    }
  };

  // Loader animation state change in isLoading

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: loader1
    });
  }, [isLoading]);

  // Audio recorder timer

  useEffect(() => {
    let intervalId;

    if (isActive && !paused) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond = String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        let computedMinute = String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        if (counter === 30) {
          clearInterval(intervalId);
        } else {
          setCounter((counter) => counter + 1);
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter, paused]);

  // Pause Timer on pause recording

  useEffect(() => {
    if (isActive && paused) {
      pauseTimer();
      stopBarsAnimation();
    } else if (isActive && !paused) {
      startBarsAnimation();
    } else {
      // stopBarsAnimation();
    }
  }, [paused, isActive]);

  // Bar animation on audio recording

  useEffect(() => {
    barsRef.current = Array.from(document.querySelectorAll(".bar"));
    startBarsAnimation();
  }, [isActive]);

  // View more popup

  // useEffect(() => {
  //   const handleOutsideClick = (event) => {
  //     if (popupRef.current && !popupRef.current.contains(event.target)) {
  //       handleClosePopup();
  //     }
  //   };

  //   if (showPopup) {
  //     document.addEventListener("mousedown", handleOutsideClick);
  //   } else {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleOutsideClick);
  //   };
  // }, [showPopup]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  function restartTimer() {
    setCounter(0);
    setSecond("00");
    setMinute("00");
    stopBarsAnimation();
  }

  function pauseTimer() {
    setCounter(counter);
    setSecond(second);
    setMinute(minute);
  }

  function changeActiveStatus(val) {
    setIsActive(val);
  }

  const startBarsAnimation = () => {
    barsRef.current.forEach((item) => {
      // Random move
      item.style.animationDuration = `${Math.random() * (2.5 - 0.2) + 0.2}s`;
      item.style.animationPlayState = "running";
    });
  };

  const stopBarsAnimation = () => {
    barsRef.current.forEach((item) => {
      // Reset animation properties
      item.style.animationPlayState = "paused";
    });
  };

  const changePauseStatus = (val) => {
    setPaused(val);
  };

  const fetchSpecificChatHistory = async (queryId) => {
    const response = await UserAiChatService.getConversations(id, token, 1);
    const chatData = response?.data?.results;
    const specificChat = chatData.find((chat) => chat.id === queryId);

    if (specificChat?.ai_text_response !== null) {
      // If the answer is available, update the chat history and stop further calls
      setChatHistory(response?.data?.results);
      localStorage.removeItem("currentChatId");
      setAudio("");
      setS3FileURL("");
      return true;
    } else {
      // If the answer is not available, schedule the next API call after 10 seconds
      return fetchSpecificChatHistory(queryId);
    }
  };

  const fetchSpecificChatHistory2 = async (queryId, id) => {
    try {
      setDisablePrompt(true);
      const response = await UserAiChatService.getConversations(id, token, 1);
      const chatData = response?.data?.results;
      const specificChat = chatData.find((chat) => chat.id === queryId);

      if (specificChat?.ai_text_response !== null) {
        // If the answer is available, update the chat history and stop further calls
        setChatHistory(response?.data?.results);
        setDisablePrompt(false);
        localStorage.removeItem("currentChatId");
      } else {
        // If the answer is not available, schedule the next API call after 10 seconds
        fetchSpecificChatHistory2(queryId, id);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
    }
  };

  // checks audio solution generation status for 'completed'

  const checkAudioSolutionStatus = async () => {
    try {
      const response = await DashboardService.getHistory({
        headers: {
          Authorization: `token ${token}`
        }
      });
      const audioRequests = response?.data.data;
      const specificAudioRequest = audioRequests.find((req) => req.id === data?.id);

      if (specificAudioRequest.status === "completed") {
        // setContent(specificAudioRequest.solution_findings);
        // setFormattedContent(specificAudioRequest.solution_findings.split('\n\n'));
        setIsLoading(false);

        navigate("/ai-assistant/3", {
          state: specificAudioRequest
        });
      } else if (specificAudioRequest.status === "failed") {
        navigate("/dashboard");
        toast.error("Error generating solution. Try again.");
        setIsLoading(false);
      } else {
        setTimeout(() => checkAudioSolutionStatus(), 5000);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
    }
  };

  // function to send text message

  const sendTextMessage = async (conversations) => {
    localStorage.removeItem("currentAudioChat");
    localStorage.removeItem("currentAudioChatId");

    if (voiceAccent === null) setVoiceAccent("female");

    var textPrompt = prompt;
    var modifiedPrompt = textPrompt.replace(/^'|'$/g, "");
    let userChatData;

    if (s3FileURL) {
      userChatData = {
        user_text_input: modifiedPrompt,
        user_audio_input: s3FileURL,
        message_object: `[SystemMessage(content=f'You are a helpful assistant with name as AI sales coach who respond to user query based on knowledage base: {final_data} ') ${conversations}]`,
        voice_type: voiceAccent
      };
      const lastChat = chatHistory.length - 1;

      const lastObject = chatHistory[lastChat];

      const updatedObject = {
        user_audio_input: audio,
        user_text_response: prompt,
        currentChat: true
      };

      const updatedChatHistory = [...chatHistory];
      updatedChatHistory[lastChat] = updatedObject;

      setChatHistory(updatedChatHistory);
      setAudio(null);
      setPromptType("text");
    } else {
      userChatData = {
        user_text_input: modifiedPrompt,
        user_audio_input: "",
        message_object: `[SystemMessage(content=f'You are a helpful assistant with name as AI sales coach who respond to user query based on knowledage base: {final_data} ') ${conversations}]`,
        voice_type: voiceAccent
      };
      setChatHistory([{ user_text_input: prompt, currentChat: true }, ...chatHistory]);
    }

    const response = await UserAiChatService.sendTextMessage(id, userChatData, token);
    setCurrentChatId(response?.data.data.chat_id);
    if (response?.data?.status) {
      const getConversations = await UserAiChatService.getConversations(id, token, 1);
      setChatHistory(getConversations?.data?.results);
      localStorage.setItem("currentChatId", response?.data.data.chat_id);
      localStorage.setItem("currentAudioChatId", id);
      const answer = await fetchSpecificChatHistory(response?.data.data.chat_id);
      if (answer) return true;
      else return false;
    }
  };

  // function to send audio message

  const sendAudioMessage = async (conversations, file_url) => {
    if (voiceAccent === null) setVoiceAccent("female");

    const userChatData = {
      user_text_input: "",
      user_audio_input: file_url,
      message_object: `[SystemMessage(content=f'You are a helpful assistant with name as AI sales coach who respond to user query based on knowledage base: {final_data} ') ${conversations}]`,
      voice_type: voiceAccent
    };

    const response = await UserAiChatService.sendTextMessage(id, userChatData, token);
    getChatHistory(id);
    setCurrentChatId(response?.data.data.chat_id);
    localStorage.setItem("currentChatId", response?.data.data.chat_id);
    const answer = await fetchSpecificChatHistory(response?.data.data.chat_id);
    if (answer) return true;
    else return false;
  };

  const changePromptType = (val) => {
    setPromptType(val);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleKeyPress();
    }
  };

  // convert speech to text

  const speechToText = async (file_url, id, refreshedChat, parsedAudio) => {
    if (refreshedChat) {
      await getChatHistory(id, 1);
      try {
        const response = await UserAiChatService.getConversations(id, token, 1);
        setAudio(parsedAudio?.user_audio_input);
        setPromptType("");
        setTranscriptRequest(true);
      } catch (error) {
        deleteAudio();
        toast.error(error?.response?.data?.message);
        setTTSLoader(false);
        setDisablePrompt(false);
      }
    }
    // speech to text
    try {
      const data = {
        audio_file_url: file_url,
        audio_id: id
      };
      const audioToTextResponse = await UserAiChatService.speechToText(data, token);
      setTTSLoader(false);
      setDisablePrompt(false);
      if (!audioToTextResponse?.data?.data?.transcript) {
        deleteAudio();
        setTTSLoader(false);
        setDisablePrompt(false);
        toast.error("No transcript found.");
        setPromptType("text");
      } else {
        setPrompt(audioToTextResponse?.data?.data?.transcript);
        localStorage.removeItem("currentAudioChat");
        localStorage.removeItem("currentAudioChatId");
        setS3FileURL(file_url);
      }
      // localStorage.removeItem("currentAudioChat")
    } catch (error) {
      deleteAudio();
      toast.error("Please try again.");
      setTTSLoader(false);
      setDisablePrompt(false);
    }
  };

  // function to send audio message

  const sendAudioChat = async (recordedAudioFile) => {
    try {
      setDisablePrompt(true);
      setTTSLoader(true);
      setTranscriptRequest(true);

      const data = { audio_file_name: "problem.wav", audio_id: id };

      // call to s3_url_generator api
      const response = await UserAiChatService.s3UrlGeneratorAudioChat(data, token);

      const { upload_url, file_url } = response.data.data;

      // setChatHistory([...chatHistory, { user_audio_input: audio, currentChat: true, isAudioChat: true }]);

      // put request to s3 pre-assigned url
      await axios.put(upload_url, recordedAudioFile, {
        headers: {
          "Content-Type": "audio/wav",
          "x-amz-acl": "public-read"
        },
        // Enable tracking of upload progress
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        }
      });
      localStorage.setItem("currentAudioChat", JSON.stringify({ user_audio_input: file_url, currentChat: true }));
      localStorage.setItem("currentAudioChatId", id);
      speechToText(file_url, id);
    } catch (error) {
      toast.error(error?.response?.data?.message?.error[0]);
      setDisablePrompt(false);
      setTTSLoader(false);
    }
  };

  const handlePrompt = (e) => {
    setPrompt(e.target.value);
  };

  const handleKeyPress = async (e) => {
    var textPrompt = prompt;
    var modifiedPrompt = textPrompt.replace(/^'|'$/g, "");

    if (prompt !== "") {
      try {
        setDisablePrompt(true);
        setPrompt("");

        const res = await UserAiChatService.getConversations(id, token, 1);
        let conversations = "";

        if (res.status === false) {
          conversations += `, AIMessage(content=${"'''" + data.solution_findings + "'''"})`;
          conversations += `, HumanMessage(content=${"'''" + modifiedPrompt + "'''"})`;
        } else {
          if (res?.data?.results.length > 5) {
            res?.data?.results.slice(-5).map((res) => {
              if (res.ai_text_response === "Failed" || res.ai_text_response === null) {
                conversations += `, HumanMessage(content=${"'''" + res.user_text_input + "'''"})`;
                conversations += `, AIMessage(content=${"'''" + "'''"})`;
              } else {
                conversations += `, HumanMessage(content=${"'''" + res.user_text_input + "'''"})`;
                conversations += `, AIMessage(content=${"'''" + res.ai_text_response + "'''"})`;
              }
            });
          } else {
            conversations += `, AIMessage(content=${"'''" + data.solution_findings + "'''"})`;
            res?.data?.results.map((res) => {
              if (res.ai_text_response === "Failed" || res.ai_text_response === null) {
                conversations += `, HumanMessage(content=${"'''" + res.user_text_input + "'''"})`;
                conversations += `, AIMessage(content=${"'''" + "'''"})`;
              } else {
                conversations += `, HumanMessage(content=${"'''" + res.user_text_input + "'''"})`;
                conversations += `, AIMessage(content=${"'''" + res.ai_text_response + "'''"})`;
              }
            });
          }

          conversations += `, HumanMessage(content=${"'''" + modifiedPrompt + "'''"})`;
          // conversations += `, AIMessage(content=${res.ai_text_response})`;
        }

        const answer = await sendTextMessage(conversations);
        if (answer) {
          changePromptStatus(false);
          setTranscriptRequest(false);
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        if (error?.response?.status === 404) {
          let conversations = "";

          conversations += `, AIMessage(content=${"'''" + data.solution_findings + "'''"})`;
          conversations += `, HumanMessage(content=${"'''" + modifiedPrompt + "'''"})`;

          const answer = await sendTextMessage(conversations);
          if (answer) setDisablePrompt(false);
          return;
        }
        changePromptStatus(false);
      }
    }
  };

  const handleAudio = (url) => {
    setAudio(url);
  };

  const deleteAudio = () => {
    setAudio(null);
    setS3FileURL("");
    setRecordingAudioFile(null);
    localStorage.removeItem("currentAudioChat");
    localStorage.removeItem("currentAudioChatId");
    // const updatedChatHistory = [...chatHistory];
    // updatedChatHistory.pop();
    // setChatHistory(updatedChatHistory);
    getChatHistory(id, 1);
    setPrompt("");
    setPromptType("text");
  };

  const makeFile = (file) => {
    setRecordingAudioFile(file);
  };

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  const changePromptStatus = (val) => {
    setDisablePrompt(val);
  };

  const handleNavigation = () => {
    navigate("/dashboard");
    window.location.reload();
  };

  const handleFeedbackMessage = (e) => {
    setFeedbackError("");
    setFeedbackMessage(e.target.value);
  };

  const handleSendFeedback = () => {
    setFeedbackDisableStatus(true);
    if (!feedbackMessage) {
      setFeedbackError("Please provide feedback.");
      setFeedbackDisableStatus(false);
      return;
    }
    if (/^\s*$/.test(feedbackMessage)) {
      setFeedbackError("Feedback cannot be empty.");
      setFeedbackDisableStatus(false);
      return;
    }

    AudioRequestService.sendFeedback(
      {
        audio: id,
        feedback_message: feedbackMessage
      },
      token
    )
      .then((response) => {
        setFeedbackSubmitted(true);

        setTimeout(() => {
          setShowPopover(false);
          checkAudioSolutionStatus();
          setTimeout(() => {
            setFeedbackButtonVisible(false);
          }, 2000);
        }, 2000);
        setFeedbackDisableStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setFeedbackDisableStatus(false);
      });
  };
  // Tour step start
  const tourSteps = [
    {
      target: ".chatheight",
      content: "This area displays AI-generated solutions to your questions.",
      disableBeacon: "true"
    },
    {
      target: ".chat-prompt-input",
      content: " Enter your new question to receive additional solutions.",
      disableBeacon: "true"
    },
    {
      target: ".walkthrough-dummy-class",
      content: " Enter your new question to receive additional solutions.",
      disableBeacon: "true"
    }
  ];

  const handleJoyrideCallback = async (data) => {
    try {
      const { status, type } = data;
      if (data.action === "skip") {
        const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
        dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
        setIsJoyrideRunning(false);
        const updatedState = "skip";
        navigate("/ai-assistant/3", { state: updatedState });
      }
      if (status === "finished" && type === "step:after") {
        // Check if the last step is reached
        const isSecondLastStep = tourSteps.length - 2;
        if (isSecondLastStep) {
          // Navigate to the desired route
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/profile/edit-profile", { state: "walkthrough" });
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/dashboard");
        toast.error(error?.response?.data?.message?.message[0]);
      } else if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
      }
    }
  };
  // Tour step end

  const walkthroughBtnText = {
    last: "Next"
  };

  return (
    <section className="m-lg-5 m-3">
      {isWalkThroughTourStep3 === "walkthrough" && (
        <Joyride
          steps={tourSteps}
          showSkipButton={true}
          run={isJoyrideRunning}
          continuous={true}
          locale={walkthroughBtnText}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#ed7f5a",
              zIndex: 100000
            },
            tooltip: {
              fontFamily: "lato"
            },
            buttonClose: {
              fontFamily: "lato"
            }
          }}
        />
      )}

      {isLoading && isWalkThroughTourStep3 !== "skip" && (
        <div className="overlay d-flex flex-column">
          <div id="react-logo" style={{ width: 300, height: 200 }}></div>
          <p className="loading-text">Processing...</p>
        </div>
      )}      
      <div className="container ai-assistant-stepper-2 mt-4 ai-assistant-font-family">
        {/* view more popup start */}
        {showPopup && (
          <div className="popup-overlay">
            <div className="popup" ref={popupRef}>
              <div className="popup-content p-3 d-flex flex-column">
                <img src={aiChat} className="chatbot-ellipse mb-2" alt="Chatbot" width={34} />
                <div className="chattext">
                  {formattedContent?.map((para) => {
                    return <p>{para}</p>;
                  })}
                </div>
                <span>
                  <button
                    className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                    onClick={handleClosePopup}
                  >
                    Close
                  </button>
                </span>
              </div>
            </div>
          </div>
        )}
        {/* view more popup end */}
        <div className=" p-2 ai-chat-lady-container">
          <div className={` px-3 ${!isLoading && "accents-margin"}`}>
            <p className="roboto-700 fs-30 color-gray-text text-center">Accent Voices</p>
            <div className="accentCards justify-content-center">
              <div
                className={`${
                  voiceAccent === "female" ? "active-accent-voice" : "inactive-accent-voice"
                } accent-voices-option py-2 px-2 d-flex justify-content-around justify-content-center align-items-center`}
                type="button"
                onClick={() => setVoiceAccent("female")}
              >
                <div className="d-flex align-items-center">
                  <img src={femaleAccent} alt="Female Accent" />
                  <span className="d-flex flex-column mx-3">
                    <span className="roboto-500">Bella(F)</span>
                    <span className="accent-voices-subtitle">Young Adult</span>
                  </span>
                </div>
                <span className="accent-voices-btn">
                  <IoMdPlay />
                </span>
              </div>
              <div
                className={`${
                  voiceAccent === "male" ? "active-accent-voice" : "inactive-accent-voice"
                } accent-voices-option py-2 px-2 d-flex justify-content-around align-items-center flex-wrap`}
                type="button"
                onClick={() => setVoiceAccent("male")}
              >
                <div className="d-flex align-items-center">
                  <img src={maleAccent} alt="Male Accent" />
                  <span className="d-flex flex-column mx-3">
                    <span className="roboto-500">Terrell (M)</span>
                    <span className="accent-voices-subtitle">Middle-Aged</span>
                  </span>
                </div>
                <span className="accent-voices-btn">
                  <IoMdPlay />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-11 chat-interface p-lg-3 p-1 mx-lg-5 my-lg-5">
            <div className="p-3 d-flex flex-column chatheight" ref={chatSectionRef}>
              <div className="step-3-chat my-2 p-3">
                {!isLoading ? (
                  <div
                    className={`d-flex flex-column ${
                      content?.length > 500 ? "solution-findings-height" : "solution-findings-maxheight"
                    }`}
                  >
                    <img src={aiChat} className="chatbot-ellipse mb-2" alt="Chatbot" width={34} />
                    <span>
                      <>
                        <div className="chattext">
                          {" "}
                          {formattedContent?.map((para) => {
                            return <p>{para}</p>;
                          })}
                        </div>
                        <div className="d-flex align-items-center">
                          {data?.output_audio_file && <Player URL={data?.output_audio_file} downloadAudio={true} />}
                        </div>
                      </>
                    </span>
                  </div>
                ) : (
                  <span>
                    <div className="lds-ellipsis">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>{" "}
                  </span>
                )}
              </div>
              {chatLoadingLoader && <Loader />}
              <ChatHistory data={chatHistory} currentChatId={currentChatId} ID={id} refresh={getChatHistory} />
            </div>
            <div className="input-group chat-prompt-container px-3 chat-prompt-responsive-padding">
              {promptType === "text" ? (
                <input
                  type="text"
                  autoComplete="off"
                  className={`form-control chat-prompt-input chat-prompt p-3 ${
                    promptType === "text" ? "chat-prompt-80" : "chat-prompt-120"
                  }`}
                  id="specificSizeInputGroupUsername"
                  value={prompt}
                  onChange={(e) => handlePrompt(e)}
                  onKeyDown={handleEnter}
                  placeholder={`${TTSLoader ? "Transcribing audio..." : "Type Or Record Your Query"}`}
                  disabled={disablePrompt}
                />
              ) : (
                <div
                  className={`form-control ${
                    promptType === "text" ? "chat-prompt-80" : "chat-prompt-120"
                  } chat-prompt chat-prompt-input d-flex align-items-center`}
                >
                  <div className={`${audio ? "d-flex" : "d-none"} flex-column w-100 audio-transcript`}>
                    {audio && <Player URL={audio}></Player>}
                    {audio && transcriptRequest && (
                      <textarea
                        type="text"
                        autoComplete="off"
                        className="step3-transcript-input"
                        id="specificSizeInputGroupUsername"
                        value={prompt}
                        onChange={(e) => handlePrompt(e)}
                        onKeyDown={handleEnter}
                        placeholder={`${TTSLoader ? "Transcribing audio..." : "Type Or Record Your Query"}`}
                        disabled={disablePrompt}
                      />
                    )}
                  </div>
                  {isActive && (
                    <div className="audio-recorder px-3 py-2 d-lg-flex justify-content-center align-items-center gap-3">
                      <div className="audio-recoder-timer-mobile">
                        <span className="minute">{minute}</span>
                        <span>:</span>
                        <span className="second">{second}</span>
                      </div>
                      <div className="sound-wave">
                        <div className="bar" ref={(el) => (barsRef.current[0] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[1] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[2] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[3] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[4] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[5] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[6] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[7] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[8] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[9] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[10] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[11] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[12] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[13] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[14] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[15] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[16] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[17] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[18] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[19] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[20] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[21] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[22] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[23] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[24] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[25] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[26] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[27] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[28] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[29] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[30] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[31] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[32] = el)}></div>
                        <div className="bar" ref={(el) => (barsRef.current[33] = el)}></div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {TTSLoader ? (
                <div className="input-group-text  chat-prompt chat-prompt-120 chat-prompt-record-icon chat-prompt-record-icon-120">
                  <div class="jumpy-bars ">
                    <div class="bars" id="bar1"></div>
                    <div class="bars" id="bar2"></div>
                    <div class="bars" id="bar3"></div>
                    <div class="bars" id="bar4"></div>
                    <div class="bars" id="bar5"></div>
                    <div class="bars" id="bar6"></div>
                    <div class="bars" id="bar7"></div>
                  </div>
                </div>
              ) : prompt === "" ? (
                <div
                  className={`input-group-text action-tool-recorder chat-prompt ${
                    promptType === "text" ? "chat-prompt-80" : "chat-prompt-120"
                  } chat-prompt-record-icon`}
                >
                  <AudioRecorder
                    changePromptType={changePromptType}
                    handleAudio={handleAudio}
                    audio={audio}
                    sendAudioChat={sendAudioChat}
                    makeFile={makeFile}
                    stopTimer={stopTimer}
                    restartTimer={restartTimer}
                    pauseTimer={pauseTimer}
                    isActive={isActive}
                    changeActiveStatus={changeActiveStatus}
                    stopBarsAnimation={stopBarsAnimation}
                    startBarsAnimation={startBarsAnimation}
                    paused={paused}
                    changePauseStatus={changePauseStatus}
                    displayStatus={disablePrompt}
                    counter={counter}
                  />
                </div>
              ) : (
                <>
                  {s3FileURL && prompt && (
                    <div className="input-group-text chat-prompt chat-prompt-120 chat-prompt-delete-audio-icon">
                      <span
                        className="text-send-icon record-delete-btn"
                        data-tooltip-id="delete"
                        onClick={deleteAudio}
                        type="button"
                      >
                        <i className="fa-solid fa-trash" style={{ color: "#ffffff" }}></i>
                      </span>
                    </div>
                  )}
                  <div
                    className={`input-group-text chat-prompt chat-prompt-send-icon ${
                      promptType === "text" ? "chat-prompt-send-icon-80" : "chat-prompt-send-icon-120"
                    }`}
                  >
                    <span
                      className={`${
                        promptType === "text" ? "text-send-icon chat-send-button" : " text-send-icon record-send-button"
                      }`}
                      data-tooltip-id="send-text-chat"
                      onClick={handleKeyPress}
                      type="button"
                    >
                      <MdSend />
                    </span>
                  </div>
                </>
              )}
            </div>
            {!data?.feedback?.length && (
              <p className="mt-2 chat-prompt-footer roboto-500 text-center">
                All the results are generated by AI, If you get any wrong answers share feedback
              </p>
            )}
          </div>
        </div>
        {!data?.feedback?.length && (
          <div className="row">
            <div className="col-lg-8 p-lg-3 p-1 mx-lg-4 m-auto m-none"></div>
            <div className="col-lg-3 ms-lg-4 mb-4">
              <div className="feedback-content">
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    {feedbackButtonVisible && data?.feedback?.length === 0 && (
                      <OverlayTrigger
                        trigger="click"
                        className={`dashboard-avatar-popover ${showPopover ? "fadeIn" : ""}`}
                        placement="top-end"
                        show={showPopover}
                        onToggle={setShowPopover}
                        overlay={
                          <Popover id="feedback-popover">
                            <Popover.Body className="feedback-popover-body">
                              {feedbackSubmitted ? (
                                // {true ? (
                                <div className="d-flex flex-column justify-content-center align-items-center">
                                  <img width={100} src={success} alt="success" />
                                  <p className="text-center fs-22 mt-3">
                                    Thank you for your feedback, your thoughts matter!
                                  </p>
                                  {/* {setFeedbackButtonVisible(false)} */}
                                </div>
                              ) : (
                                <div>
                                  <p className="feedback-popup-heading">Your feedback is valuable to us</p>
                                  <div className="d-flex align-items-center justify-content-center">
                                    <div className="d-flex flex-column align-items-end justify-content-center">
                                      <textarea
                                        className="feedback-textarea"
                                        placeholder="Your Feedback"
                                        rows={6}
                                        cols={50}
                                        value={feedbackMessage}
                                        onChange={(e) => handleFeedbackMessage(e)}
                                      />
                                      <span className="mt-1 text-danger">{feedbackError && feedbackError}</span>
                                      {/* <span className="my-1">{feedbackMessage.length} / 500</span> */}
                                      <button
                                        className="btn primary-orange-gradient-ai-assistant-button"
                                        onClick={handleSendFeedback}
                                        disabled={feedbackDisableStatus}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <div className="d-flex responsive-steps-padding">
                          <button className="d-flex justify-content-center btn primary-orange-gradient-ai-assistant-button">
                            <span>
                              <MdOutlineFeedback />
                            </span>
                            <span className="ms-2">Share Feedback</span>
                          </button>
                        </div>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* tooltips start */}
      <ReactTooltip id="send-text-chat" place="bottom" content="Send" />
      <ReactTooltip id="delete" place="bottom" content="Delete" />
      {/* tooltips end */}
    </section>
  );
};

export default AIAssistantStep3;
