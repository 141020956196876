import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import { IoIosMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { BsTelephoneFill } from "react-icons/bs";
import { TailSpin } from "react-loader-spinner";

import "../style.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ContactUsService from "../../../services/ContactUsService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ContactUsForm = ({ teamData, imageLoaded }) => {
  const [formButtonDisableStatus, setFormButtonDisableStatus] = useState(false);

  const navigate = useNavigate();

  const contactUsTitleRef = useRef(null);

  const scrollToContactUsTitle = () => {
    if (contactUsTitleRef.current) {
      contactUsTitleRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToContactUsTitle();
  });

  // Validation schema start
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .test("is-not-empty", "Please enter valid First name", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your First name"),
    email: Yup.string()
      .email("Please enter a valid email")
      .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, "Please enter a valid email")
      .required("Please enter your email"),
    subject: Yup.string()
      .test("is-not-empty", "Please enter subject", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your subject"),
    message: Yup.string()
      .test("is-not-empty", "Please enter Message", (value) => {
        return value.trim() !== "";
      })
      .required("Please enter your message")
  });
  // Validation schema end

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = (data) => {
    setFormButtonDisableStatus(true);
    ContactUsService.contactUsData(data)
      .then((response) => {
        toast.success(response?.data?.message);
        navigate("/");
        setFormButtonDisableStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setFormButtonDisableStatus(false);
      });
  };

  return (
    <section className="contact-us-background  text-white">
      <div className=" container-fluid contact-us-container">
        <p className="fs-60 p-lg-2  text-capitalize text-center" ref={contactUsTitleRef}>
          Let's Get in touch!
        </p>
        <p className="text-center">Get in touch with a friendly member of our team.</p>
        <section className="teams_welcom_sec">
          <div className="container">
            <div className="row">
              {imageLoaded ? (
                <p className="image-loader d-flex justify-content-center align-items-center">
                  <TailSpin width="30" color="#FF8DAF" />
                </p>
              ) : (
                <>
                  {teamData.map((member) => (
                    <div className="col-lg-2 col-md-6 col-sm-6 contact_us_team-card-layout" key={member.id}>
                      <div className="teamcard_contact-us">
                        <img src={member.profile_image} alt={member.name} />

                        <h5 className="white-color mt-3 mb-0">{member.name}</h5>
                        <p className="white-color">{member.designation}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </section>
        <div className="row contact-us-card d-flex align-items-center justify-items-center">
          <div className="col-lg-5 col-md-12 col-sm-12 container contact-us-details mb-5">
            <div className="contact-us-content  d-flex align-items-center justify-content-center ">
              <div>
                <div className="row mb-3">
                  <div className="col-2">
                    <BsTelephoneFill className="contact-us-phone-icon" />
                  </div>
                  <div className="col-8">
                    <p>Phone</p>
                    <p className="pt-18">
                      <a href="tel:+15853806184" className="contact-us-number">
                        +1 (585) 380-6184
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <IoIosMail className="contact-us-mail-icon" />
                  </div>
                  <div className="col-8">
                    <p>Chat to us</p>

                    <p className="pt-18">
                      <a href="mailto:support@aisalescoach.ai" className="contact-us-mail">
                        support@aisalescoach.ai
                      </a>
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-2">
                    <IoLocationSharp className="contact-us-location-icon" />
                  </div>
                  <div className="col-8">
                    <p>Office</p>
                    <p className="pt-18">880 3rd Ave, New York, NY 10022, US</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-12 col-sm-12">
            <div className=" container  col-lg-8">
              <form onSubmit={handleSubmit(onSubmit)}>
                {/* Name field start */}
                <div className="formbox">
                  <label className="form-label pt-16 ">Name</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Name"
                    className={`form-control form-field pt-16 ${errors.name ? "is-invalid" : ""}`}
                    {...register("name")}
                  />
                  {errors.name && <div className="invalid-feedback pt-16">{errors.name.message}</div>}
                </div>
                {/* Name field end */}

                <div className="formbox">
                  <label className="form-label pt-16 ">Email</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Email"
                    className={`form-control form-field pt-16 ${errors.email ? "is-invalid" : ""}`}
                    {...register("email")}
                  />
                  {errors.email && <div className="invalid-feedback pt-16">{errors.email.message}</div>}
                </div>
                <div className="formbox">
                  <label className="form-label pt-16 ">Subject</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Subject"
                    className={`form-control form-field pt-16 ${errors.subject ? "is-invalid" : ""}`}
                    {...register("subject")}
                  />
                  {errors.subject && <div className="invalid-feedback pt-16">{errors.subject.message}</div>}
                </div>
                <div className="formbox">
                  <label className="form-label pt-16 ">Message</label>
                  <textarea
                    autoComplete="off"
                    placeholder="Message"
                    className={`form-control form-field contact-us-text-area pt-16 ${
                      errors.message ? "is-invalid" : ""
                    }`}
                    {...register("message")}
                  />
                  {errors.message && <div className="invalid-feedback pt-16">{errors.message.message}</div>}
                </div>
                <div className="formbox ContactUsformBox">
                  <button
                    type="submit"
                    className="orange-gradient-auth-button pt-20"
                    disabled={formButtonDisableStatus}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="row contact-us-card support-section d-flex align-items-center justify-items-center mt-3">
          <h4>First Response Time</h4>
          <p>
            AI sales coach is committed to providing an initial response to all customer inquiries within a specific
            timeframe. The response time will vary depending on the priority or severity level of the request, as
            defined below:
          </p>
          <div>
            <ul>
              <li>Critical issues: within 3 hour.</li>
              <li>Other issues: within 24 hours.</li>
            </ul>
          </div>
          <h4>Business Hours</h4>
          <p>Our support team operates during the following business hours: Monday to Friday: 9:00 AM to 5:00 PM.</p>
        </div>
      </div>
    </section>
  );
};

export default ContactUsForm;
