import React from "react";
import { useSelector } from "react-redux";

import "../style.css";
import { selectAuth } from "../../../redux/authSlice";

const DashboardTitle = () => {
  const user = useSelector(selectAuth);

  return (
    <>
      <div className="container">
        <div className="dashboard-title d-flex  justify-content-between aligin-items-center mt-3">
          <span className="d-flex gap-2">
            <h1 className="pt-30-normal">Welcome,</h1> <h1 className="pt-30-normal">{user.user.full_name}</h1>
          </span>
        </div>
      </div>
    </>
  );
};

export default DashboardTitle;
