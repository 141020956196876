import React, { useState, useEffect } from "react";
import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import PricingTitle from "./components/PricingTitle";
import SalesCoachPricing from "./components/SalesCoachPricing";
import PricingService, { salesPricing } from "../../services/PricingService";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/authSlice";
import { Helmet } from "react-helmet";

const Pricing = () => {
  const [salesPricingData, setSalesPricingData] = useState([]);

  // const data = useSelector(selectAuth);
  // const token1 = data.token;

  useEffect(() => {
    PricingService.getPaypalPricing()
      .then((response) => {
        setSalesPricingData(response?.data?.data);
      })
      .catch((error) => {
        console.error(error);
      });

    // const salesPricingServiceCall = async () => {
    //   const [salesPrice] = await Promise.all([salesPricing()]);
    //   setSalesPricingData(salesPrice?.data?.data);
    // };
    // salesPricingServiceCall();
  }, []);

  return (
    <>
      <Helmet>
        <title>AI Sales Coach Pricing: Find Your Perfect Plan</title>
        <meta
          name="description"
          content="Explore our competitive AI Sales Coach pricing to empower your sales team.
          Find cost-effective plans designed to drive results and boost revenue."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <PricingTitle />
        <SalesCoachPricing data={salesPricingData} />
      </InnerLayout>
    </>
  );
};

export default Pricing;
