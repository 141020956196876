import apiClient, { setHeader } from "../utils/apiClient";

const getPaypalPricing = () => {
  return apiClient.get("/subscription_list/");
};

const payPalPayment = (data, token) => {
  return apiClient.post("/paypal_subscription_plan/", data, token);
};

const freePlan = (token) => {
  return apiClient.post("/free_subscription_plan/", "", token);
};

const checkSubscriptionPlan = (headers) => {
  return apiClient.get("/check_subscription_plan/", headers);
};

const cancelTransaction = (token) => {
  return apiClient.post("/transaction/", null, token);
};
const PricingService = {
  getPaypalPricing,
  payPalPayment,
  checkSubscriptionPlan,
  freePlan,
  cancelTransaction
};

export default PricingService;
