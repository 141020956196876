import React from 'react'

import './style.css'
import InnerLayout from '../../layouts/OuterLayout/InnerLayout'
import { ReactComponent as TitleDesign } from "../../assets/pricing/TitleDesign.svg";

const Docs = () => {
  return (
    <InnerLayout>
      <section className="pricing-title-background text-white">
        <div className="pricing-card-padding  d-flex align-items-center justify-content-center">
          <div>
            <h1 className=" prising-title d-flex  align-items-center justify-content-center">Docs</h1>
            <br />
            <span className="d-flex  align-items-center justify-content-center">Home/Docs</span>
          </div>
          <TitleDesign className="pricing-title-image px-20px" />
        </div>
      </section>
    </InnerLayout>
  )
}

export default Docs
