import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AuthLayout from "../../../layouts/OuterLayout/AuthLayout";
import Succes from "../../../assets/auth/Success.png";

const OtpSuccess = () => {
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate("/sign-in");
    // toast.success('Please log in to access your account.');
  };
  return (
    <AuthLayout>
      <div className="container">
        {/* Success page start */}

        <div className="row justify-content-center">
          <div className="col-9 col-lg-6">
            {/* Success image start */}
            <div className="mt-4 d-flex justify-content-center">
              <img src={Succes} alt="success" />
            </div>
            {/* Success image end */}

            {/* Success text start */}
            <div className="success-text my-5 d-flex justify-content-center">
              <h2>Success!!!</h2>
            </div>
            {/* Success text end */}

            {/*Back to Sign in button start */}
            <div className="formbox">
              <button type="submit" className="orange-gradient-auth-button pt-20" onClick={handleSuccess}>
                Back to Sign in
              </button>
            </div>
            {/*Back to Sign in button end */}
            <div className="copyrighttext-position">
              <div className="copyright   footer d-flex justify-content-center mb-3">
                &copy; {new Date().getFullYear()} All rights reserved.
              </div>
            </div>
          </div>
        </div>

        {/* Success page end */}
      </div>
    </AuthLayout>
  );
};

export default OtpSuccess;
