import React from "react";
import AIChat from "./AIChat";
import UserChat from "./UserChat";

const ChatHistory = ({ data, currentChatId, ID, refresh }) => {
  const reversedData = [...data].reverse();
  return (
    <>
      {reversedData?.map((chat, i) => {
        return (
          <div key={i}>
            <UserChat text={chat.user_text_input} audio={chat.user_audio_input} />
            {/* do not render this if the current chat is of type audio */}
            {!chat.currentChat && !chat.isAudioChat && (
              <AIChat
                text={chat.ai_text_response}
                audio={chat.ai_audio_response}
                displayEllipses={(currentChatId === chat.id && chat.ai_text_response === null) || chat.currentChat}
                chatId={chat.id}
                ID={ID}
                currentChatId={currentChatId}
                refresh={refresh}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default ChatHistory;
