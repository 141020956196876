import React from "react";

import DashboardLayout from "../../../layouts/OuterLayout/DashboardLayout";
import { Stepper } from "react-form-stepper";

const AIAssistantLayout = ({ element, active }) => {
  return (
    <DashboardLayout>
      <h1 className="text-center m-lg-4 m-md-3 m-2 ai-assistant-heading">AI Assistant</h1>
      <Stepper
        steps={[{ label: "UPLOAD" }, { label: "PROBLEM OUTPUT" }, { label: "SOLUTION" }]}
        activeStep={active}
        styleConfig={{
          completedBgColor: "#ffffff",
          inactiveBgColor: "#ffffff",
          inactiveTextColor: "#000000",
          completedTextColor: "#ffffff",
          size: "3rem"
        }}
        connectorStyleConfig={{ style: "dashed", disabledColor: "#EF9543", activeColor: "#EF9543" }}
      />
      {element}
    </DashboardLayout>
  );
};

export default AIAssistantLayout;
