import apiClient, { setHeader } from "../utils/apiClient";

const headers = {
    headers: {
        Accept: 'application/json',
    },
};

const s3UrlGeneratorAudioChat = (data, token) => {
    return apiClient.post("/s3_chat_audio_uploader/", data, setHeader(headers, token));
}

const sendTextMessage = (audio_id, data, token) => {

    return apiClient.post(`/ai_chat/?audio_id=${audio_id}`, data,  setHeader(headers, token));
}

const getConversations = (audio_id, token, page) => {

    return apiClient.get(`ai_chat/?audio_id=${audio_id}&page=${page}`, setHeader(headers, token));
}

const speechToText = (data, token) => {

    return apiClient.post('/speech_to_text/', data, setHeader(headers, token));
}

const UserAiChatService = {

    sendTextMessage,
    getConversations,
    s3UrlGeneratorAudioChat,
    speechToText
}

export default UserAiChatService;