import apiClient, { setHeader } from "../utils/apiClient";

const headers = {
  headers: {
    Accept: "application/json"
  }
};

const audioUploadLimitCheck = (token) => {
  return apiClient.post("/request_validation/", "", setHeader(headers, token));
};

const s3UrlGenerator = (data, token) => {
  return apiClient.post("/s3_url_generator/", data, setHeader(headers, token));
};

const postAudioRequest = (data, token) => {
  return apiClient.post("/user_audio_request", data, setHeader(headers, token));
};

const getUserData = (token) => {
  return apiClient.get("/user_audio_request", setHeader(headers, token));
};

const problemFieldUpdate = (audio_id, data, token) => {
  return apiClient.patch(`/user_audio_request?audio_id=${audio_id}`, data, setHeader(headers, token));
};
const sendFeedback = (data, token) => {
  return apiClient.post("/feedback/", data, setHeader(headers, token));
};

const UserAudioRequestService = {
  postAudioRequest,
  s3UrlGenerator,
  problemFieldUpdate,
  getUserData,
  audioUploadLimitCheck,
  sendFeedback
};

export default UserAudioRequestService;
