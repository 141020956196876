import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import AuthLayout from "../../../layouts/OuterLayout/AuthLayout";
import "../style.css";
import OtpTimer from "../partials/OtpTimer";
import AuthService from "../../../services/Auth";
import { signInUser } from "../../../redux/authSlice";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const { type, supportUs } = location.state || {};
  const [timeLeft, setTimeLeft] = useState(Number(localStorage.getItem("otpTimer")) || 60);
  const initialTimeLeft = 60;

  const otpInputRef = useRef(null);
  const dispatch = useDispatch();

  const otplocation = location.state?.email;
  useEffect(() => {
    if (!otplocation) {
      navigate("/");
    }
  });

  useEffect(() => {
    // Reset the timer whenever the component is mounted
    localStorage.setItem("otpTimer", initialTimeLeft.toString());
    setTimeLeft(initialTimeLeft);
  }, []);

  useEffect(() => {
    // Reset the timer whenever the component is unmounted
    return () => {
      localStorage.removeItem("otpTimer");
    };
  }, []);

  const handleKeyDown = (e) => {
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 86) {
      // Ctrl+V (Command+V for macOS)
      const hiddenInput = document.createElement("input");
      document.body.appendChild(hiddenInput);
      hiddenInput.focus();

      // Listen for the clipboard paste event
      hiddenInput.addEventListener("paste", (pasteEvent) => {
        const pastedData = pasteEvent.clipboardData.getData("text");
        setOtp(pastedData);
        hiddenInput.remove();
      });

      // Wait for the paste event
      setTimeout(() => {
        hiddenInput.remove();
      }, 0);
    }
  };

  const maskedEmail = email
    ? email.replace(
        /^(.{3})(.*)(?=@)/,
        (_, firstFourChars, remainingChars) => firstFourChars + "*".repeat(remainingChars.length)
      )
    : "";

  useEffect(() => {
    // Set the email value from the location state when component mounts
    setEmail(location.state?.email || "");
  }, [location.state]);

  const handleResend = (e) => {
    e.preventDefault();
    // Check the type value to determine which API to call
    if (type === "EMAIL_ACTIVATION") {
      // Resend email activation OTP
      AuthService.resendOtp({ type: "EMAIL_ACTIVATION", email })
        .then((response) => {
          navigate("/activate-account", { state: { email, type: "EMAIL_ACTIVATION", supportUs: supportUs } });
          toast.success("OTP resend to your email");
        })
        .catch((error) => {
          toast.error("Error resending OTP for email activation.");
        });
    } else if (type === "FORGOT_PASSWORD_OTP") {
      // Resend forgot password OTP
      AuthService.resendOtp({ type: "FORGOT_PASSWORD_OTP", email })
        .then((response) => {
          navigate("/enter-otp", { state: { email, type: "FORGOT_PASSWORD_OTP" } });
          toast.success("OTP resend to your email");
        })
        .catch((error) => {
          toast.error("Error resending OTP for forgot password.");
        });
    }
  };
  // OTP timer function end

  // validation schema start
  const validationSchema = Yup.object().shape({
    otp: Yup.string().required().length(6)
  });
  // validation schema ebd

  const {
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleVerifyOtp = (event) => {
    event.preventDefault();
    if (errors.otp) {
      return;
    }

    const data = {
      otp,
      email: email
    };

    if (type !== "FORGOT_PASSWORD_OTP") {
      AuthService.validateOtp(data)
        .then((response) => {
          if (response?.status === 200) {
            dispatch(signInUser({ token: response?.data?.data?.token, user: response?.data?.data?.user }));
            navigate("/dashboard");
            toast.success(response?.data?.message);
            // if (!supportUs) {
            // navigate("/dashboard");
            // toast.success(response?.data?.message);
            // dispatch(signInUser({ token: data?.token, user: data?.user, transaction: data?.transaction }));
            // }
            // else {
            //   const { data } = response?.data;
            //   navigate("/pricing");
            //   toast.success("You're in! Time to uncover the magic in our plans.");
            // }
          }
        })
        .catch((error) => {
          toast.error("Invalid OTP");
        });
    } else {
      AuthService.forgetPasswordVerifyOTP(data)
        .then((response) => {
          if (!response.data.status) {
            navigate("/confirm-password");
            toast.error("Invalid OTP");
          } else {
            navigate("/forgot-password");
            toast.success("Verification Successful");
          }
          navigate("/confirm-password", { state: { token: response.data.token } });
        })
        .catch((error) => {
          toast.error("Invalid OTP");
        });
    }
  };

  const handlePaste = (e) => {
    if (e.clipboardData) {
      e.preventDefault();

      const pastedData = e.clipboardData.getData("text");

      setOtp(pastedData);
    }
  };
  const buttonDisabled = !otp || errors.otp;

  return (
    <AuthLayout>
      {/* Enter OTP page start */}

      <div className="row justify-content-center">
        {/* otp description start */}
        <h1 className="mb-5 d-flex justify-content-center">Enter the OTP</h1>
        <div className="description mx-5">
          <p className="d-flex justify-content-center pt-16">Enter the OTP that we sent to your email {maskedEmail}.</p>
          <p className="d-flex justify-content-center pt-16">Be careful not to share the code with anyone.</p>
        </div>
        {/* otp description end */}

        <div className="col-lg-6">
          {/*OTP input field start */}

          <form onSubmit={handleVerifyOtp}>
            <div id="otp" className="inputs d-flex flex-row justify-content-center my-5"></div>
            <div className="d-flex justify-content-center otpinput">
              <OtpInput
                value={otp}
                onChange={setOtp}
                shouldAutoFocus="true"
                numInputs={6}
                ref={otpInputRef}
                renderSeparator={
                  <span
                    style={{
                      fontSize: "7px",
                      marginLeft: "5px",
                      marginRight: "5px"
                    }}
                  >
                    {""}
                  </span>
                }
                renderInput={(props) => (
                  <input
                    {...props}
                    onKeyDown={handleKeyDown} // Pass the handleKeyDown function
                    onPaste={handlePaste}
                  />
                )}
                inputType="tel"
                inputStyle={{
                  width: "100%",
                  height: "10vh",
                  backgroundColor: "#F5F5F5",
                  outline: "none",
                  borderRadius: "8px",
                  border: "1px solid #D3687B"
                }}
              />
            </div>
            {/*OTP input field end */}

            {/* Verify OTP button start */}
            <div className="mb-3 mt-5">
              <button type="submit" className="orange-gradient-auth-button pt-20" disabled={buttonDisabled}>
                Verify OTP
              </button>
            </div>
            {/* Verify OTP button end */}

            {/* Resend OTP link start */}
            <div className=" d-flex justify-content-center mb-5">
              <span className="d-flex gap-2 pt-16 flex-wrap">
                Didn’t get the code?{" "}
                <OtpTimer
                  minutes={0}
                  seconds={60}
                  onResend={handleResend}
                  textclassName="your-text-class"
                  timerclassName="your-timer-class"
                  timeLeft={timeLeft}
                />
              </span>
            </div>
            {/* Resend OTP link end */}
          </form>
        </div>
        <div className="copyrighttext-position">
          <div className="copyright   footer d-flex justify-content-center mb-3">
            &copy; {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>

      {/* Enter OTP page end */}
    </AuthLayout>
  );
};

export default Otp;
