import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Lottie from "lottie-web";

import { ReactComponent as Graph2 } from "../../../assets/dashboard/graph2.svg";
import { ReactComponent as Graph3 } from "../../../assets/dashboard/graph3.svg";
import DashboardService from "../../../services/DashboardService";
import { selectAuth, selectLatestStatus, signOutUser } from "../../../redux/authSlice";
import { InfinitySpin } from "react-loader-spinner";
import AudioRequestService from "../../../services/AudioRequestService";
import FreeTrialExpire from "../../../assets/dashboard-navbar/FreeTrialExpire.json";
import { BiPurchaseTag } from "react-icons/bi";
import { LuFileAudio } from "react-icons/lu";

const DashboardCard = () => {
  // constants start
  const freePlanExpired = "Your Free Trial Period has Expired.";
  const freePlanCancelled = "Your Free Trial Period has been Cancelled.";
  const noActivePlan = "You currently have no active plan.";
  const paidPlanExpired = "Your Paid Plan has been Expired.";
  const paidPlanCancelled = "Your Paid Plan has been Cancelled.";

  const freePlanDesc =
    "You may choose a package plan to support us in the development of this project, once the platform officially launches you will get three months access for the price of one month for the package you commit to purchasing now. We will let you know by email when the official platform launches with features listed working and ready to use.";
  const paidPlanExpiredDesc =
    'Your plan has expired, and you no longer have access to our premium services. Don\'t miss out on all the benefits we offer. Click "Get Started" below to view our available plans and choose the one that suits you best to continue enjoying our services.';
  const paidPlanCancelledDesc =
    "We're sorry to see that you've cancelled your plan. Your access to our premium services is currently inactive. If you change your mind or want to explore other options, please click \"Get Started\" below to view our available plans.";

  // constants end

  const [showPopup, setShowPopup] = useState(false);
  const [planTitle, setPlanTitle] = useState();
  const [planDescription, setPlanDescription] = useState();

  const user = useSelector(selectAuth);

  const latestStatus = useSelector(selectLatestStatus);

  const token = user?.token;

  const [userProcessDetails, setUserProcessDetails] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const planType = user?.user?.free_plan_status;

  const active_subscription = user?.user?.active_subscription;

  const planPopupRef = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    DashboardService.getUserProcessDetails(
      {
        headers: {
          Authorization: `token ${token}`
        }
      },

      "week"
    )
      .then((response) => {
        const responseData = response?.data?.data;
        setUserProcessDetails(responseData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, [latestStatus]);

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#free-trail-expire-loader"),
      animationData: FreeTrialExpire
    });
  });

  const handleLinkClick = (event) => {
    const check = async () => {
      try {
        if (planType === "expired") {
          setShowPopup(true);
          setPlanTitle(freePlanExpired);
          setPlanDescription(freePlanDesc);
        } else if (planType === "cancel") {
          setShowPopup(true);
          setPlanTitle(freePlanCancelled);
          setPlanDescription(freePlanDesc);
        } else if (active_subscription) {
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/ai-assistant/1", {
              state: true
            });
          }
        } else {
          if (planType === "not") {
            setShowPopup(true);
            setPlanTitle(noActivePlan);
            setPlanDescription(freePlanDesc);
          } else if (planType === "paid" && !active_subscription) {
            setShowPopup(true);
            setPlanTitle(paidPlanCancelled);
            setPlanDescription(paidPlanCancelledDesc);
          }
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          navigate("/dashboard");
          toast.error(error?.response?.data?.message?.error[0]);
          // }
        } else if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
        }
      }
      return;
    };

    const isTrue = window.location.pathname.includes("/ai-assistant");
    if (isTrue) {
      event.preventDefault();
    } else {
      check();
    }
  };

  const handleCloseFreeTrialPopup = () => {
    setShowPopup(false);
  };

  const handleUpgradePlan = () => {
    navigate("/pricing");
  };
  return (
    <section className="mb-5">
      {showPopup && (
        <div className="popup-overlay-free-trial-expire trial-expire-popup">
          <div className="expire-popup aos-init" data-aos="zoom-in" data-aos-duration="1100" ref={planPopupRef}>
            <div className="expire-free-trail-popheader">
              <h2>{planTitle}</h2>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                onClick={handleCloseFreeTrialPopup}
              >
                Close
              </button>
            </div>
            <div className="free-trail-expire-popup-content p-3 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex row justify-content-center align-items-center">
                <div className="col-lg-6 d-flex justify-content-center">
                  {/* <img src={FreeTrailExpire} alt="supportai" className="img-fluid" width={400} /> */}
                  <div id="free-trail-expire-loader" className="free-trial-popup-lootie-loader"></div>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start">
                  <div className="free-trial-expire-popup-style">{planDescription}</div>
                </div>
                <span className="d-flex align-item-center justify-content-center">
                  <Link className="btn mt-5 close-chat-btn primary-orange-gradient-ai-assistant-button" to="/pricing">
                    Get Started
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 mb-3">
            <div className="card dashboard-card-3">
              <div className="card-body d-flex align-items-center justify-content-between gap-2">
                <div className="card-description">
                  <p className="pt-20">Total Audio Process</p>
                  {isLoading ? (
                    <div className="count-loader">
                      <InfinitySpin width="80" color="#FF8DAF" />
                    </div>
                  ) : (
                    <h2>{userProcessDetails?.total_in_process}</h2>
                  )}
                </div>
                <div>
                  <Graph3 />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 mb-3">
            <div className="card dashboard-card-2">
              <div className="card-body d-flex align-items-center justify-content-between gap-2">
                <div className="card-description">
                  <p className="pt-20">Solution Generated</p>
                  {isLoading ? (
                    <div className="count-loader">
                      <InfinitySpin width="80" color="#60B2FF" />
                    </div>
                  ) : (
                    <h2>{userProcessDetails?.total_completed}</h2>
                  )}
                </div>
                <div>
                  <Graph2 />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex row justify-content-center align-items-center mt-2">
          {active_subscription === "Free Trial Plan(By Refferal)" ||
          active_subscription === "Free Trial" ||
          active_subscription === null ? (
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center mb-lg-0 mb-md-0 mb-3">
              <button className="start-button orange-gradient-auth-button upgrade-btn" onClick={handleUpgradePlan}>
                <BiPurchaseTag className="start-btn-icon " />
                <span className="start-btn-text">Upgrade Plan</span>
              </button>
            </div>
          ) : (
            ""
          )}
          <div className="col-lg-6 col-md-6 col-sm-12 d-flex justify-content-center">
            <button className="start-button orange-gradient-auth-button upload-convo-btn" onClick={handleLinkClick}>
              <LuFileAudio className="start-btn-icon" />
              <span className="start-btn-text">Upload Sales Conversation Audio</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardCard;
