import React from "react";
import DashboardLayout from "../../OuterLayout/DashboardLayout";
import ProfileHeader from "./components/ProfileHeader";
import ProfileSidebar from "./components/ProfileSidebar";
import "./style.css";

const ProfileLayout = ({ children }) => {
  return (
    <DashboardLayout>
      <section>
        <div className="container">
          {/* <div className="card my-4"> */}
          <ProfileHeader />
          <div className="card-body">
            <div className="row justify-content-center position-relative">
              <div className="col-lg-3">
                <ProfileSidebar />
              </div>
              <div className="col-lg-9">{children}</div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </section>
    </DashboardLayout>
  );
};

export default ProfileLayout;
