import React, { createRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./style.css";
import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import SalesCoach from "./components/SalesCoach";
import SalesDevelopment from "./components/SalesDevelopment";
import SalesWhatsNew from "./components/SalesWhatsNew";
import Testimonials from "./components/Testimonials";
import * as landingPageCMSService from "../../services/CMSService";
import { useDispatch, useSelector } from "react-redux";
import { visited, firstVisit, removedVisited, selectAuth } from "../../redux/authSlice";
import SupportUs from "../../layouts/OuterLayout/SupportUs";

const Home = () => {
  const [loaded, setLoaded] = useState(false);
  const user = useSelector(selectAuth);
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("load", () => {
      setLoaded(true);
      if (user?.token) {
        navigate("/dashboard");
      }
    });

    return () => {
      window.removeEventListener("load", () => {
        setLoaded(false);
      });
    };
  }, []);

  const isAuthenticated = useSelector(selectAuth);

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  let popupRef = createRef(null);

  const [salesCoachData, setSalesCoachData] = useState([]);
  const [salesDevelopmentData, setSalesDevelopmentData] = useState([]);
  const [salesWhatsNewData, setSalesWhatsNewData] = useState([]);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [faqsData, setFaqsData] = useState([]);

  useEffect(() => {
    const landingPageCMSServiceCall = async () => {
      try {
        const [salesCoach, salesDevelopment, salesWhatsNew, testimonials, faqs] = await Promise.all([
          landingPageCMSService.salesCoachBanner(),
          landingPageCMSService.salesDevelopment(),
          landingPageCMSService.salesWhatsNew(),
          landingPageCMSService.testimonials(),
          landingPageCMSService.faqs()
        ]);
        setSalesCoachData(salesCoach?.data?.data);
        setSalesDevelopmentData(salesDevelopment?.data?.data);
        setSalesWhatsNewData(salesWhatsNew?.data.data);
        setTestimonialsData(testimonials?.data?.data);
        setFaqsData(faqs?.data?.data);
      } catch (error) {}
    };
    landingPageCMSServiceCall();
  }, []);

  // useEffect(() => {
  //   const visited = user;
  //   if (!visited && !isAuthenticated?.token && {}) {
  //     setTimeout(() => setShowPopup(true), 2000);
  //     dispatch(firstVisit({ visited: true }));
  //   } else {
  //     setShowPopup(false);
  //   }
  //   const handleBeforeUnload = (event) => {
  //     dispatch(removedVisited());
  //     setShowPopup(false);
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const setRef = (ref) => {
    popupRef.current = ref;
  };

  return (
    <>
      <Helmet>
        <title>AI Sales Coach: Boost Your Sales Performance with AI</title>
        <meta
          name="description"
          content="Elevate your sales game with AI sales coach. Unlock revenue growth, boost
      performance, and close deals like never before."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1,
max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1,
max-image-preview:large, max-video-preview:-1"
        />
      </Helmet>
      <InnerLayout>
        {showPopup && <SupportUs popupref={setRef} handleClosePopup={handleClosePopup} />}
        <SalesCoach data={salesCoachData} />
        <SalesDevelopment data={salesDevelopmentData} />
        <SalesWhatsNew data={salesWhatsNewData} />
        <Testimonials testimonials={testimonialsData} faqs={faqsData} />
      </InnerLayout>
    </>
  );
};

export default Home;
