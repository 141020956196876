import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CgProfile } from "react-icons/cg";
import { VscSignOut } from "react-icons/vsc";
import Lottie from "lottie-web";

import { profilePic, selectAuth, signOutUser, updateActivePlan } from "../../redux/authSlice";
import DashboardIcon from "../../assets/dashboard-navbar/DashboardIcon.png";
import AssistanIcon from "../../assets/dashboard-navbar/AssistanIcon.png";
import HistoryIcon from "../../assets/dashboard-navbar/HistoryIcon.png";
import ResourcesIcon from "../../assets/dashboard-navbar/ResourcesIcon.png";
import TeamIcon from "../../assets/dashboard-navbar/TeamIcon.png";
import ForumIcon from "../../assets/dashboard-navbar/ForumIcon.png";
import Search from "../../assets/dashboard-navbar/Search.png";
import Notification from "../../assets/dashboard-navbar/Notification.png";
import Settings from "../../assets/dashboard-navbar/Settings.png";
import Line from "../../assets/dashboard-navbar/Line.png";
import Avtar3 from "../../assets/dashboard-navbar/Avtar3.png";
import ProfileService from "../../services/ProfileService";
import { ReactComponent as AiSalesLogoWhite } from "../../assets/dashboard-navbar/ai-sales-final-logo 1.svg";
import AudioRequestService from "../../services/AudioRequestService";
import FreeTrailExpire from "../../assets/dashboard-navbar/Freetrailexpire.png";
import "./style.css";
import FreeTrialExpire from "../../assets/dashboard-navbar/FreeTrialExpire.json";

const DashboardHeader = () => {
  // constants start
  const freePlanExpired = "Your Free Trial Period has Expired.";
  const freePlanCancelled = "Your Free Trial Period has been Cancelled.";
  const noActivePlan = "You currently have no active plan.";
  const paidPlanExpired = "Your Paid Plan has been Expired.";
  const paidPlanCancelled = "Your Paid Plan has been Cancelled.";

  const freePlanDesc =
    "You may choose a package plan to support us in the development of this project, once the platform officially launches you will get three months access for the price of one month for the package you commit to purchasing now. We will let you know by email when the official platform launches with features listed working and ready to use.";
  const paidPlanExpiredDesc =
    'Your plan has expired, and you no longer have access to our premium services. Don\'t miss out on all the benefits we offer. Click "Get Started" below to view our available plans and choose the one that suits you best to continue enjoying our services.';
  const paidPlanCancelledDesc =
    "We're sorry to see that you've cancelled your plan. Your access to our premium services is currently inactive. If you change your mind or want to explore other options, please click \"Get Started\" below to view our available plans.";

  // constants end

  const [showPopover, setShowPopover] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [planTitle, setPlanTitle] = useState();
  const [planDescription, setPlanDescription] = useState();
  const planPopupRef = useRef(null);
  const user = useSelector(selectAuth);

  const user2 = useSelector(profilePic);
  const { token } = useSelector(selectAuth);

  const planType = user?.user?.free_plan_status;
  const active_subscription = user?.user?.active_subscription;

  const popupRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setShowPopover(false);
  };

  const handleCloseFreeTrialPopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#free-trail-expire-loader"),
      animationData: FreeTrialExpire
    });
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopover) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopover]);

  const handleSignOut = () => {
    // Dispatch the signOut action to clear the user authentication state
    dispatch(signOutUser());
    // Navigate to the desired page after sign out (e.g., back to the sign-in page)
    navigate("/sign-in");
    toast.success("You have successfully logged out.");
  };

  const handleLinkClick = (event) => {
    const check = async () => {
      try {
        if (planType === "expired") {
          setShowPopup(true);
          setPlanTitle(freePlanExpired);
          setPlanDescription(freePlanDesc);
        } else if (planType === "cancel") {
          setShowPopup(true);
          setPlanTitle(freePlanCancelled);
          setPlanDescription(freePlanDesc);
        } else if (active_subscription) {
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/ai-assistant/1", {
              state: true
            });
          }
        } else {
          if (planType === "not") {
            setShowPopup(true);
            setPlanTitle(noActivePlan);
            setPlanDescription(freePlanDesc);
          } else if (planType === "paid" && !active_subscription) {
            setShowPopup(true);
            setPlanTitle(paidPlanCancelled);
            setPlanDescription(paidPlanCancelledDesc);
          }
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          // if (error?.response?.data?.message?.freePlanExpired[0] === 'True') {
          // // if (active_subscription === null) {
          //   dispatch(updateActivePlan({ active_subscription: null, free_plan_status: 'expired' }));
          //   setShowPopup(true);
          //   setPlanTitle(freePlanExpired)
          //   setPlanDescription(freePlanDesc)
          // } else {
          navigate("/dashboard");
          toast.error(error?.response?.data?.message?.error[0]);
          // }
        } else if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
        }
      }
      return;
    };

    const isTrue = window.location.pathname.includes("/ai-assistant");
    if (isTrue) {
      event.preventDefault();
    } else {
      check();
    }
  };

  const imageUrl = user?.user.profile_pic ? user?.user.profile_pic : Avtar3;

  return (
    <header id="header" className="dashboard-header">
      {showPopup && (
        <div className="popup-overlay-free-trial-expire trial-expire-popup">
          <div className="expire-popup aos-init" data-aos="zoom-in" data-aos-duration="1100" ref={planPopupRef}>
            <div className="expire-free-trail-popheader">
              <h2>{planTitle}</h2>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                onClick={handleCloseFreeTrialPopup}
              >
                Close
              </button>
            </div>
            <div className="free-trail-expire-popup-content p-3 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex row justify-content-center align-items-center">
                <div className="col-lg-6 d-flex justify-content-center">
                  {/* <img src={FreeTrailExpire} alt="supportai" className="img-fluid" width={400} /> */}
                  <div id="free-trail-expire-loader" className="free-trial-popup-lootie-loader"></div>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start">
                  <div className="free-trial-expire-popup-style">{planDescription}</div>
                </div>
                <span className="d-flex align-item-center justify-content-center">
                  <Link className="btn mt-5 close-chat-btn primary-orange-gradient-ai-assistant-button" to="/pricing">
                    Get Started
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <nav className="navbar navbar-expand-lg dashboard-navbar">
        <div className="container-fluid flex-nowrap">
          <Link to="/dashboard">
            <AiSalesLogoWhite className="logo" />
          </Link>

          <div className=" navbar-collapse d-lg-flex justify-content-lg-around" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 pt-18 dashboardnav d-lg-flex d-none">
              <li className={`nav-item ${window.location.pathname.includes("dashboard") && "activeTab"} mx-2`}>
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-dashboard"
                  to="/dashboard"
                >
                  <img src={DashboardIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu" to="/dashboard">
                    Dashboard
                  </span>
                </Link>
              </li>
              <li className={`nav-item ${window.location.pathname.includes("ai-assistant") && "activeTab"} mx-2`}>
                <button
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-salescoach"
                  // to="/ai-assistant/1"
                  onClick={handleLinkClick}
                >
                  <img src={AssistanIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu">Your AI Coach</span>
                </button>
              </li>
              <li className={`nav-item ${window.location.pathname.includes("history") && "activeTab"} mx-2`}>
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-history"
                  to="/history"
                >
                  <img src={HistoryIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu">History</span>
                </Link>
              </li>
              <li className={`nav-item ${window.location.pathname.includes("resources") && "activeTab"} mx-2`}>
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-resources"
                  to="/resources"
                >
                  <img src={ResourcesIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu">Resources</span>
                </Link>
              </li>
              <li className={`nav-item ${window.location.pathname.includes("teams") && "activeTab"} mx-2`}>
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-teams"
                  to="/teams"
                >
                  <img src={TeamIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu">Teams</span>
                </Link>
              </li>
              <li className={`nav-item ${window.location.pathname.includes("forum") && "activeTab"} mx-2`}>
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-forum"
                  to="/forum"
                >
                  <img src={ForumIcon} alt="dashboard-icon" />
                  <span className="dashboard-nav-menu">Forum</span>
                </Link>
              </li>
            </ul>
            <div className="headerProfilesec d-flex align-items-center gap-3 mx-3 justify-content-end">
              <Link to="/dashboard">
                <img src={Search} alt="search" />
              </Link>
              <Link to="/dashboard">
                <img src={Notification} alt="search" />
              </Link>
              <div>
                <img src={Line} alt="line" />
              </div>
              <Link to="/dashboard">
                <img src={Settings} alt="search" />
              </Link>
              <div ref={popupRef} style={{ cursor: "pointer" }}>
                {/* Wrap the avatar link with an OverlayTrigger to display the popover */}
                <OverlayTrigger
                  trigger="click"
                  placement="bottom-end"
                  show={showPopover}
                  onToggle={setShowPopover}
                  overlay={
                    <Popover id="avatar-popover" className="dashboard-avatar-popover">
                      <Popover.Body>
                        {/* Add the options in the popover */}
                        <ul className="list-unstyled mb-0">
                          <li>
                            <Link to="/profile/edit-profile" className="d-flex align-items-center gap-1 pop-over-link ">
                              <CgProfile />
                              My Profile
                            </Link>
                          </li>
                          <hr />
                          <li>
                            <Link
                              to="/sign-in"
                              onClick={handleSignOut}
                              className="d-flex align-items-center gap-1  pop-over-link"
                            >
                              <VscSignOut />
                              Sign out
                            </Link>
                          </li>
                        </ul>
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <img src={imageUrl} alt="search" className="avtar dashbord-nav-profile" />
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default DashboardHeader;
