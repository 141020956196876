import React, { useEffect, useState, useRef } from "react";

import UserAiChatService from "../../../services/UserAiChatService";
import aiChat from "../../../assets/ai-assistant/aiChat.png";
import Download from "../../../assets/dashboard/Download.png";
import Player from "./Audioplayer/Player";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/authSlice";
import LoadingPlayer from "./Audioplayer/LoadingPlayer";

const AIChat = ({ text, audio, displayEllipses, chatId, ID, currentChatId, refresh }) => {
  const [formattedContent, setFormattedContent] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [loadedAudio, setLoadedAudio] = useState();
  const popupRef = useRef(null);
  const user = useSelector(selectAuth);

  const fetchAudio = async (queryId) => {
    try {
      const response = await UserAiChatService.getConversations(ID, user?.token, 1);
      const chatData = response?.data?.results;
      const specificChat = chatData.find((chat) => chat.id === queryId);

      if (specificChat?.ai_audio_response !== null) {
        // If the answer is available, update the chat history and stop further calls
        setAudioLoading(false);
        setLoadedAudio(specificChat?.ai_audio_response);
        refresh(ID);
      } else {
        // If the answer is not available, schedule the next API call after 10 seconds
        return fetchAudio(queryId);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (text !== null && text !== undefined && text !== "Failed") {
      setFormattedContent(text.split("\n\n"));
    }
    if (currentChatId === chatId && !audio) {
      setAudioLoading(true);
      fetchAudio(chatId);
    }
  }, [text, audio]);

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className={`step-3-chat ${!displayEllipses && "my-2"} p-3 d-flex flex-column`}>
      {/* view more popup start */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup" ref={popupRef}>
            <div className="popup-content p-3 d-flex flex-column">
              <img src={aiChat} className="chatbot-ellipse mb-2" alt="Chatbot" width={34} />
              <div className="chattext">
                {formattedContent?.map((para, i) => {
                  return <p key={i}>{para}</p>;
                })}
              </div>
              <span>
                <button
                  className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                  onClick={handleClosePopup}
                >
                  Close
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
      {/* view more popup end */}
      {!displayEllipses && text !== "Failed" && text !== "" && (
        <img src={aiChat} className="chatbot-ellipse mb-2" alt="Chatbot" width={34} />
      )}
      {displayEllipses ? (
        <div className="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : text === "Failed" || !text ? (
        <span className="chattext">Failed</span>
      ) : (
        <span>
          <>
            <div className="chattext">
              {formattedContent?.map((para, i) => {
                return <p key={i}>{para}</p>;
              })}
            </div>
            {/* <audio src={audio} className='my-3' controls></audio> */}
            {chatId === currentChatId ? (
              audioLoading ? (
                <LoadingPlayer />
              ) : (
                loadedAudio !== "Failed" && (
                  <div className="mt-2 d-flex">
                    <Player URL={loadedAudio} downloadAudio={true} />
                  </div>
                )
              )
            ) : (
              audio !== "Failed" && (
                <div className="mt-2 d-flex">
                  <Player URL={audio} downloadAudio={true} />
                </div>
              )
            )}
          </>
          {/* )} */}
        </span>
      )}
    </div>
  );
};

export default AIChat;
