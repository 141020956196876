import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BsPlayFill } from "react-icons/bs";
import { MdOutlineStopCircle, MdSend, MdCancel } from "react-icons/md";
import { FaPause } from "react-icons/fa";

import recordQueryIcon from "../../../assets/ai-assistant/recordQueryIcon.png";

const AudioRecorder = ({
  changePromptType,
  handleAudio,
  audio,
  sendAudioChat,
  makeFile,
  stopTimer,
  pauseTimer,
  restartTimer,
  changeActiveStatus,
  paused,
  changePauseStatus,
  isActive,
  displayStatus,
  counter
}) => {
  const [permission, setPermission] = useState(false);
  const [stream, setStream] = useState(null);
  const mediaRecorder = useRef(null);
  const [recordingAudio, setRecordingAudio] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [restartAudioRecording, setRestartAudioRecording] = useState(false);

  const mimeType = "audio/wav";

  useEffect(() => {
    if (counter === 30) {
      toast.error("A maximum audio duration of 30 seconds is allowed.");
      stopRecording();
    }
  }, [counter]);

  const getMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false
        });
        setPermission(true);
        setStream(streamData);
        startRecording(streamData);
      } catch (err) {
        toast.error(err.message);
        changePromptType("text");
      }
    } else {
      toast.error("The MediaRecorder API is not supported in your browser.");
      changePromptType("text");
    }
  };

  const startRecording = async (streamData) => {
    if (paused && !restartAudioRecording) {
      mediaRecorder.current.resume();
      changePauseStatus(false);
      return;
    } else if (restartAudioRecording) {
      changePauseStatus(false);
      setRestartAudioRecording(false);
    }

    const media = new MediaRecorder(streamData, { type: mimeType });
    mediaRecorder.current = media;
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") return;
      if (event.data.size === 0) return;
      localAudioChunks.push(event.data);
      const audioBlob = new Blob(localAudioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      setRecordingAudio(audioUrl);
    };
    setAudioChunks(localAudioChunks);
    changeActiveStatus(true);
  };

  const stopRecording = () => {
    changeActiveStatus(false);
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      handleAudio(audioUrl);
      var d = new Date();
      var file = new File([new Blob(audioChunks)], d.valueOf(), { type: "audio/wav" });
      makeFile(file);
      setAudioChunks([]);
      stopTimer();
      changePauseStatus(false);
      if (counter === 0) {
        toast.error('No audio found');
        handleCancelClick();
      } else {
        sendAudioChat(file);
      }
    };
  };

  const pauseRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.pause();
      changePauseStatus(true);
    }
  };

  const restartRecording = () => {
    if (!paused) {
      mediaRecorder.current.stop();
    }
    restartTimer();
    changePauseStatus(true);
    setRestartAudioRecording(true);
  };

  const handleCancelClick = () => {
    changePromptType("text");
    setPermission(false);
    handleAudio(null);
    changePauseStatus(false);
    changeActiveStatus(false);
  };

  const handleSend = () => {
    sendAudioChat();
  };

  return (
    <div className="audio-controls d-flex align-items-center flex-wrap">
      {!permission && !displayStatus && (
        <div
          onClick={() => {
            getMicrophonePermission();
            changePromptType("audio");
          }}
          type="button"
        >
          <img src={recordQueryIcon} alt="Record" data-tooltip-id="voice" />
        </div>
      )}
      {permission && !isActive && audio && (
        <>
          <span className="step-3-icons" data-tooltip-id="send-recording" onClick={handleSend} type="button">
            <MdSend />
          </span>
          <span
            className="step-3-icons ms-2"
            data-tooltip-id="cancel-recording-2"
            data-tooltip-content="Cancel"
            onClick={handleCancelClick}
            style={{ fontSize: "20px" }}
            type="button"
          >
            <MdCancel />
          </span>
        </>
      )}
      {permission && (
        <div className="d-flex align-items-center flex-wrap gap-2">
          {isActive && (
            <>
              {paused ? (
                <span
                  className="step-3-icons"
                  onClick={() => startRecording(stream)}
                  type="button"
                  style={{ fontSize: "22px" }}
                  data-tooltip-id="play-pause-recording"
                  data-tooltip-content={`${paused ? "Resume" : "Pause"}`}
                >
                  <BsPlayFill />
                </span>
              ) : (
                <span
                  className="step-3-icons"
                  onClick={pauseRecording}
                  type="button"
                  data-tooltip-id="play-pause-recording"
                  data-tooltip-content={`${paused ? "Resume" : "Pause"}`}
                >
                  <FaPause />
                </span>
              )}
              <span
                type="button"
                data-tooltip-id="restart-recording"
                onClick={() => restartRecording()}
                className="step-3-icons"
              >
                <i className="fa-solid fa-rotate-right fa-flip-horizontal" style={{ color: "#ffffff" }}></i>
              </span>
              {!restartAudioRecording ? (
                <span
                  className="step-3-icons"
                  onClick={stopRecording}
                  type="button"
                  style={{ fontSize: "22px" }}
                  data-tooltip-id="cancel-recording"
                  data-tooltip-content={restartAudioRecording ? "Cancel" : "Stop"}
                >
                  <MdOutlineStopCircle />
                </span>
              ) : (
                <span
                  className="step-3-icons"
                  data-tooltip-id="cancel-recording"
                  data-tooltip-content="Cancel"
                  onClick={handleCancelClick}
                  style={{ fontSize: "20px" }}
                  type="button"
                >
                  <MdCancel />
                </span>
              )}
            </>
          )}
        </div>
      )}
      <ReactTooltip id="play-pause-recording" place="bottom" />
      <ReactTooltip id="stop-recording" place="bottom" content="Stop" />
      <ReactTooltip id="cancel-recording" place="bottom" />
      <ReactTooltip id="send-recording" place="bottom" content="Send" />
      <ReactTooltip id="restart-recording" place="bottom" content="Restart" />
      <ReactTooltip id="cancel-recording-2" place="bottom" content="Restart" />
      <ReactTooltip id="voice" place="bottom" content="Voice" />
    </div>
  );
};
export default AudioRecorder;
