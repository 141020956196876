import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import ProtectedRoute from "./ProtectedRoute";
import Home from "../pages/home";
import Pricing from "../pages/pricing";
import SignIn from "../pages/auth/components/SignIn";
import Signup from "../pages/auth/components/SignUp";
import ForgotPasswordPage from "../pages/auth/components/ForgotPasswordPage";
import ConfirmPassword from "../pages/auth/components/ConfirmPassword";
import Otp from "../pages/auth/components/Otp";
import OtpSuccess from "../pages/auth/components/OtpSuccess";
import Dashboard from "../pages/dashboard";
import AIAssistant from "../pages/aiAssistant";
import History from "../pages/History/History";
import ProfileRoutes from "./ProfileRoutes";
import SubscriptionStatus from "../pages/subscription/SubscriptionStatus";
import RefundPolicy from "../pages/refundPolicy";
import TermsOfService from "../pages/TermsOfService";
import Features from "../pages/features";
import UseCases from "../pages/useCases";
import Docs from "../pages/docs";
import ContactUs from "../pages/contactUs";
import AboutUs from "../pages/aboutUs";
import TransactionSuccess from "../pages/subscription/TransactionSuccess";
import Qualify from "../layouts/OuterLayout/Forms/Qualify";
import CheatSheet from "../layouts/OuterLayout/Forms/CheatSheet";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const AppRoutes = () => {
  const { PUBLIC_URL } = process.env;

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        {/* Outer routes start*/}
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />
        {/* <Route path="/sign-up" element={<Signup />} /> */}
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
        <Route path="/activate-account" element={<Otp />} />
        <Route path="/enter-otp" element={<Otp />} />
        <Route path="/success" element={<OtpSuccess />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/features" element={<Features />} />
        <Route path="/usecases" element={<UseCases />} />
        <Route path="/docs" element={<Docs />} />
        <Route path="/about-us" element={<AboutUs />} />

        <Route path="/qualify" element={<Qualify />} />
        <Route path="/cheat-sheet" element={<CheatSheet />} />

        {/* Outer routes end */}

        {/* Inner routes start */}
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/ai-assistant/:id" element={<ProtectedRoute element={<AIAssistant />} />} />
        <Route path="/history" element={<ProtectedRoute element={<History />} />} />
        <Route path="/resources" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/teams" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/forum" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/profile/*" element={<ProtectedRoute element={<ProfileRoutes />} />} />
        <Route path="/check-subscription" element={<ProtectedRoute element={<SubscriptionStatus />} />} />
        <Route path="/transaction-success" element={<ProtectedRoute element={<TransactionSuccess />} />} />

        {/* Inner routes end */}
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
