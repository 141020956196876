import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import "./style.css";
import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import { ReactComponent as TitleDesign } from "../../assets/pricing/TitleDesign.svg";
import AboutUsContent from "./components/AboutUsContent";
import * as aboutUsPageCMSService from "../../services/CMSService";

const AboutUs = () => {
  const [coreValuePrincipleData, setCoreValuePrincipleData] = useState([]);
  const [companyHistoryData, setCompanyHistoryData] = useState([]);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const aboutUsPageCMSServiceCall = async () => {
      try {
        const [aboutUs] = await Promise.all([aboutUsPageCMSService.aboutUs()]);
        setCoreValuePrincipleData(aboutUs?.data?.data?.about_us);
        setCompanyHistoryData(aboutUs?.data?.data?.history);
        setTeamData(aboutUs?.data?.data?.team);
      } catch (error) {}
    };
    aboutUsPageCMSServiceCall();
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us - Leading AI Sales Coach Provider</title>
        <meta
          name="description"
          content="Get to know us: AI sales coach specialists. Explore our dedication to optimizing
sales performance with cutting-edge AI."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <section className="pricing-title-background text-white">
          <div className="pricing-card-padding  d-flex align-items-center justify-content-center">
            <div>
              <h1 className=" prising-title d-flex  align-items-center justify-content-center">About Us</h1>
              <br />
              <span className="d-flex  align-items-center justify-content-center">Home/About Us</span>
            </div>
            <TitleDesign className="pricing-title-image px-20px" />
          </div>
        </section>
        <AboutUsContent
          coreValuePrincipleData={coreValuePrincipleData}
          companyHistoryData={companyHistoryData}
          teamData={teamData}
        />
      </InnerLayout>
    </>
  );
};

export default AboutUs;
