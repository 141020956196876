import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-modal";

import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";
import zoomIcon from "../../../assets/ai-assistant/zoom_icon.png";
import "../style.css";
import { selectAuth, updateZoomAuthorizationStatus } from "../../../redux/authSlice";
import ZoomService from "../../../services/ZoomService";
import { toast } from "react-toastify";

const Integration = () => {
  const [showModal, setShowModal] = useState(false);

  const { user } = useSelector(selectAuth);
  const dispatch = useDispatch();

  const zoom_integration = user?.zoom_integration;
  const { token } = useSelector(selectAuth);

  const handleDeAuthorized = () => {
    ZoomService.zoomDeAuthorize({ zoom_integration: true }, token)
      .then((response) => {
        const updatedZoomStatus = { zoom_integration: false };
        dispatch(updateZoomAuthorizationStatus(updatedZoomStatus));

        setShowModal(false);
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <ProfileLayout>
      <section className="subscription profileform mt-5">
        <div className="container">
          <div className="col-lg-9 ">
            <div className="formheading subHeading mb-3">Integration</div>
            {zoom_integration ? (
              <label id="zoom-deauthorize" className="mt-3 mt-lg-0 mt-md-0 col-lg-4 col-md-6 col-12">
                <div className="d-flex align-items-center justify-content-center flex-column">
                  <img src={zoomIcon} alt="Upload Icon" className="zoom-icon m-2 text-center" />
                  <p className="zoom-deauthorize-title text-center">Zoom Conversation</p>
                  <Link className="zoom-deauthorize-link" onClick={() => setShowModal(true)}>
                    <p className="zoom-deauthorize-title text-center">Click for Deauthorize</p>
                  </Link>
                </div>
              </label>
            ) : (
              <p>You have not integrate any app</p>
            )}
          </div>
        </div>
      </section>
      <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Confirm Deauthorization"
        className="cancel-model"
      >
        <h2 className="pt-24">Confirm to Deauthorization</h2>
        <p className="pt-18">Are you sure you want to deauthorized?</p>
        <div className="d-flex gap-2  flex-lg-row">
          <button onClick={handleDeAuthorized} className="orange-gradient-auth-button pt-18">
            Yes, Sure
          </button>
          <button onClick={() => setShowModal(false)} className="orange-gradient-auth-button pt-18">
            No
          </button>
        </div>
      </Modal>
    </ProfileLayout>
  );
};

export default Integration;
