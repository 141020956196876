import React from "react";

const ContactUsTitle = () => {
  return (
    <section className="pricing-title-background text-white">
      <div className="pricing-card-padding  d-flex align-items-center justify-content-center">
        <div>
          <h1 className=" prising-title d-flex  align-items-center justify-content-center">Contact Us</h1>
          <br />
          <span className="d-flex  align-items-center justify-content-center">Home/Contact Us</span>
        </div>

        {/* <TitleDesign className="pricing-title-image px-20px" /> */}
      </div>
    </section>
  );
};

export default ContactUsTitle;
