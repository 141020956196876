import React from "react";

import SalesDevelopmentCard from "./SalesDevelopmentCard";

const SalesDevelopment = ({ data }) => {
  return (
    <section className="sales-development text-white">
      <div
        className="container-fluid sales-development-container aos-init"
        data-aos="fade-up"
        data-aos-duration="1500"
        data-aos-delay="150"
      >
        <h1 className="fs-60 text-center">
          AI-Powered Sales
          <br />
          Coach
        </h1>
        <div className="row py-5">
          {data?.map((card) => (
            <SalesDevelopmentCard
              key={card.id}
              title={card.steps}
              subTitle={card.step_title}
              description={card.title_description}
              link=""
              image={card.card_image}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default SalesDevelopment;
