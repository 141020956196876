import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

import TestiCard from "../../../assets/home/testicardBg.svg";

const Testimonials = ({ testimonials, faqs }) => {
  const navigate = useNavigate();

  const testimonialSettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    loop: true,
    margin: 10,
    autoplaySpeed: 3000,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const handleContactUs = () => {
    navigate("/contact-us/");
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This provides a smooth scrolling animation
    });
  };

  return (
    <section className="newTestimonial bg-orange-gradient p-lg-5 px-2">
      <div
        className="container-fluid text-white text-center aos-init"
        data-aos="fade"
        data-aos-duration="1500"
        data-aos-delay="150"
      >
        <h1 className="fs-60 p-4 text-capitalize">What Do Users of AI Sales Coach Say?</h1>
      </div>

      <Slider {...testimonialSettings}>
        {testimonials.map((testimonialsData, index) => (
          <div key={index} className="col-lg-12 col-md-12 col-sm-12">
            <div className="testimonial-item-two">
              <div className="testimonial-shape-two">
                <img src={TestiCard} alt="TestiCard" />
              </div>
              <div className="testimonial-avatar">
                <div className="testi-avatar-img">
                  <img src={testimonialsData.user_image} alt="" className="img-fluid testimonials-img" />
                </div>
                <div className="testi-avatar-info">
                  <h5 className="title">{testimonialsData?.name}</h5>
                  <span>{testimonialsData?.title}</span>
                </div>
              </div>
              <div className="rating">
                {[...Array(testimonialsData.rating)].map((_, index) => (
                  <i key={index} className="fa-solid fa-star mx-2"></i>
                ))}
                {[...Array(5 - testimonialsData.rating)].map((_, index) => (
                  <i key={index} className="fa-regular fa-star mx-2"></i>
                ))}
              </div>
              <div className="testimonial-content-two">
                <p>{testimonialsData?.discription}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {/* new curausel end */}

      {/* FAQ section start */}
      <div className="row faq-card px-lg-5 px-2 my-5">
        <div className="col-lg-5">
          <h4 className="fs-60 orange-gradient-text text-capitalize" style={{ fontSize: "46px" }}>
            Frequently asked questions
          </h4>
          <button className="btn1 btn primary-orange-gradient-button fs-20 my-4 py-2 px-4" onClick={handleContactUs}>
            Contact Us
          </button>
        </div>
        <div className="col-lg-7">
          <div className="accordion" id="accordionExample">
            {faqs?.map((faq, index) => (
              <div key={faq.id} className={`accordion-item ${index !== 0 && "mt-4"} faq-item`}>
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${index !== 0 && "collapsed"} testimonial-question`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${faq.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${faq.id}`}
                  >
                    {faq.Question}
                  </button>
                </h2>
                <div
                  id={`collapse${faq.id}`}
                  className={`accordion-collapse collapse ${index === 0 && "show"}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body pt-20 text-black">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* FAQ section end */}
    </section>
  );
};

export default Testimonials;
