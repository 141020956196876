import US from "../../../assets/country_flag/united-states.png";
import AUS from "../../../assets/country_flag/australia.png";
import AMERICAN_SAMOA from "../../../assets/country_flag/american-samoa.png";
import ANTIGUA_BARBUDA from "../../../assets/country_flag/antigua-and-barbuda.png";
import MACEDONIA from "../../../assets/country_flag/republic-of-macedonia.png";
import MAYOTTE from "../../../assets/country_flag/mayotte.png";
import GER from "../../../assets/country_flag/germany.png";
import IND from "../../../assets/country_flag/india.png";
import BELGI from "../../../assets/country_flag/belgium.png";
import BRAZIL from "../../../assets/country_flag/brazil-.png";
import COLOMBIA from "../../../assets/country_flag/colombia.png";
import EGYPT from "../../../assets/country_flag/egypt.png";
import FRANCE from "../../../assets/country_flag/france.png";
import HONGKONG from "../../../assets/country_flag/hong-kong.png";
import INDONESIA from "../../../assets/country_flag/indonesia.png";
import IRAN from "../../../assets/country_flag/iran.png";
import IRAQ from "../../../assets/country_flag/iraq.png";
import ITALY from "../../../assets/country_flag/italy.png";
import JAPAN from "../../../assets/country_flag/japan.png";
import KENYA from "../../../assets/country_flag/kenya.png";
import KUWAIT from "../../../assets/country_flag/kuwait.png";
import MEXICO from "../../../assets/country_flag/Mexico.png";
import ZIMBABVE from "../../../assets/country_flag/zimbabwe.png";
import UK from "../../../assets/country_flag/united-kingdom.png";
import TAIWAN from "../../../assets/country_flag/taiwan.png";
import THAILAND from "../../../assets/country_flag/thailand.png";
import TURKEY from "../../../assets/country_flag/turkey.png";
import SAUDIARABIA from "../../../assets/country_flag/saudi-arabia.png";
import SERBIA from "../../../assets/country_flag/serbia.png";
import SINGAPORE from "../../../assets/country_flag/singapore.png";
import SA from "../../../assets/country_flag/south-africa.png";
import SPAIN from "../../../assets/country_flag/spain.png";
import SWITZERLAND from "../../../assets/country_flag/switzerland.png";
import PHILIPPINES from "../../../assets/country_flag/philippines.png";
import POLAND from "../../../assets/country_flag/poland.png";
import NETHERLAND from "../../../assets/country_flag/netherlands.png";
import NEWZELAND from "../../../assets/country_flag/new-zealand.png";
import NORWAY from "../../../assets/country_flag/norway.png";
import ISRAEL from "../../../assets/country_flag/israel.png";
import AFGHANISTAN from "../../../assets/country_flag/afghanistan.png";
import ALBANIA from "../../../assets/country_flag/albania.png";
import ALGERIA from "../../../assets/country_flag/algeria.png";
import ANDORRA from "../../../assets/country_flag/andorra.png";
import ANGOLA from "../../../assets/country_flag/angola.png";
import ANGUILLA from "../../../assets/country_flag/anguilla.png";
import ANTARCTICA from "../../../assets/country_flag/antarctica.png";
import ARGENTINA from "../../../assets/country_flag/argentina.png";
import ARMENIA from "../../../assets/country_flag/armenia.png";
import ARUBA from "../../../assets/country_flag/aruba.png";
import AZERBAIJAN from "../../../assets/country_flag/azerbaijan.png";
import BAHAMAS from "../../../assets/country_flag/bahamas.png";
import BAHRAIN from "../../../assets/country_flag/bahrain.png";
import BANGLADESH from "../../../assets/country_flag/bangladesh.png";
import BARBADOS from "../../../assets/country_flag/barbados.png";
import BELIZE from "../../../assets/country_flag/belize.png";
import BENIN from "../../../assets/country_flag/benin.png";
import BERMUDA from "../../../assets/country_flag/bermuda.png";
import BHUTAN from "../../../assets/country_flag/bhutan.png";
import BOLIVIA from "../../../assets/country_flag/bolivia.png";
import BOSNIA_HERZEGOVINA from "../../../assets/country_flag/bosnia-and-herzegovina.png";
import BOTSWANA from "../../../assets/country_flag/botswana.png";
import BRITISH_INDIAN_OCEAN_TERRITORY from "../../../assets/country_flag/british-indian-ocean-territory.png";
import BRITISH_VIRGIN_ISLANDS from "../../../assets/country_flag/british-virgin-islands.png";
import BRUNEI from "../../../assets/country_flag/brunei.png";
import BULGARIA from "../../../assets/country_flag/bulgaria.png";
import BURKINA_FASO from "../../../assets/country_flag/burkina-faso.png";
import BURUNDI from "../../../assets/country_flag/burundi.png";
import CAMBODIA from "../../../assets/country_flag/cambodia.png";
import CAMEROON from "../../../assets/country_flag/cameroon.png";
import CANADA from "../../../assets/country_flag/canada.png";
import CAPE_VERDE from "../../../assets/country_flag/cape-verde.png";
import CAYMAN_ISLANDS from "../../../assets/country_flag/cayman-islands.png";
import CENTRAL_AFRICAN_REPUBLIC from "../../../assets/country_flag/central-african-republic.png";
import CHAD from "../../../assets/country_flag/chad.png";
import CHILE from "../../../assets/country_flag/chile.png";
import CHINA from "../../../assets/country_flag/china.png";
import CHRISTMAS_ISLAND from "../../../assets/country_flag/christmas-island.png";
import COCOS_ISLANDS from "../../../assets/country_flag/cocos-island.png";
import COMOROS from "../../../assets/country_flag/comoros.png";
import COOK_ISLANDS from "../../../assets/country_flag/cook-islands.png";
import COSTA_RICA from "../../../assets/country_flag/costa-rica.png";
import CROATIA from "../../../assets/country_flag/croatia.png";
import CUBA from "../../../assets/country_flag/cuba.png";
import CURACAO from "../../../assets/country_flag/curacao.png";
import CYPRUS from "../../../assets/country_flag/cyprus.png";
import CZECH_REPUBLIC from "../../../assets/country_flag/czech-republic.png";
import CONGO from "../../../assets/country_flag/democratic-republic-of-congo.png";
import DENMARK from "../../../assets/country_flag/denmark.png";
import DJIBOUTI from "../../../assets/country_flag/djibouti.png";
import DOMINICA from "../../../assets/country_flag/dominica.png";
import EAST_TIMOR from "../../../assets/country_flag/east-timor.png";
import ECUADOR from "../../../assets/country_flag/ecuador.png";
import EL_SALVADOR from "../../../assets/country_flag/el-salvador.png";
import EQUATORIAL_GUINEA from "../../../assets/country_flag/equatorial-guinea.png";
import ERITREA from "../../../assets/country_flag/ERITREA.png";
import ESTONIA from "../../../assets/country_flag/estonia.png";
import ETHIOPIA from "../../../assets/country_flag/ethiopia.png";
import FALKLAND_ISLANDS from "../../../assets/country_flag/falkland-islands.png";
import FAROE_ISLANDS from "../../../assets/country_flag/faroe-islands.png";
import FIJI from "../../../assets/country_flag/fiji.png";
import FINLAND from "../../../assets/country_flag/finland.png";
import FRENCH_POLYNESIA from "../../../assets/country_flag/french-polynesia.png";
import GABON from "../../../assets/country_flag/gabon.png";
import GAMBIA from "../../../assets/country_flag/gambia.png";
import GEORGIA from "../../../assets/country_flag/georgia.png";
import GHANA from "../../../assets/country_flag/ghana.png";
import GIBRALTAR from "../../../assets/country_flag/gibraltar.png";
import GREECE from "../../../assets/country_flag/greece.png";
import GREENLAND from "../../../assets/country_flag/greenland.png";
import GRENADA from "../../../assets/country_flag/grenada.png";
import GUAM from "../../../assets/country_flag/guam.png";
import GUATEMALA from "../../../assets/country_flag/guatemala.png";
import GUINEA from "../../../assets/country_flag/papua-new-guinea.png";
import GUINEA_BISSAU from "../../../assets/country_flag/guinea-bissau.png";
import GUYANA from "../../../assets/country_flag/guyana.png";
import HAITI from "../../../assets/country_flag/haiti.png";
import HONDURAS from "../../../assets/country_flag/honduras.png";
import HUNGARY from "../../../assets/country_flag/hungary.png";
import ICELAND from "../../../assets/country_flag/iceland.png";
import IRELAND from "../../../assets/country_flag/ireland.png";
import IVORY_COAST from "../../../assets/country_flag/ivory-coast.png";
import JAMAICA from "../../../assets/country_flag/jamaica.png";
import JERSEY from "../../../assets/country_flag/jersey.png";
import JORDAN from "../../../assets/country_flag/jordan.png";
import KAZAKHSTAN from "../../../assets/country_flag/kazakhstan.png";
import KIRIBATI from "../../../assets/country_flag/kiribati.png";
import KOSOVO from "../../../assets/country_flag/kosovo.png";
import KYRGYZSTAN from "../../../assets/country_flag/kyrgyzstan.png";
import LAOS from "../../../assets/country_flag/laos.png";
import LATVIA from "../../../assets/country_flag/latvia.png";
import LEBANON from "../../../assets/country_flag/lebanon.png";
import LESOTHO from "../../../assets/country_flag/lesotho.png";
import LIECHTENSTEIN from "../../../assets/country_flag/liechtenstein.png";
import LIBYA from "../../../assets/country_flag/libya.png";
import LITHUANIA from "../../../assets/country_flag/lithuania.png";
import LUXEMBOURG from "../../../assets/country_flag/luxembourg.png";
import MACAU from "../../../assets/country_flag/macau.png";
// import MACEDONIA from "../../../assets/country_flag/mace";
import MADAGASCAR from "../../../assets/country_flag/madagascar.png";
import MALAWI from "../../../assets/country_flag/malawi.png";
import MALAYSIA from "../../../assets/country_flag/malaysia.png";
import MALDIVES from "../../../assets/country_flag/maldives.png";
import MALI from "../../../assets/country_flag/mali.png";
import MALTA from "../../../assets/country_flag/malta.png";
import MARSHALL_ISLANDS from "../../../assets/country_flag/marshall-island.png";
import MAURITANIA from "../../../assets/country_flag/mauritania.png";
import MAURITIUS from "../../../assets/country_flag/mauritius.png";
// import MAYOTTE from "../../../assets/country_flag/may";
import MICRONESIA from "../../../assets/country_flag/micronesia.png";
import MOLDOVA from "../../../assets/country_flag/moldova.png";
import MONACO from "../../../assets/country_flag/monaco.png";
import MONGOLIA from "../../../assets/country_flag/mongolia.png";
import MONTENEGRO from "../../../assets/country_flag/montenegro.png";
import MONTSERRAT from "../../../assets/country_flag/MONTSERRAT.png";
import MOROCCO from "../../../assets/country_flag/morocco.png";
import MOZAMBIQUE from "../../../assets/country_flag/mozambique.png";
import MYANMAR from "../../../assets/country_flag/myanmar.png";
import NAMIBIA from "../../../assets/country_flag/namibia.png";
import NAURU from "../../../assets/country_flag/nauru.png";
import NEPAL from "../../../assets/country_flag/nepal.png";
import NETHERLANDS_ANTILLES from "../../../assets/country_flag/NETHERLANDS ANTILLES.png";
import NEW_CALEDONIA from "../../../assets/country_flag/NEW_CALEDONIA.png";
import NICARAGUA from "../../../assets/country_flag/nicaragua.png";
import NIGER from "../../../assets/country_flag/niger.png";
import NIGERIA from "../../../assets/country_flag/nigeria.png";
import NIUE from "../../../assets/country_flag/niue.png";
import NORTH_KOREA from "../../../assets/country_flag/north-korea.png";
import NORTHERN_MARIANA from "../../../assets/country_flag/northern-marianas-islands.png";
import OMAN from "../../../assets/country_flag/oman.png";
import PAKISTAN from "../../../assets/country_flag/pakistan.png";
import PALAU from "../../../assets/country_flag/palau.png";
import PALESTINE from "../../../assets/country_flag/palestine.png";
import PANAMA from "../../../assets/country_flag/panama.png";
import PAPUA_NEW_GUINEA from "../../../assets/country_flag/papua-new-guinea.png";
import PARAGUAY from "../../../assets/country_flag/paraguay.png";
import PERU from "../../../assets/country_flag/peru.png";
import PITCAIRN from "../../../assets/country_flag/pitcairn-islands.png";
import PORTUGAL from "../../../assets/country_flag/portugal.png";
import QATAR from "../../../assets/country_flag/qatar.png";
import REPUBLIC_CONGO from "../../../assets/country_flag/democratic-republic-of-congo.png";
import REUNION from "../../../assets/country_flag/reunion.png";
import ROMANIA from "../../../assets/country_flag/romania.png";
import RUSSIA from "../../../assets/country_flag/russia.png";
import RWANDA from "../../../assets/country_flag/rwanda.png";
// import SAINT_BARTHELEMY from "../../../assets/country_flag/sa";
import SAINT_HELENA from "../../../assets/country_flag/saint-helena.png";
import SAINT_KITTS_AND_NEVIS from "../../../assets/country_flag/saint-kitts-and-nevis.png";
import SAINT_LUCIA from "../../../assets/country_flag/saint-lucia.png";
// import SAINT_MARTIN from "../../../assets/country_flag/saint";
// import SAINT_PIERRE_AND_MIQUELON from "../../../assets/country_flag/saint";
import SAINT_VINCENT_GRENADINES from "../../../assets/country_flag/saint-vincent-and-the-grenadines.png";
import SAMOA from "../../../assets/country_flag/samoa.png";
// import SAN_ARINO from "../../../assets/country_flag/san";
import SAO_TOME_PRINCIPE from "../../../assets/country_flag/sao-tome-and-prince.png";
import SENEGAL from "../../../assets/country_flag/senegal.png";
import SEYCHELLES from "../../../assets/country_flag/seychelles.png";
import SIERRA_LEONE from "../../../assets/country_flag/sierra-leone.png";
import SINT_MAARTEN from "../../../assets/country_flag/sint-maarten.png";
import SLOVAKIA from "../../../assets/country_flag/slovakia.png";
import SLOVENIA from "../../../assets/country_flag/slovenia.png";
import SOLOMON_ISLANDS from "../../../assets/country_flag/solomon-islands.png";
import SOMALIA from "../../../assets/country_flag/somalia.png";
import SOUTH_KOREA from "../../../assets/country_flag/south-korea.png";
import SOUTH_SUDAN from "../../../assets/country_flag/south-sudan.png";
import SRI_LANKA from "../../../assets/country_flag/sri-lanka.png";
import SUDAN from "../../../assets/country_flag/sudan.png";
import SURINAME from "../../../assets/country_flag/suriname.png";
import SVALBARD from "../../../assets/country_flag/svalbard-and-jan-mayen.png";
import SWAZILAND from "../../../assets/country_flag/swaziland.png";
import SWEDEN from "../../../assets/country_flag/sweden.png";
import SYRIA from "../../../assets/country_flag/syria.png";
import TAJIKISTAN from "../../../assets/country_flag/tajikistan.png";
import TANZANIA from "../../../assets/country_flag/tanzania.png";
import TOGO from "../../../assets/country_flag/togo.png";
import TRINIDAD from "../../../assets/country_flag/trinidad-and-tobago.png";
import TUNISIA from "../../../assets/country_flag/tunisia.png";
import TURKMENISTAN from "../../../assets/country_flag/turkmenistan-.png";
import TUVALU from "../../../assets/country_flag/tuvalu.png";
import UGANDA from "../../../assets/country_flag/uganda.png";
import UKRAINE from "../../../assets/country_flag/ukraine.png";
import UNITED_ARAB_EMIRATES from "../../../assets/country_flag/united-arab-emirates.png";
import URUGUAY from "../../../assets/country_flag/uruguay.png";
import UZBEKISTAN from "../../../assets/country_flag/uzbekistan.png";
import VANUATU from "../../../assets/country_flag/vanuatu.png";
import VATICAN from "../../../assets/country_flag/vatican-city.png";
import VENEZUELA from "../../../assets/country_flag/venezuela.png";
import VIETNAM from "../../../assets/country_flag/vietnam.png";
import WALLIS_AND_FUTUNA from "../../../assets/country_flag/WALLIS_AND_FUTUNA.png";
import WESTERN_SAHARA from "../../../assets/country_flag/sahrawi-arab-democratic-republic.png";
import YEMEN from "../../../assets/country_flag/yemen.png";

const CountryCode = [
  { value: "+1", label: "+1", flagUrl: US },
  { value: "+61", label: "+61", flagUrl: AUS },
  { value: "+49", label: "+49", flagUrl: GER },
  { value: "+91", label: "+91", flagUrl: IND },
  { value: "+32", label: "+32", flagUrl: BELGI },
  { value: "+55", label: "+55", flagUrl: BRAZIL },
  { value: "+57", label: "+57", flagUrl: COLOMBIA },
  { value: "+20", label: "+20", flagUrl: EGYPT },
  { value: "+33", label: "+33", flagUrl: FRANCE },
  { value: "+852", label: "+852", flagUrl: HONGKONG },
  { value: "+62", label: "+62", flagUrl: INDONESIA },
  { value: "+98", label: "+98", flagUrl: IRAN },
  { value: "+964", label: "+964", flagUrl: IRAQ },
  { value: "+39", label: "+39", flagUrl: ITALY },
  { value: "+81", label: "+81", flagUrl: JAPAN },
  { value: "+254", label: "+254", flagUrl: KENYA },
  { value: "+965", label: "+965", flagUrl: KUWAIT },
  { value: "+52", label: "+52", flagUrl: MEXICO },
  { value: "+263", label: "+263", flagUrl: ZIMBABVE },
  { value: "+44", label: "+44", flagUrl: UK },
  { value: "+886", label: "+886", flagUrl: TAIWAN },
  { value: "+66", label: "+66", flagUrl: THAILAND },
  { value: "+90", label: "+90", flagUrl: TURKEY },
  { value: "+966", label: "+966", flagUrl: SAUDIARABIA },
  { value: "+381", label: "+381", flagUrl: SERBIA },
  { value: "+65", label: "+65", flagUrl: SINGAPORE },
  { value: "+27", label: "+27", flagUrl: SA },
  { value: "+34", label: "+34", flagUrl: SPAIN },
  { value: "+41", label: "+41", flagUrl: SWITZERLAND },
  { value: "+63", label: "+63", flagUrl: PHILIPPINES },
  { value: "+48", label: "+48", flagUrl: POLAND },
  { value: "+31", label: "+31", flagUrl: NETHERLAND },
  { value: "+64", label: "+64", flagUrl: NEWZELAND },
  { value: "+47", label: "+47", flagUrl: NORWAY },
  { value: "+972", label: "+972", flagUrl: ISRAEL },
  { value: "+93", label: "+93", flagUrl: AFGHANISTAN },
  { value: "+355", label: "+355", flagUrl: ALBANIA },
  { value: "+213", label: "+213", flagUrl: ALGERIA },
  { value: "+1-684", label: "+1-684", flagUrl: AMERICAN_SAMOA },
  { value: "+376", label: "+376", flagUrl: ANDORRA },
  { value: "+244", label: "+244", flagUrl: ANGOLA },
  { value: "+1-264", label: "+1-264", flagUrl: ANGUILLA },
  { value: "+672", label: "+672", flagUrl: ANTARCTICA },
  { value: "+1-268", label: "+1-268", flagUrl: ANTIGUA_BARBUDA },
  { value: "+54", label: "+54", flagUrl: ARGENTINA },
  { value: "+374", label: "+374", flagUrl: ARMENIA },
  { value: "+297", label: "+297", flagUrl: ARUBA },
  { value: "+994", label: "+994", flagUrl: AZERBAIJAN },
  { value: "+1-242", label: "+1-242", flagUrl: BAHAMAS },
  { value: "+973", label: "+973", flagUrl: BAHRAIN },
  { value: "+880", label: "+880", flagUrl: BANGLADESH },
  { value: "+1-246", label: "+1-246", flagUrl: BARBADOS },
  { value: "+375", label: "+375", flagUrl: BELIZE },
  { value: "+501", label: "+501", flagUrl: ALGERIA },
  { value: "+229", label: "+229", flagUrl: BENIN },
  { value: "+1-441", label: "+1-441", flagUrl: BERMUDA },
  { value: "+975", label: "+975", flagUrl: BHUTAN },
  { value: "+591", label: "+591", flagUrl: BOLIVIA },
  { value: "+387", label: "+387", flagUrl: BOSNIA_HERZEGOVINA },
  { value: "+267", label: "+267", flagUrl: BOTSWANA },
  { value: "+246", label: "+246", flagUrl: BRITISH_INDIAN_OCEAN_TERRITORY },
  { value: "+1-284", label: "+1-284", flagUrl: BRITISH_VIRGIN_ISLANDS },
  { value: "+673", label: "+673", flagUrl: BRUNEI },
  { value: "+359", label: "+359", flagUrl: BULGARIA },
  { value: "+226", label: "+226", flagUrl: BURKINA_FASO },
  { value: "+257", label: "+257", flagUrl: BURUNDI },
  { value: "+855", label: "+855", flagUrl: CAMBODIA },
  { value: "+237", label: "+237", flagUrl: CAMEROON },
  { value: "+1", label: "+1", flagUrl: CANADA },
  { value: "+238", label: "+238", flagUrl: CAPE_VERDE },
  { value: "+1-345", label: "+1-345", flagUrl: CAYMAN_ISLANDS },
  { value: "+236", label: "+236", flagUrl: CENTRAL_AFRICAN_REPUBLIC },
  { value: "+235", label: "+235", flagUrl: CHAD },
  { value: "+56", label: "+56", flagUrl: CHILE },
  { value: "+86", label: "+86", flagUrl: CHINA },
  { value: "+61", label: "+61", flagUrl: CHRISTMAS_ISLAND },
  { value: "+61", label: "+61", flagUrl: COCOS_ISLANDS },
  { value: "+269", label: "+269", flagUrl: COMOROS },
  { value: "+682", label: "+682", flagUrl: COOK_ISLANDS },
  { value: "+506", label: "+506", flagUrl: COSTA_RICA },
  { value: "+385", label: "+385", flagUrl: CROATIA },
  { value: "+53", label: "+53", flagUrl: CUBA },
  { value: "+599", label: "+599", flagUrl: CURACAO },
  { value: "+357", label: "+357", flagUrl: CYPRUS },
  { value: "+420", label: "+420", flagUrl: CZECH_REPUBLIC },
  { value: "+243", label: "+243", flagUrl: CONGO },
  { value: "+45", label: "+45", flagUrl: DENMARK },
  { value: "+253", label: "+253", flagUrl: DJIBOUTI },
  { value: "+1-767", label: "+1-767", flagUrl: DOMINICA },
  { value: "+670", label: "+670", flagUrl: EAST_TIMOR },
  { value: "+593", label: "+593", flagUrl: ECUADOR },
  { value: "+503", label: "+503", flagUrl: EL_SALVADOR },
  { value: "+240", label: "+240", flagUrl: EQUATORIAL_GUINEA },
  { value: "+291", label: "+291", flagUrl: ERITREA },
  { value: "+372", label: "+372", flagUrl: ESTONIA },
  { value: "+251", label: "+251", flagUrl: ETHIOPIA },
  { value: "+500", label: "+500", flagUrl: FALKLAND_ISLANDS },
  { value: "+298", label: "+298", flagUrl: FAROE_ISLANDS },
  { value: "+679", label: "+679", flagUrl: FIJI },
  { value: "+358", label: "+358", flagUrl: FINLAND },
  { value: "+689", label: "+689", flagUrl: FRENCH_POLYNESIA },
  { value: "+241", label: "+241", flagUrl: GABON },
  { value: "+220", label: "+220", flagUrl: GAMBIA },
  { value: "+995", label: "+995", flagUrl: GEORGIA },
  { value: "+233", label: "+233", flagUrl: GHANA },
  { value: "+350", label: "+350", flagUrl: GIBRALTAR },
  { value: "+30", label: "+30", flagUrl: GREECE },
  { value: "+299", label: "+299", flagUrl: GREENLAND },
  { value: "+1-473", label: "+1-473", flagUrl: GRENADA },
  { value: "+1-671", label: "+1-671", flagUrl: GUAM },
  { value: "+502", label: "+502", flagUrl: GUATEMALA },
  { value: "+224", label: "+224", flagUrl: GUINEA },
  { value: "+245", label: "+245", flagUrl: GUINEA_BISSAU },
  { value: "+592", label: "+592", flagUrl: GUYANA },
  { value: "+509", label: "+509", flagUrl: HAITI },
  { value: "+504", label: "+504", flagUrl: HONDURAS },
  { value: "+36", label: "+36", flagUrl: HUNGARY },
  { value: "+354", label: "+354", flagUrl: ICELAND },
  { value: "+353", label: "+353", flagUrl: IRELAND },
  { value: "+225", label: "+225", flagUrl: IVORY_COAST },
  { value: "+1-876", label: "+1-876", flagUrl: JAMAICA },
  { value: "+44-1534", label: "+44-1534", flagUrl: JERSEY },
  { value: "+962", label: "+962", flagUrl: JORDAN },
  { value: "+7", label: "+7", flagUrl: KAZAKHSTAN },
  { value: "+686", label: "+686", flagUrl: KIRIBATI },
  { value: "+383", label: "+383", flagUrl: KOSOVO },
  { value: "+996", label: "+996", flagUrl: KYRGYZSTAN },
  { value: "+856", label: "+856", flagUrl: LAOS },
  { value: "+371", label: "+371", flagUrl: LATVIA },
  { value: "+961", label: "+961", flagUrl: LEBANON },
  { value: "+266", label: "+266", flagUrl: LESOTHO },
  { value: "+423", label: "+423", flagUrl: LIECHTENSTEIN },
  { value: "+218", label: "+218", flagUrl: LIBYA },
  { value: "+370", label: "+370", flagUrl: LITHUANIA },
  { value: "+352", label: "+352", flagUrl: LUXEMBOURG },
  { value: "+853", label: "+853", flagUrl: MACAU },
  { value: "+389", label: "+389", flagUrl: MACEDONIA },
  { value: "+261", label: "+261", flagUrl: MADAGASCAR },
  { value: "+265", label: "+265", flagUrl: MALAWI },
  { value: "+60", label: "+60", flagUrl: MALAYSIA },
  { value: "+960", label: "+960", flagUrl: MALDIVES },
  { value: "+223", label: "+223", flagUrl: MALI },
  { value: "+356", label: "+356", flagUrl: MALTA },
  { value: "+692", label: "+692", flagUrl: MARSHALL_ISLANDS },
  { value: "+222", label: "+222", flagUrl: MAURITANIA },
  { value: "+230", label: "+230", flagUrl: MAURITIUS },
  { value: "+262", label: "+262", flagUrl: MAYOTTE },
  { value: "+691", label: "+691", flagUrl: MICRONESIA },
  { value: "+373", label: "+373", flagUrl: MOLDOVA },
  { value: "+377", label: "+377", flagUrl: MONACO },
  { value: "+976", label: "+976", flagUrl: MONGOLIA },
  { value: "+382", label: "+382", flagUrl: MONTENEGRO },
  { value: "+1-664", label: "+1-664", flagUrl: MONTSERRAT },
  { value: "+212", label: "+212", flagUrl: MOROCCO },
  { value: "+258", label: "+258", flagUrl: MOZAMBIQUE },
  { value: "+95", label: "+95", flagUrl: MYANMAR },
  { value: "+264", label: "+264", flagUrl: NAMIBIA },
  { value: "+674", label: "+674", flagUrl: NAURU },
  { value: "+977", label: "+977", flagUrl: NEPAL },
  { value: "+599", label: "+599", flagUrl: NETHERLANDS_ANTILLES },
  { value: "+687", label: "+687", flagUrl: NEW_CALEDONIA },
  { value: "+505", label: "+505", flagUrl: NICARAGUA },
  { value: "+227", label: "+227", flagUrl: NIGER },
  { value: "+234", label: "+234", flagUrl: NIGERIA },
  { value: "+683", label: "+683", flagUrl: NIUE },
  { value: "+850", label: "+850", flagUrl: NORTH_KOREA },
  { value: "+1-670", label: "+1-670", flagUrl: NORTHERN_MARIANA },
  { value: "+968", label: "+968", flagUrl: OMAN },
  { value: "+92", label: "+92", flagUrl: PAKISTAN },
  { value: "+680", label: "+680", flagUrl: PALAU },
  { value: "+970", label: "+970", flagUrl: PALESTINE },
  { value: "+507", label: "+507", flagUrl: PANAMA },
  { value: "+675", label: "+675", flagUrl: PAPUA_NEW_GUINEA },
  { value: "+595", label: "+595", flagUrl: PARAGUAY },
  { value: "+51", label: "+51", flagUrl: PERU },
  { value: "+64", label: "+64", flagUrl: PITCAIRN },
  { value: "+351", label: "+351", flagUrl: PORTUGAL },
  { value: "+974", label: "+974", flagUrl: QATAR },
  { value: "+242", label: "+242", flagUrl: REPUBLIC_CONGO },
  { value: "+262", label: "+262", flagUrl: REUNION },
  { value: "+40", label: "+40", flagUrl: ROMANIA },
  { value: "+7", label: "+7", flagUrl: RUSSIA },
  { value: "+250", label: "+250", flagUrl: RWANDA },
  // { value: "+590", label: "+590", flagUrl: SAINT_BARTHELEMY },
  { value: "+290", label: "+290", flagUrl: SAINT_HELENA },
  { value: "+1-869", label: "+1-869", flagUrl: SAINT_KITTS_AND_NEVIS },
  { value: "+1-758", label: "+1-758", flagUrl: SAINT_LUCIA },
  // { value: "+590", label: "+590", flagUrl: SAINT_MARTIN },
  // { value: "+508", label: "+508", flagUrl: SAINT_PIERRE_AND_MIQUELON },
  { value: "+1-784", label: "+1-784", flagUrl: SAINT_VINCENT_GRENADINES },
  { value: "+685", label: "+685", flagUrl: SAMOA },
  // { value: "+378", label: "+378", flagUrl: SAN_ARINO },
  { value: "+239", label: "+239", flagUrl: SAO_TOME_PRINCIPE },
  { value: "+221", label: "+221", flagUrl: SENEGAL },
  { value: "+248", label: "+248", flagUrl: SEYCHELLES },
  { value: "+232", label: "+232", flagUrl: SIERRA_LEONE },
  { value: "+1-721", label: "+1-721", flagUrl: SINT_MAARTEN },
  { value: "+421", label: "+421", flagUrl: SLOVAKIA },
  { value: "+386", label: "+386", flagUrl: SLOVENIA },
  { value: "+677", label: "+677", flagUrl: SOLOMON_ISLANDS },
  { value: "+252", label: "+252", flagUrl: SOMALIA },
  { value: "+82", label: "+82", flagUrl: SOUTH_KOREA },
  { value: "+211", label: "+211", flagUrl: SOUTH_SUDAN },
  { value: "+94", label: "+94", flagUrl: SRI_LANKA },
  { value: "+249", label: "+249", flagUrl: SUDAN },
  { value: "+597", label: "+597", flagUrl: SURINAME },
  { value: "+47", label: "+47", flagUrl: SVALBARD },
  { value: "+268", label: "+268", flagUrl: SWAZILAND },
  { value: "+46", label: "+46", flagUrl: SWEDEN },
  { value: "+963", label: "+963", flagUrl: SYRIA },
  { value: "+992", label: "+992", flagUrl: TAJIKISTAN },
  { value: "+255", label: "+255", flagUrl: TANZANIA },
  { value: "+228", label: "+228", flagUrl: TOGO },
  { value: "+1-868", label: "+1-868", flagUrl: TRINIDAD },
  { value: "+216", label: "+216", flagUrl: TUNISIA },
  { value: "+993", label: "+993", flagUrl: TURKMENISTAN },
  { value: "+688", label: "+688", flagUrl: TUVALU },
  { value: "+256", label: "+256", flagUrl: UGANDA },
  { value: "+380", label: "+380", flagUrl: UKRAINE },
  { value: "+971", label: "+971", flagUrl: UNITED_ARAB_EMIRATES },
  { value: "+598", label: "+598", flagUrl: URUGUAY },
  { value: "+998", label: "+998", flagUrl: UZBEKISTAN },
  { value: "+678", label: "+678", flagUrl: VANUATU },
  { value: "+379", label: "+379", flagUrl: VATICAN },
  { value: "+58", label: "+58", flagUrl: VENEZUELA },
  { value: "+84", label: "+84", flagUrl: VIETNAM },
  { value: "+681", label: "+681", flagUrl: WALLIS_AND_FUTUNA },
  { value: "+212", label: "+212", flagUrl: WESTERN_SAHARA },
  { value: "+967", label: "+967", flagUrl: YEMEN }
];

export default CountryCode;
