import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AboutTimelineCarousel = ({ companyHistoryData }) => {
  const testimonialSettings = {
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    autoplay: false,
    loop: true,
    margin: 10,
    autoplaySpeed: 3000,
    dots: false,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...testimonialSettings}>
      {companyHistoryData.map((item) => (
        <div className="timeline-carousel__item" key={item.id}>
          <div className="timeline-carousel__image">
            <div
              className="media-wrapper media-wrapper--overlay"
              style={{ background: `url(${item.image}) center center`, backgroundSize: "cover" }}
            ></div>
          </div>
          <div className="timeline-carousel__item-inner">
            <span className="year">{item.year}:</span>
            <p>
              <span>{item.title}:</span> {item.content}
            </p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default AboutTimelineCarousel;
