import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import PlacesAutocomplete from "react-places-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { FaMapMarkerAlt } from "react-icons/fa";

import "../style.css";
import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";
import ProfileService from "../../../services/ProfileService";
import { selectAuth, signInUser, signOutUser, updatedIsWalkThroughTakenStatus } from "../../../redux/authSlice";
import countryCode from "../data/countryCode";
import Camera from "../../../assets/profile/camera.svg";
import Avtar3 from "../../../assets/dashboard-navbar/Avtar3.png";
import Joyride from "react-joyride";

const EditProfile = () => {
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState("");
  const [isWalkThroughTourProfile, setIsWalkThroughTourProfile] = useState();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const CustomOption = ({ innerProps, label, data }) => (
    <div {...innerProps} className="d-flex align-items-center justify-content-start gap-1">
      <img src={data.flagUrl} alt={label} className="country-flag-icon" />
      <span>{label}</span>
    </div>
  );

  useEffect(() => {
    setIsWalkThroughTourProfile(location?.state);
  }, [location.state]);

  useEffect(() => {
    if (isWalkThroughTourProfile === "walkthrough") {
      setIsJoyrideRunning(true);
    }
  });

  const [userData, setUserData] = useState({
    full_name: "",
    last_name: "",
    email: "",
    address: "",
    country_code: "",
    mobile_number: "",
    profile_pic: null
  });

  const validationSchema = Yup.object().shape({});

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const data = useSelector(selectAuth);

  const getProfileDetails = () => {
    ProfileService.getProfile({
      headers: {
        Authorization: `token ${data.token}`
      }
    })
      .then((response) => {
        const userDataFromAPI = response.data.data;
        dispatch(signInUser({ token: token1, user: userDataFromAPI }));

        setSelectedAddress(userDataFromAPI.address);

        setUserData({
          full_name: userDataFromAPI.full_name,
          last_name: userDataFromAPI.last_name,
          email: userDataFromAPI.email,
          mobile_number: userDataFromAPI.mobile_number,
          address: userDataFromAPI.address,
          country_code: userDataFromAPI.country_code,
          profile_pic: userDataFromAPI.profile_pic || null
        });
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        toast.error("Failed to fetch user data.");
      });
  };

  useEffect(() => {
    getProfileDetails();
  }, [data.token]);

  useEffect(() => {
    setValue("full_name", userData.full_name);
    setValue("last_name", userData.last_name);
    setValue("email", userData.email);
    setValue("image", userData.profile_pic);
    setValue("mobile_number", userData.mobile_number);
    setValue("address", userData.address);
    if (userData.country_code) {
      const selectedCountryCode = countryCode.find((code) => code.value === userData.country_code);
      setSelectedCountryCode(selectedCountryCode);
      setValue("country_code", selectedCountryCode?.value);
    }
  }, [userData]);

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#80bdff" : "#ced4da", // Customize border color when focused
      borderRadius: "10px",
      height: "48px",
      width: "100%"
    }),
    indicatorsContainer: (base) => ({
      ...base
    })
  };

  const token1 = data.token;
  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      address: selectedAddress
    };
    ProfileService.editProfile(formattedData, {
      headers: {
        Authorization: `token ${token1}`
      }
    })
      .then((response) => {
        const userProfile = {
          ...data?.user,
          profile_pic: userData?.profile_pic,
          full_name: data?.full_name,
          last_name: data?.last_name
        };
        getProfileDetails();
        toast.success("Profile Updated Successfully");
      })
      .catch((error) => {
        toast.error("response");
      });
  };

  const handleChange1 = (address) => {
    setSelectedAddress(address);
  };

  const handleSelect = (address) => {
    setSelectedAddress(address);
  };

  const handleProfileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_pic", file);

    ProfileService.editProfileImage(formData, token1)
      .then((response) => {
        if (response?.statusText === "OK") {
          ProfileService.getProfile({
            headers: {
              Authorization: `token ${data.token}`
            }
          })
            .then((response) => {
              const userDataFromAPI = response.data.data;
              setSelectedAddress(userDataFromAPI.address);
              const userProfile = {
                ...data?.user,
                profile_pic: response.data.data.profile_pic,
                full_name: response?.data?.data?.full_name,
                last_name: response?.data?.data?.last_name
              };
              dispatch(signInUser({ token: data.token, user: userProfile }));
              setUserData({
                full_name: userDataFromAPI.full_name,
                last_name: userDataFromAPI.last_name,
                email: userDataFromAPI.email,
                mobile_number: userDataFromAPI.mobile_number,
                address: userDataFromAPI.address,
                country_code: userDataFromAPI.country_code,
                profile_pic: userDataFromAPI.profile_pic || null
              });
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                navigate("/sign-in");
                toast.error("Please login again.");
                dispatch(signOutUser());
                return;
              }
              toast.error("Failed to fetch user data.");
            });
        }
        toast.success("Profile Image Updated Successfully");
      })
      .catch((error) => {
        toast.error("Failed to update profile image.");
      });
  };
  // Tour step start
  const tourSteps = [
    {
      target: ".list-unstyled",
      content: "Manage your account and settings using the options in this sidebar.",
      disableBeacon: "true"
    },
    {
      target: ".edit-profile-form",
      content: "Update your personal information from this form.",
      disableBeacon: "true"
    }
  ];

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    if (data.action === "skip") {
      const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
      dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
      setIsJoyrideRunning(false);
      const updatedState = "skip";
      navigate("/profile/edit-profile", { state: updatedState });
    }
    if (status === "finished" && type === "step:after") {
      // Check if the last step is reached
      const isLastStep = tourSteps.findIndex((step) => step.target === data.step.target) === tourSteps.length - 1;
      if (isLastStep) {
        const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
        dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
        // Navigate to the desired route

        navigate("/dashboard", { state: "walkthrough" });
      }
    }
  };
  // Tour step end

  return (
    <>
      {isWalkThroughTourProfile === "walkthrough" && (
        <Joyride
          steps={tourSteps}
          showSkipButton={true}
          run={isJoyrideRunning}
          continuous={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#ed7f5a",
              zIndex: 1000
            },
            tooltip: {
              fontFamily: "lato"
            },
            buttonClose: {
              fontFamily: "lato"
            }
          }}
        />
      )}

      <ProfileLayout>
        <div className="profileAvtarSec">
          <div className="profile-avtar d-flex justify-content-center justify-content-md-start">
            <div className="profile-img">
              <img
                className="img-fluid"
                src={userData.profile_pic ? userData.profile_pic : Avtar3}
                alt="profileImage"
              />
              <label className="camera-input">
                <img src={Camera} alt="edit-profile" className="edit-profile-icon" />
                <input
                  type="file"
                  className="edit-profoile-input"
                  name="image"
                  accept="image/*"
                  onChange={handleProfileChange}
                />
              </label>
            </div>
            <div className="card-body ms-4">
              <div className="d-flex gap-2">
                <h1 className="pt-700">{userData.full_name}</h1>
                <h1 className="pt-700">{userData.last_name}</h1>
              </div>

              <h6 className="pt-20">You can Edit the Profile</h6>
            </div>
          </div>
        </div>
        <div className="profileform">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row justify-content-center edit-profile-form">
              <div className="row ">
                <h2 className="mb-5 formheading subHeading">Edit Profile</h2>
                <h5 className="smallHeading mb-4">Personal Details</h5>
                <div className="col-md-6">
                  <div className="row">
                    <div className="formbox col-md-12 col-lg-12">
                      <label className="form-label pt-16">Full Name</label>
                      <input
                        autoComplete="off"
                        type="text"
                        id="full_name"
                        placeholder="Full Name"
                        {...register("full_name")}
                        className={`form-control form-field pt-16 ${errors.full_name ? "is-invalid" : ""}`}
                      />
                      {errors.full_name && <div className="invalid-feedback pt-16">{errors.full_name.message}</div>}
                    </div>

                    <div className="formbox col-md-12 col-lg-12">
                      <label className="form-label pt-16">Email</label>
                      <input
                        disabled
                        autoComplete="off"
                        type="text"
                        className={`form-control form-field pt-16 ${errors.email ? "is-invalid" : ""}`}
                        id="email"
                        placeholder="Email"
                        {...register("email")}
                        inputMode="email"
                      />
                      {errors.email && <div className="invalid-feedback pt-16">{errors.email.message}</div>}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row selectColor">
                    <label className="form-label labelColor pt-16">Phone Number</label>
                    <div className="code-dropdown col-4">
                      <Select
                        options={countryCode.map((option) => ({
                          ...option,
                          label: (
                            <CustomOption
                              innerProps={{}}
                              label={option.label}
                              data={{ flagUrl: option.flagUrl }} // Pass flag URL to custom option
                            />
                          )
                        }))}
                        {...register("country_code")}
                        onChange={(selectedOption) => {
                          setSelectedCountryCode(selectedOption);
                          setValue("country_code", selectedOption?.value);
                        }}
                        value={selectedCountryCode}
                        classNamePrefix="react-select"
                        className={`  pt-16 countrySelect  ${errors.countryCode ? "is-invalid" : ""}`}
                        placeholder="Code"
                        styles={customSelectStyles}
                      />
                      {errors.countryCode && <div className="invalid-feedback pt-16">{errors.countryCode.message}</div>}
                    </div>

                    <div className="formbox col-8">
                      <input
                        autoComplete="off"
                        type="text"
                        id="last_name"
                        placeholder="Phone Number"
                        maxLength="10"
                        {...register("mobile_number")}
                        className={`form-control form-field pt-16 ${errors.mobile_number ? "is-invalid" : ""}`}
                        inputMode="numeric"
                      />
                      {errors.mobile_number && (
                        <div className="invalid-feedback pt-16">{errors.mobile_number.message}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="formbox col-md-12 profileTextarea">
                  <label className="form-label pt-16">Address </label>

                  <PlacesAutocomplete value={selectedAddress} onChange={handleChange1} onSelect={handleSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Search Places ...",
                            className: "form-control form-field pt-16 col-md-12"
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div className="pt-16">Loading...</div>}
                          {suggestions &&
                            suggestions.map((suggestion) => {
                              const className = suggestion.active ? "suggestion-item--active" : "suggestion-item";
                              const style = suggestion.active
                                ? {
                                    backgroundColor: "#fafafa",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #CED4DA",
                                    padding: "10px"
                                  }
                                : {
                                    backgroundColor: "#ffffff",
                                    cursor: "pointer",
                                    borderBottom: "1px solid #CED4DA",
                                    padding: "10px"
                                  };

                              const iconStyle = suggestion.active
                                ? { color: "#007BFF" } // Change the color for active state
                                : {};

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    onClick: () => handleSelect(suggestion.description) // Set the selected address when clicked
                                  })}
                                >
                                  <FaMapMarkerAlt className="location-icon" style={iconStyle} /> &nbsp;
                                  <span className="pt-16 address-suggetion">{suggestion.description}</span>
                                  <br />
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>

                  {errors.address && <div className="invalid-feedback pt-16">{errors.address.message}</div>}
                </div>
              </div>
              <div className="formbox profileformBox mt-4 responsive-padding">
                <button type="submit" className="orange-gradient-auth-button pt-20">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </ProfileLayout>
    </>
  );
};

export default EditProfile;
