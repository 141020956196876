import React, { useEffect, useState } from "react";

const StatusFilter = ({ selectedStatus, setSelectedStatus }) => {
  // const [selectedStatus, setSelectedStatus] = useState(""); // Initialize state with an empty string

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value); // Update the state with the selected status
  };

  return (
    <div className="fv-row">
      <label className="form-label fw-bold">Status</label>
      <select
        className="form-select mb-3 cursor-pointer "
        aria-label="Select example"
        name="selectedStatus"
        value={selectedStatus}
        onChange={handleStatusChange} // Call the event handler when the selection changes
      >
        <option value="">Select Status</option>
        <option value="in_process">Problem is Generating</option>
        <option value="problem_process">Problem Generated</option>
        <option value="solution_generated">Solution is Generating</option>
        <option value="completed">Solution Generated</option>
        <option value="failed">Failed</option>
      </select>
    </div>
  );
};

export default StatusFilter;
