import { data } from "jquery";
import apiClient, { setHeader } from "../utils/apiClient";

const zoomAuth = (token) => {
  return apiClient.get("/zoom-auth/", setHeader("", token));
};

const accessTokenGenerator = (data, token) => {
  return apiClient.post("/zoom-auth/", data, setHeader("", token));
};

const getZoomRecordings = (token) => {
  return apiClient.get("/zoom-meeting-recordings/", setHeader("", token));
};

const zoomAudioRequest = (data, token) => {
  return apiClient.post("/zoom-user-audio-request", data, setHeader("", token));
};

const zoomDeAuthorize = (data, token) => {
  return apiClient.post("/zoom-deauthorize", data, setHeader("", token));
};
const ZoomService = {
  zoomAuth,
  accessTokenGenerator,
  getZoomRecordings,
  zoomAudioRequest,
  zoomDeAuthorize
};

export default ZoomService;
