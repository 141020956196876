import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const OtpTimer = ({ minutes, seconds, onResend, textClass, timerClass }) => {
  const initialTimeLeft = minutes * 60 + seconds;
  const [timeLeft, setTimeLeft] = useState(Number(localStorage.getItem("otpTimer")) || initialTimeLeft);

  useEffect(() => {
    const timer =
      timeLeft > 0 &&
      setInterval(() => {
        const updatedTimeLeft = timeLeft - 1;
        setTimeLeft(updatedTimeLeft);
        localStorage.setItem("otpTimer", updatedTimeLeft.toString());
      }, 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const minutesDisplay = Math.floor(timeLeft / 60);
  const secondsDisplay = timeLeft % 60;

  const handleResend = (e) => {
    e.preventDefault();
    if (timeLeft === 0) {
      onResend(e);
      setTimeLeft(initialTimeLeft);
      localStorage.setItem("otpTimer", initialTimeLeft.toString());
    }
  };

  return (
    <span className={timerClass}>
      {timeLeft > 0 ? (
        <span>{`${minutesDisplay.toString().padStart(2, "0")}:${secondsDisplay.toString().padStart(2, "0")}`}</span>
      ) : (
        <Link className="link" onClick={handleResend}>
          <span className="otp-link">Resend it</span>
        </Link>
      )}
    </span>
  );
};

export default OtpTimer;
