import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import lottie from "lottie-web";
import loader1 from "../../../assets/ai-assistant/loader1.json";
import { IoMdPlay } from "react-icons/io";
import { GiPauseButton } from "react-icons/gi";

import AudioRequestService from "../../../services/AudioRequestService";
import step2AILady from "../../../assets/ai-assistant/step2AILady.png";
import audioFile from "../../../assets/ai-assistant/audioFile.png";
import { selectAuth, signOutUser, updatedIsWalkThroughTakenStatus } from "../../../redux/authSlice";
import femaleAccent from "../../../assets/ai-assistant/femaleAccent.png";
import maleAccent from "../../../assets/ai-assistant/maleAccent.png";
import Joyride from "react-joyride";

const AIAssistantStep2 = ({ onNext, onPrevious, data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioId, setAudioId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const audioRef = useRef();
  const [problemFindings, setProblemFindings] = useState({});
  const [editProblemFindings, setEditProblemFindings] = useState();
  const { token } = useSelector(selectAuth);
  const [editTextStatus, setEditTextStatus] = useState(true);
  const [disableGetSolution, setDisableGetSolution] = useState(false);
  const [voiceAccent, setVoiceAccent] = useState("");
  const [navigated, setNavigated] = useState(false);
  const [voiceAccentError, setVoiceAccentError] = useState("");
  const [editProblemFindingsError, setEditProblemFindingsError] = useState("");
  const [isWalkThroughTourStep2, setIsWalkThroughTourStep2] = useState();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleBlockedNavigation = (event) => {
      navigate("/dashboard");
    };

    if (data === null) {
      navigate("/dashboard");
    } else {
      if (data.id && data.problem_findings !== null) {
        setProblemFindings(data);
        setEditProblemFindings(data.problem_findings);
        return;
      }
      if (data.status === true) {
        setAudioId(data.data.audio_id);
        setIsLoading(true);
        fetchUserDataUntilProblemFindings(token, data.data.audio_id);
      }
    }
    window.addEventListener("popstate", handleBlockedNavigation);
    return () => {
      window.removeEventListener("popstate", handleBlockedNavigation);
    };
  }, [data]);

  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
      animationData: loader1
    });
  }, [isLoading]);
  const location = useLocation();

  useEffect(() => {
    setIsWalkThroughTourStep2(location?.state);
  }, [location.state]);

  useEffect(() => {
    if (isWalkThroughTourStep2 === "walkthrough") {
      setIsJoyrideRunning(true);
    }
  });

  const fetchUserDataUntilProblemFindings = async (token, audioId) => {
    try {
      const allRequests = await AudioRequestService.getUserData(token);

      const currentRequest = allRequests?.data.data.find((req) => req.id === audioId);

      if (currentRequest?.problem_findings === null && currentRequest.status !== "failed" && !navigated) {
        // If solution_findings is null, wait for some time and call the function again.
        fetchUserDataUntilProblemFindings(token, audioId);
      } else if (currentRequest?.problem_findings === null && currentRequest.status === "failed" && !navigated) {
        navigate("/dashboard");
        toast.error("Error generating problem. Try again.");
        setIsLoading(false);
      } else if (!navigated) {
        // When solution_findings is not null, update the state and stop loading.
        setIsLoading(false);
        // setProblemFindings(currentRequest);
        // setEditProblemFindings(currentRequest.problem_findings);
        navigate("/ai-assistant/2", {
          state: currentRequest
        });
        // Do whatever you need with the currentRequest here.
      }
    } catch (error) {
      navigate("/dashboard");
      toast.error("Error generating problem. Try again.");
      setIsLoading(false); // Stop loading even if there is an error.
    }
  };

  // handles text area update
  const handleTextareaChange = (event) => {
    const newValue = event.target.value;

    setEditProblemFindings(newValue);
    setEditProblemFindingsError("");
  };

  const handleAccentValidation = () => {
    if (!voiceAccent) {
      setVoiceAccentError("Select voice accent.");
      return false;
    }
    setVoiceAccentError("");
    return true;
  };

  const handleProblemFindingsValidation = () => {
    if (!editProblemFindings) {
      setEditProblemFindingsError("Problem output cannot be empty.");
      return false;
    }
    setEditProblemFindingsError("");
    return true;
  };

  const handleGetSolution = async () => {
    const isAccentValid = handleAccentValidation();
    const ispfValid = handleProblemFindingsValidation();

    setDisableGetSolution(true);
    const { id, conversation_title } = data;
    try {
      if (isAccentValid && ispfValid) {
        const dataToUpdate = {
          problem_findings: editProblemFindings,
          gender_output_audio: voiceAccent
        };
        const response = await AudioRequestService.problemFieldUpdate(id, dataToUpdate, token);
        setEditTextStatus(true);
        if (response) {
          navigate("/ai-assistant/3", {
            state: { id: id }
          });
          toast.success(`Your solution generation for ${conversation_title} is in progress. Please wait...`);
          setDisableGetSolution(false);
        }
      } else {
        setDisableGetSolution(false);
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
      toast.error(`Error generating solution for ${conversation_title}. Try again.`);
      setDisableGetSolution(false);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = audioRef.current.currentTime;
    const duration = audioRef.current.duration;
    setProgress((currentTime / duration) * 100);
  };

  const handleSeek = (e) => {
    const seekTime = (e.target.value * audioRef.current.duration) / 100;
    audioRef.current.currentTime = seekTime;
    setProgress(e.target.value);
  };

  const handleNavigation = () => {
    navigate("/dashboard");
    window.location.reload();
  };

  // Tour step start
  const tourSteps = [
    {
      target: ".step-2-audio-file",
      content: "Play the audio you've uploaded",
      disableBeacon: "true"
    },
    {
      target: ".step-2-text-area",
      content: "Modify the generated text output or add your own comments.",
      disableBeacon: "true"
    },
    {
      target: ".accentCardsStep2",
      content: "Choose your preferred accent for the generate solution.",
      disableBeacon: "true"
    },
    {
      target: ".get-solution-btn",
      content: "Click here to obtain solutions for the audio input.",
      disableBeacon: "true"
    },
    {
      target: ".walkthrough-dummy-class",
      content: "Click here to obtain solutions for the audio input.",
      disableBeacon: "true"
    }
  ];

  const handleJoyrideCallback = async (data) => {
    try {
      const { status, type } = data;
      if (data.action === "skip") {
        const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
        dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
        setIsJoyrideRunning(false);
        const updatedState = "skip";
        navigate("/ai-assistant/2", { state: updatedState });
      }
      if (status === "finished" && type === "step:after") {
        // Check if the last step is reached
        const isSecondLastStep = tourSteps.length - 1;
        if (isSecondLastStep) {
          // Navigate to the desired route
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/ai-assistant/3", { state: "walkthrough" });
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/dashboard");
        toast.error(error?.response?.data?.message?.message[0]);
      } else if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
      }
    }
  };
  // Tour step end

  const walkthroughBtnText = {
    last: "Next"
  };

  return (
    <section className="m-lg-5 m-3">
      {isWalkThroughTourStep2 === "walkthrough" && (
        <Joyride
          steps={tourSteps}
          showSkipButton={true}
          run={isJoyrideRunning}
          callback={handleJoyrideCallback}
          continuous={true}
          locale={walkthroughBtnText}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#ed7f5a",
              zIndex: 1000
            },
            tooltip: {
              fontFamily: "lato"
            },
            buttonClose: {
              fontFamily: "lato"
            }
          }}
        />
      )}

      {isLoading && (
        <div className="overlay d-flex flex-column">
          <div id="react-logo" style={{ width: 300, height: 200 }}></div>
          <p className="loading-text">Processing...</p>
        </div>
      )}
      <div className="container ai-assistant-stepper-2 mt-4 ai-assistant-font-family">
        <div className="row">
          <div className="step-2-container col-lg-8 col-12 p-lg-5 p-2">
            <h3 className="fs-30 roboto-700">AI Base Problems Generated</h3>
            <div className="step-2-audio-file px-5 py-3 d-flex justify-content-around gap-lg-3 align-items-center mt-4 mb-5">
              <img src={audioFile} alt="Audio File" />
              <div className="d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-between">
                  <p className="audio-title-step2">
                    {problemFindings?.conversation_title?.length > 25
                      ? `${problemFindings?.conversation_title?.substring(0, 25)}...`
                      : problemFindings?.conversation_title}
                  </p>
                  <p className="audio-title-step2">{`${
                    problemFindings?.audio_file_size
                      ? `${(problemFindings?.audio_file_size / (1024 * 1024)).toFixed(2)}MB`
                      : ""
                  }`}</p>
                </div>
                <audio
                  ref={audioRef}
                  src={problemFindings?.audio_file_url}
                  onTimeUpdate={handleTimeUpdate}
                  onEnded={() => setIsPlaying(false)}
                />
                <div className="audio-bar-container">
                  <input type="range" min="0" max="100" value={progress} onChange={handleSeek} className="audio-bar" />
                </div>
              </div>
              <button
                onClick={problemFindings?.audio_file_url && handlePlayPause}
                className="pause-play-bg"
                style={{ marginLeft: "10px" }}
              >
                {isPlaying ? (
                  <GiPauseButton className="text-white" style={{ width: "22px" }} />
                ) : (
                  <IoMdPlay className="text-white" style={{ width: "22px" }} />
                )}
              </button>
            </div>
            <div className="step-2-edit-text">
              <div className="d-lg-flex justify-content-between">
                <h4 className="roboto-500 fs-22 step-2-edit-text-heading">Problems Output Here You Can Edit in Text</h4>
                <button
                  className="btn ai-assistant-edit-text-btn"
                  onClick={() => {
                    setEditTextStatus(false);
                  }}
                >
                  <i className="fa-regular fa-pen-to-square" style={{ color: "#ef9543" }}></i>&nbsp;Edit Text
                </button>
              </div>
            </div>
            <textarea
              className="step-2-text-area p-3 roboto-400 mt-5"
              value={editProblemFindings}
              onChange={handleTextareaChange}
              disabled={editTextStatus}
            ></textarea>
            <p className="errors">
              {editProblemFindingsError && <span className="text-danger">{editProblemFindingsError}</span>}
            </p>
            <p>Select accent voice:</p>
            <div className="accentCardsStep2 mt-4 flex-lg-row flex-md-row flex-column">
              <div
                className={`${
                  voiceAccent === "female" ? "active-accent-voice" : "inactive-accent-voice"
                } accent-voices-option py-2 px-2 d-flex justify-content-around justify-content-center align-items-center`}
                type="button"
                onClick={() => {
                  setVoiceAccent("female");
                  setVoiceAccentError("");
                }}
              >
                <div className="d-flex align-items-center">
                  <img src={femaleAccent} alt="Female Accent" />
                  <span className="d-flex flex-column mx-3">
                    <span className="roboto-500">Bella(F)</span>
                    <span className="accent-voices-subtitle">Young Adult</span>
                  </span>
                </div>
                <span className="accent-voices-btn">
                  <IoMdPlay />
                </span>
              </div>
              <div
                className={`${
                  voiceAccent === "male" ? "active-accent-voice" : "inactive-accent-voice"
                } accent-voices-option py-2 px-2 d-flex justify-content-around align-items-center flex-wrap`}
                type="button"
                onClick={() => {
                  setVoiceAccent("male");
                  setVoiceAccentError("");
                }}
              >
                <div className="d-flex align-items-center">
                  <img src={maleAccent} alt="Male Accent" />
                  <span className="d-flex flex-column mx-3">
                    <span className="roboto-500">Terrell (M)</span>
                    <span className="accent-voices-subtitle">Middle-Aged</span>
                  </span>
                </div>
                <span className="accent-voices-btn">
                  <IoMdPlay />
                </span>
              </div>
            </div>
            <p className="errors">{voiceAccentError && <span className="text-danger">{voiceAccentError}</span>}</p>
            <button
              className="btn get-solution-btn primary-orange-gradient-ai-assistant-button mt-4"
              onClick={handleGetSolution}
              disabled={disableGetSolution}
            >
              GET SOLUTION
            </button>
          </div>
          <div className="col-lg-4 col-12 step-2-img-image responsive-steps-padding">
            <img src={step2AILady} alt="AI" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIAssistantStep2;
