import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import "../style.css";

const ProfileSidebar = () => {
  const location = useLocation();

  return (
    <div className="profilesidebar">
      <div className="sidebar-menu mt-3">
        <ul className="list-unstyled">
          <Link className="list-item text-decoration-none" to="/profile/edit-profile">
            <li
              className={`sidebar-items pt-20 m-1 py-1 px-3 ${
                location.pathname === "/profile/edit-profile" ? "active" : ""
              }`}
            >
              Edit Profile
            </li>
          </Link>
          <Link className="list-item text-decoration-none" to="/profile/notifications">
            <li
              className={`sidebar-items pt-20 m-1 py-1 px-3 ${
                location.pathname === "/profile/notifications" ? "active" : ""
              }`}
            >
              Notifications
            </li>
          </Link>
          <Link className="list-item text-decoration-none" to="/profile/transaction">
            <li
              className={`sidebar-items pt-20 m-1 py-1 px-3 ${
                location.pathname === "/profile/transaction" ? "active" : ""
              }`}
            >
              Transaction
            </li>
          </Link>
          <Link className="sidebar-items list-item text-decoration-none" to="/profile/change-password">
            <li className={` pt-20 m-1 py-1 px-3 ${location.pathname === "/profile/change-password" ? "active" : ""}`}>
              Password & security
            </li>
          </Link>
          <Link className="sidebar-items list-item text-decoration-none" to="/profile/mysubscription">
            <li className={` pt-20 m-1 py-1 px-3 ${location.pathname === "/profile/mysubscription" ? "active" : ""}`}>
              My Subscription
            </li>
          </Link>
          {/* <Link className="sidebar-items list-item text-decoration-none" to="/profile/integration">
            <li className={` pt-20 m-1 py-1 px-3 ${location.pathname === "/profile/integration" ? "active" : ""}`}>
              Integration
            </li>
          </Link> */}
        </ul>
      </div>
    </div>
  );
};

export default ProfileSidebar;
