import apiClient from "../utils/apiClient";

const hubspot = (data) => {
  return apiClient.post("/hubspot/", data);
};

const LandingPageService = {
  hubspot
};

export default LandingPageService;
