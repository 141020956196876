import { data } from "jquery";
import apiClient from "../utils/apiClient";
import { setHeader } from "../utils/apiClient";

const getProfile = (token) => {
  return apiClient.get("/user_profile/", token);
};

const editProfile = (data, token) => {
  return apiClient.put("/user_profile/", data, token);
};

const editProfileImage = (data, token) => {
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data; "
    }
  };
  return apiClient.patch("/user_profile/", data, setHeader(headers, token));
};

const changePassword = (data, header) => {
  return apiClient.put("/change_password/", data, header);
};

const getTransaction = (token) => {
  return apiClient.get("/transaction/", token);
};

const cancelSubscription = (token) => {
  return apiClient.post("/cancel_subscription/", null, token);
};
const ProfileService = {
  getProfile,
  editProfile,
  editProfileImage,
  changePassword,
  getTransaction,
  cancelSubscription
};

export default ProfileService;
