import React, { useState } from "react";

const TitleFilter = ({ title, setTitle }) => {
  // const [title, setTitle] = useState("");

  const handleTitleChange = (event) => {
    setTitle(event.target.value); // Update the state with the input value
  };

  return (
    <div className="fv-row">
      <label for="exampleInputTitle" className="form-label">
        Title
      </label>
      <input
        type="text"
        className="form-control"
        id="exampleInputTitle"
        name="title"
        value={title}
        onChange={handleTitleChange}
      />
    </div>
  );
};

export default TitleFilter;
