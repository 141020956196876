import React, { useEffect, useState } from "react";
import * as landingPageCMSService from "../../../services/CMSService";

const PricingFaq = () => {
  const [pricingFaqsData, setPricingFaqsData] = useState([]);

  useEffect(() => {
    const landingPageCMSServiceCall = async () => {
      try {
        const [pricingFaq] = await Promise.all([landingPageCMSService.pricingFaq()]);
        setPricingFaqsData(pricingFaq?.data?.data);
      } catch (error) {}
    };
    landingPageCMSServiceCall();
  }, []);

  return (
    <div className="container pricing-faq-container">
      <div className="row pricing-faq-card px-lg-5 px-2 my-5">
        <div className="col-lg-5">
          <h4 className="fs-60 orange-gradient-text text-capitalize" style={{ fontSize: "46px" }}>
            Frequently asked questions
          </h4>
          {/* <button className="btn1 btn primary-orange-gradient-button fs-20 my-4 py-2 px-4" onClick={handleContactUs}>
          Contact Us
        </button> */}
        </div>
        <div className="col-lg-7">
          <div className="accordion" id="accordionExample">
            {pricingFaqsData?.map((faq, index) => (
              <div key={faq.id} className={`accordion-item ${index !== 0 && "mt-4"} faq-item`}>
                <h2 className="accordion-header">
                  <button
                    className={`accordion-button ${index !== 0 && "collapsed"} testimonial-question`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${faq.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${faq.id}`}
                  >
                    {faq.Question}
                  </button>
                </h2>
                <div
                  id={`collapse${faq.id}`}
                  className={`accordion-collapse collapse ${index === 0 && "show"}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body pt-20 text-black">{faq.answer}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingFaq;
