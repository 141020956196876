import React from "react";
import { selectAuth } from "../../../redux/authSlice";
import { useSelector } from "react-redux";
import Player from "./Audioplayer/Player";

import Avatar from "../../../assets/ai-assistant/Avatar.png";
const UserChat = ({ text, audio }) => {
  const { profile_pic } = useSelector(selectAuth);

  const user = useSelector(selectAuth);

  return (
    <div className="d-flex justify-content-end">
      <div className="step-3-user-chat my-2 p-3 d-flex flex-column flex-lg-row justify-content-center align-items-end">
        <div className="d-flex flex-column">
          {text !== "" && <span className={`${!text?.includes(' ') && 'no-space-text'} chattext`}>{text}</span>}
          {audio && <Player URL={audio} downloadAudio={true} />}
        </div>
        <img
          src={user.user.profile_pic ? user.user.profile_pic : Avatar}
          alt="Profile Pic"
          className="ms-2 mt-lg-0 mt-2 rounded-circle"
          width={34}
          height={34}
        />
      </div>
    </div>
  );
};

export default UserChat;
