import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectAuth } from "../../../redux/authSlice";
import Arrow from ".././../../assets/pricing/Arrow.png";
import SalesPricingCard from "./SalesPricingCard";
import PricingFaq from "./PricingFaq";
import MoneyBack from "./MoneyBack";
import FeatureTick from "../../../assets/pricing/FeatureTick.png";
import "../style.css";

const SalesCoachPricing = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(true);
  const [activePackage, setActivePackage] = useState("Essential (Individual)");

  const user = useSelector(selectAuth);

  const freeCardRef = useRef(null);
  const navigate = useNavigate();

  const handleSwitchToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const handlePackageClick = (packageName) => {
    setActivePackage(packageName);
  };

  const handleContactUs = () => {
    navigate("/contact-us/");
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This provides a smooth scrolling animation
    });
  };

  const scrollToFreeCard = () => {
    if (freeCardRef.current) {
      freeCardRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollToFreeCard();
    }, 1000);

    return () => {
      clearTimeout(timer); // Clear the timeout if the component unmounts before the timeout is reached
    };
  }, []);

  const freeTrialCard = data.find((card) => card.name === "Free Trial");
  const filteredData = data.filter(
    (card) => card.name !== "Free Trial" && (isMonthly ? card.interval === "month" : card.interval === "year")
  );
  return (
    <>
      <section className="salescoach-pricing text-white" ref={freeCardRef}>
        <div className="container-fluid sales-pricing-container">
          <h1 className="fs-60 text-center">AI Sales Coach Pricing</h1>
          <p className="pt-20 text-center">Choose a plan that's right for you</p>

          <div className="plan-switch d-flex mt-5 align-items-center justify-content-center gap-4  mb-5">
            <h4>Pay Monthly</h4>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                checked={!isMonthly}
                onChange={handleSwitchToggle}
              />
            </div>

            <h4>Pay Yearly</h4>

            <div className="arrow-image d-flex ">
              <img src={Arrow} alt="arrow" className="arrow " />
              <p className="d-flex align-items-end">Save About 25%</p>
            </div>
          </div>
          {/* Display package titles */}
          <div className="d-lg-none  d-block">
            <div className="package-titles">
              <ul class=" package-tab-container d-flex gap-4 ">
                {freeTrialCard && isMonthly && !user?.user?.is_supporter && (
                  <li>
                    <Link
                      onClick={() => handlePackageClick("FreeTrial")}
                      className={`package-tab-title ${activePackage === "FreeTrial" ? "active" : ""}`}
                    >
                      Free
                    </Link>
                  </li>
                )}
                {filteredData.map((card) => (
                  <li>
                    <Link
                      key={card.id}
                      onClick={() => handlePackageClick(card.name)}
                      className={`package-tab-title ${activePackage === card.name ? "active" : ""}`}
                      id={card?.name}
                    >
                      {card.name.split(" (")[0]}
                    </Link>
                  </li>
                ))}
                <li>
                  <Link
                    onClick={() => handlePackageClick("Enterprise")}
                    className={`package-tab-title ${activePackage === "Enterprise" ? "active" : ""}`}
                    id="enterPrise"
                  >
                    Enterprise
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="pricing-card-layout row d-lg-none  d-block">
            <div className="freecard d-flex align-items-center justify-content-center" id="freeTrial">
              {freeTrialCard && isMonthly && !user?.user?.is_supporter && activePackage === "FreeTrial" && (
                <SalesPricingCard
                  features={freeTrialCard.features}
                  id={freeTrialCard.id}
                  name={freeTrialCard.name}
                  currency={freeTrialCard.currency}
                  price={freeTrialCard.price}
                  plan_type={freeTrialCard.plan_type}
                  interval={freeTrialCard.interval}
                  plan_id={freeTrialCard.plan_id}
                  description={freeTrialCard.description}
                  user={freeTrialCard.user}
                  isSecondCard={false}
                />
              )}

              {/* Render the rest of the cards in the second row */}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {filteredData.map(
                (card, index) =>
                  activePackage === card.name && (
                    <SalesPricingCard
                      key={card.id} // Don't forget to add a unique key prop when mapping
                      features={card.features}
                      id={card.id}
                      name={card.name}
                      currency={card.currency}
                      price={card.price}
                      plan_type={card.plan_type}
                      interval={card.interval}
                      plan_id={card.plan_id}
                      description={card.description}
                      user={card.user}
                      isSecondCard={index === 1}
                    />
                  )
              )}
            </div>
            <div className="d-flex align-items-center justify-content-center">
              {activePackage === "Enterprise" && (
                <div className="my-3  col-lg-3 col-md-6 enterprise-card d-flex justify-content-center align-items-center">
                  <div className="MultipleUsers ">
                    <div className="enterprise-title  fs-30">Enterprise</div>
                    <h5 className="mx-3 fs-24">For teams and multiple user needs</h5>
                    <div className="d-flex justify-content-center p-2">
                      <button
                        className="d-flex justify-content-center align-items-center btn1 btn contact-us-button pt-20  px-4"
                        onClick={handleContactUs}
                      >
                        Contact Us
                      </button>
                    </div>
                    <div className="get-quote-title  mx-3 mt-4">Get a quote</div>
                    <div className="d-flex align-items-start gap-2 mt-2">
                      <img src={FeatureTick} alt="Feature" className="feature-tick-enterprise-package" />

                      <p className="ml-2 get-quote-description">Get a custom quote based on your needs</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="pricing-card-layout row d-lg-flex   d-none">
            <div className="freecard d-flex align-items-center justify-content-center">
              {freeTrialCard && isMonthly && !user?.user?.is_supporter && (
                <SalesPricingCard
                  features={freeTrialCard.features}
                  id={freeTrialCard.id}
                  name={freeTrialCard.name}
                  currency={freeTrialCard.currency}
                  price={freeTrialCard.price}
                  plan_type={freeTrialCard.plan_type}
                  interval={freeTrialCard.interval}
                  plan_id={freeTrialCard.plan_id}
                  description={freeTrialCard.description}
                  user={freeTrialCard.user}
                  isSecondCard={false}
                />
              )}

              {/* Render the rest of the cards in the second row */}
            </div>
            {filteredData.map((card, index) => (
              <SalesPricingCard
                key={card.id} // Don't forget to add a unique key prop when mapping
                features={card.features}
                id={card.id}
                name={card.name}
                currency={card.currency}
                price={card.price}
                plan_type={card.plan_type}
                interval={card.interval}
                plan_id={card.plan_id}
                description={card.description}
                user={card.user}
                isSecondCard={index === 1}
              />
            ))}
            <div className="my-3  col-lg-3 col-md-6 enterprise-card d-flex justify-content-center align-items-center">
              <div className="MultipleUsers ">
                <div className="enterprise-title  fs-30">Enterprise</div>
                <h5 className="mx-3 fs-24">For teams and multiple user needs</h5>
                <div className="d-flex justify-content-center p-2">
                  <button
                    className="d-flex justify-content-center align-items-center btn1 btn contact-us-button pt-20  px-4"
                    onClick={handleContactUs}
                  >
                    Contact Us
                  </button>
                </div>
                <div className="get-quote-title  mx-3 mt-4">Get a quote</div>
                <div className="d-flex align-items-start gap-2 mt-2">
                  <img src={FeatureTick} alt="Feature" className="feature-tick-enterprise-package" />

                  <p className="ml-2 get-quote-description">Get a custom quote based on your needs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PricingFaq />
        <MoneyBack />
      </section>
    </>
  );
};

export default SalesCoachPricing;
