import apiClient, { setHeader } from "../utils/apiClient";

const PCSHubspot = (data) => {
    return apiClient.post('/hubspot/', data);
};

const PCSHubspotService = {
    PCSHubspot
};

export default PCSHubspotService;
