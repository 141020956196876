import React, { useEffect, useState } from "react";
import axios from "axios";
import ProgressBar from "@ramonak/react-progress-bar";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";

import AudioRequestService from "../../../services/AudioRequestService";
import ZoomService from "../../../services/ZoomService";
import uploadIcon from "../../../assets/ai-assistant/uploadIcon.png";
import deleteIcon from "../../../assets/ai-assistant/deleteIcon.png";
import { selectAuth, signOutUser, updatedIsWalkThroughTakenStatus } from "../../../redux/authSlice";
import zoomIcon from "../../../assets/ai-assistant/zoom_icon.png";
import { data, error } from "jquery";
import Joyride from "react-joyride";
import "../style.css";

const AIAssitantStep1 = ({ onNext }) => {
  const [dragActive, setDragActive] = useState(false);
  const [audioFile, setAudioFile] = useState();
  const [audioTitle, setAudioTitle] = useState("");
  const [fileError, setFileError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileUrl, setFileUrl] = useState();
  const [browseDisableStatus, setBrowseDisableStatus] = useState(false);
  const [isWalkThroughTourStep1, setIsWalkThroughTourStep1] = useState();
  const [isJoyrideRunning, setIsJoyrideRunning] = useState(false);

  // state for zoom start
  const [zoomAudioFile, setZoomAudioFile] = useState();
  const [zoomPopUpOpen, setZoomPopUpOpen] = useState(false);
  const [zoomRecordings, setZoomRecordings] = useState([]);
  const [selectedZoomRecording, setSelectedZoomRecording] = useState(null);

  // state for zoom end

  const { token } = useSelector(selectAuth);
  const location = useLocation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    setIsWalkThroughTourStep1(location?.state);
  }, [location.state]);

  useEffect(() => {
    if (isWalkThroughTourStep1 === "walkthrough") {
      setIsJoyrideRunning(true);
    }
  });

  const tourSteps = [
    {
      target: ".file-upload-lable",
      content:
        "Welcome to the audio upload feature. This is your gateway to adding your favorite audio files to our platform.",
      disableBeacon: "true"
    },
    {
      target: ".walkthrough-dummy-class",
      content:
        "Welcome to the audio upload feature. This is your gateway to adding your favorite audio files to our platform.",
      disableBeacon: "true"
    }
  ];

  // audio file validation

  const handleFileValidation = (file) => {
    if (!file) {
      setFileError("Please select an audio file.");
      return false;
    }

    const allowedAudioFormats = [
      "audio/mpeg",
      "audio/wav",
      "audio/aac",
      "audio/aiff",
      "audio/flac",
      "audio/mp4",
      "audio/x-ms-wma",
      "audio/x-m4a"
    ];

    if (!allowedAudioFormats.includes(file.type)) {
      setFileError("Please select a file with a supported audio format.");
      setAudioFile(null);
      return false;
    }

    setFileError("");
    return true;
  };
  // audio title validation
  const handleTitleValidation = (title) => {
    if (!title.trim()) {
      setTitleError("Conversation Title is required.");
      return false;
    }
    setTitleError("");
    return true;
  };

  const handleDeleteFile = () => {
    // Clear the fileUrl when the delete icon is clicked
    setFileUrl("");
    setAudioFile("");
    setAudioTitle("");
    setUploadProgress(0);
    setFileError("");
    setTitleError("");
    setBrowseDisableStatus(false);
  };

  // on submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    // form validation
    const isFileValid = handleFileValidation(audioFile);
    const isTitleValid = handleTitleValidation(audioTitle);
    try {
      if (isFileValid && isTitleValid) {
        // for proceed after successful upload
        if (uploadProgress === 100) {
          setBrowseDisableStatus(true);
          const data = {
            audio_file: audioFile.name,
            audio_file_url: fileUrl,
            gender_output_audio: "female",
            conversation_title: audioTitle,
            conversation_description: audioFile.name,
            audio_file_size: audioFile.size,
            integration_type: "default"
          };

          const response = await AudioRequestService.postAudioRequest(data, token);
          if (response.data.status) {
            navigate("/ai-assistant/2", {
              state: response.data
            });
            toast.success("Your problem generation is in progress. Please wait...");
            setBrowseDisableStatus(false);
          }
        } else {
          const data = { audio_file_name: audioFile.name };

          // call to s3_url_generator api
          setBrowseDisableStatus(true);
          const response = await AudioRequestService.s3UrlGenerator(data, token);

          const { upload_url, file_url } = response.data.data;

          // put request to s3 pre-assigned url
          await axios.put(upload_url, audioFile, {
            headers: {
              "Content-Type": audioFile.type,
              "x-amz-acl": "public-read"
            },
            // Enable tracking of upload progress
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            }
          });
          setUploadProgress(100);
          setBrowseDisableStatus(false);

          setFileUrl(file_url);

          toast.success("Audio file uploaded successfully.Please proceed");
        }
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
        return;
      }
      toast.error(error?.response?.data?.message?.error[0]);
      handleDeleteFile();
    }
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setAudioFile(e.dataTransfer.files[0]);
    }
  };

  // Function to clean up the file name start
  const cleanUpFileName = (fileName) => {
    // Remove file extension
    const cleanedFileName = fileName.replace(/\..+$/, "");

    // Replace underscores and other special characters with spaces
    const sanitizedFileName = cleanedFileName.replace(/[_#@$%^&*!-]/g, " ");

    // Capitalize the first letter of each word
    const titleCaseFileName = sanitizedFileName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return titleCaseFileName;
  };
  // Function to clean up the file name end

  // triggers when file is selected with click
  const handleChange = async function (e) {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      const isFileValid = handleFileValidation(selectedFile);
      if (isFileValid) {
        setAudioFile(selectedFile);
        setUploadProgress(0);

        try {
          // Set the conversation title to the selected file name
          // setAudioTitle(selectedFile.name);
          setAudioTitle(cleanUpFileName(selectedFile.name));

          // call to s3_url_generator api
          const data = { audio_file_name: selectedFile.name };
          setBrowseDisableStatus(true);
          const response = await AudioRequestService.s3UrlGenerator(data, token);

          const { upload_url, file_url } = response.data.data;

          // put request to s3 pre-assigned url
          await axios.put(upload_url, selectedFile, {
            headers: {
              "Content-Type": selectedFile.type,
              "x-amz-acl": "public-read"
            },
            // Enable tracking of upload progress
            onUploadProgress: (progressEvent) => {
              const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
              setUploadProgress(progress);
            }
          });
          setUploadProgress(100);
          setBrowseDisableStatus(false);

          setFileUrl(file_url);

          toast.success("Audio file uploaded successfully. Please proceed");
        } catch (error) {
          if (error?.response?.status === 401) {
            navigate("/sign-in");
            toast.error("Please login again.");
            dispatch(signOutUser());
            return;
          }
          toast.error(error?.response?.data?.message?.error[0]);
          handleDeleteFile();
        }
      }
    }
  };

  // zoom-auth function start

  const zoomAuthUrlGenerator = () => {
    ZoomService.zoomAuth(token)
      .then((response) => {
        const zoomAuthUrl = response?.data?.data?.auth_url;
        if (zoomAuthUrl) {
          toast.info("You need to authorize to access this resource.");
          window.open(zoomAuthUrl, "_self");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getZoomConversation = () => {
    ZoomService.getZoomRecordings(token)
      .then((response) => {
        setZoomPopUpOpen(true);
        setZoomRecordings(response.data.data.meetings);
      })
      .catch((error) => {
        console.error(error);
        if (error?.response?.status === 404) {
          zoomAuthUrlGenerator();
        } else if (error?.response?.status === 401) {
          zoomAuthUrlGenerator();
        }
      });
  };

  const formatDate = (dateTimeString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleDateString("en-US", options) + " " + dateTime.toLocaleTimeString();
  };

  const zoomUserAudioRequest = (selectedRecording) => {
    const audioFile = selectedRecording.recording_files.find(
      (file) => file.recording_type === "audio_only" && file.status === "completed"
    );

    const data = {
      uuid: selectedRecording?.uuid,
      meeting_id: selectedRecording?.id,
      topic: selectedRecording?.topic,
      download_url: audioFile?.download_url,
      audio_type: audioFile?.file_type,
      file_size: audioFile?.file_size
    };
    ZoomService.zoomAudioRequest(data, token)
      .then((response) => {
        navigate("/ai-assistant/2", {
          state: response?.data?.data
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // zoom-auth function end

  const handleJoyrideCallback = async (data) => {
    try {
      const { status, type } = data;
      if (data.action === "skip") {
        const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
        dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
        setIsJoyrideRunning(false);
        const updatedState = "skip";
        navigate("/ai-assistant/1", { state: updatedState });
      }
      if (status === "finished" && type === "step:after") {
        // Check if the last step is reached
        const isSecondLastStep = tourSteps.length - 1;
        if (isSecondLastStep) {
          // Navigate to the desired route
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/ai-assistant/2", { state: "walkthrough" });
          }
        }
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        navigate("/dashboard");
        toast.error(error?.response?.data?.message?.message[0]);
      } else if (error?.response?.status === 401) {
        navigate("/sign-in");
        toast.error("Please login again.");
        dispatch(signOutUser());
      }
    }
  };
  const walkthroughBtnText = {
    last: "Next"
  };

  return (
    <section className="m-lg-5 m-3 pb-5">
      {isWalkThroughTourStep1 === "walkthrough" && (
        <Joyride
          steps={tourSteps}
          showSkipButton={true}
          locale={walkthroughBtnText}
          run={isJoyrideRunning}
          continuous={true}
          callback={handleJoyrideCallback}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#ed7f5a",
              zIndex: 1000
            },
            tooltip: {
              fontFamily: "lato"
            },
            buttonClose: {
              fontFamily: "lato"
            }
          }}
        />
      )}

      {zoomPopUpOpen && (
        <div className="popup-overlay-supportus step3-popup">
          <div className="popup">
            <div className="popheader">
              <h2>Zoom Conversation</h2>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                onClick={() => {
                  setZoomPopUpOpen(false);
                  setSelectedZoomRecording(null);
                }}
              >
                Close
              </button>
            </div>
            <div className="popup-content p-3 d-flex flex-column justify-content-center align-items-center">
              <div className="table-responsive ">
                <table className="history-table table-borderless  mt-3 text-center custom-table">
                  {/* Add Bootstrap table classes */}
                  <thead className="history-table-header pt-16">
                    <tr className="text-start">
                      <th className="ps-2">Select</th>
                      <th className="ps-4">Topic</th>
                      <th className="ps-4">Meeting ID</th>
                      <th className="ps-4">Status</th>
                      <th className="ps-4 pe-4">Date & Time</th>
                    </tr>
                  </thead>
                  <tbody className="pt-16 ">
                    {zoomRecordings.map((recording) => (
                      <tr key={recording.id}>
                        <td>
                          <input
                            type="radio"
                            name="selectedRecording"
                            value={recording.id}
                            checked={selectedZoomRecording === recording}
                            onChange={() => setSelectedZoomRecording(recording)}
                            disabled={recording.recording_files.some((file) => file.status !== "completed")}
                          />
                        </td>
                        <td className="ps-4">{recording?.topic}</td>
                        <td className="ps-4">{recording?.id}</td>
                        <td className="ps-4 text-capitalize">{recording?.recording_files[0]?.status}</td>

                        <td className="ps-4 pe-4">{formatDate(recording.start_time)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {selectedZoomRecording && (
                <button
                  className="btn close-chat-btn primary-orange-gradient-ai-assistant-button mt-4"
                  disabled={!selectedZoomRecording}
                  onClick={() => zoomUserAudioRequest(selectedZoomRecording)}
                >
                  PROCEED
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="container upload-audio-container">
        <div className="ai-assistant-stepper ai-assistant-font-family ">
          <div className="container text-center flex-column d-flex justify-content-center align-items-center p-lg-5 p-4 m-lg-4 ">
            <h3 className="fs-30 roboto-700 ">Upload Sales Conversation Audio</h3>
            <p style={{ fontSize: "13px" }} className="roboto-400">
              Please select only audio version MP3, WAV, AAC, AIFF, FLAC, M4A, WMA
            </p>
            <div className="m-3 pb-5 file-upload-lable">
              <form
                id="form-file-upload"
                className={`${
                  uploadProgress > 0 ? "form-file-upload-height-after" : "form-file-upload-height-before"
                } `}
                onDragEnter={handleDrag}
                onSubmit={handleSubmit}
                encType="multipart/form-data"
              >
                {/* audio title input */}
                <input
                  type="text"
                  maxLength={50}
                  autoComplete="off"
                  className="audio-title form-control"
                  id="autoSizingInput"
                  placeholder="Conversation Title"
                  value={audioTitle}
                  onChange={(e) => {
                    setAudioTitle(e.target.value);
                    setTitleError("");
                  }}
                ></input>
                <p className="errors">{titleError && <span className="text-danger">{titleError}</span>}</p>
                {/* audio file input */}
                <input
                  key={audioFile?.name}
                  type="file"
                  id="input-file-upload"
                  name="audioFile"
                  accept="audio/*"
                  onChange={(e) => {
                    handleChange(e);
                    // setFileError("");
                  }}
                  disabled={browseDisableStatus}
                />
                {/* <div className="d-lg-flex d-md-flex gap-2"> */}
                  <label
                    id="label-file-upload"
                    htmlFor="input-file-upload"
                    className={`${dragActive ? "drag-active" : ""}`}
                  >
                    <div>
                      <img src={uploadIcon} alt="Upload Icon" className="m-2" />
                      <p className="upload-text  gap-1 justify-content-center align-items-center">
                        <span className="drag-drop-text d-lg-block d-none">Drag & drop files or </span>
                        <span className={`upload-button ${browseDisableStatus ? "browse-disable" : "browse-enable"}`}>
                          Browse
                        </span>
                      </p>
                      <p className="upload-sub-text">Supported formats: MP3, WAV, AAC, AIFF, FLAC, M4A, WMA</p>
                      <p>{audioFile?.name}</p>
                    </div>
                  </label>

                  {/* zoom audio upload start */}
                  {/* <label id="label-file-upload" className="mt-3 mt-lg-0 mt-md-0">
                    <div>
                      <Link onClick={getZoomConversation} className="zoom-start-link">
                        <img src={zoomIcon} alt="Upload Icon" className="zoom-icon m-2" />

                        <p className="upload-text">Select Zoom Conversation</p>
                      </Link>
                      <p className="upload-sub-text">Supported formats: MP3, WAV, AAC, AIFF, FLAC, M4A, WMA</p>
                      <p>{zoomAudioFile?.name}</p>
                    </div>
                  </label> */}
                  {/* zoom audio upload end */}
                {/* </div> */}
                <p className="errors">{fileError && <span className="text-danger">{fileError}</span>}</p>
                {dragActive && (
                  <div
                    id="drag-file-element"
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                  ></div>
                )}
                {uploadProgress > 0 && (
                  <>
                    <p className="uploading-audio">
                      Uploading {audioFile?.name}...{uploadProgress}%
                    </p>
                    <ProgressBar
                      completed={uploadProgress}
                      maxCompleted={100}
                      bgColor="linear-gradient(117.47deg, #F09A3E 0%, #EC7368 48.96%, #C46188 100%)"
                      height="3px"
                      isLabelVisible={false}
                    />
                  </>
                )}
                {uploadProgress === 100 && (
                  <>
                    <p className="uploading-audio mt-4">Uploaded</p>
                    {fileUrl && (
                      <>
                        <input
                          type="text"
                          className="uploaded-file position-relative"
                          value={audioFile.name}
                          readOnly
                        />
                        <img
                          className="delete-icon position-absolute"
                          src={deleteIcon}
                          alt="Delete"
                          onClick={handleDeleteFile}
                        />
                      </>
                    )}
                  </>
                )}
                {/* submit button */}
                {uploadProgress === 100 && (
                  <button
                    type="submit"
                    className="btn upload-btn primary-orange-gradient-ai-assistant-button mt-2"
                    disabled={browseDisableStatus}
                  >
                    PROCEED
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIAssitantStep1;
