import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";
import { BallTriangle, ThreeDots } from "react-loader-spinner";
import { MdError, MdDoneAll } from "react-icons/md";
import { RiAiGenerate } from "react-icons/ri";
import { FiRefreshCw } from "react-icons/fi";
import { AiTwotoneDelete } from "react-icons/ai";
import Modal from "react-modal";

import { selectAuth, signOutUser, updateLatestStatus } from "../../../../redux/authSlice";
import DashboardService from "../../../../services/DashboardService";
import Download from "../../../../assets/dashboard/Download.png";
import FileIcon from "../../../../assets/dashboard/FileIcon.png";
import Avtar3 from "../../../../assets/dashboard-navbar/Avtar3.png";
import Funnel from "../../../../assets/dashboard/Funnel.png";
import "../../style.css";
import { GrNext, GrPrevious } from "react-icons/gr";
import FilterButton from "./FilterButton";
import { toast } from "react-toastify";

const HistoryTable = ({ showPagination }) => {
  const [loading, setLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const ITEMS_PER_PAGE = 10;
  const [filterStatus, setFilterStatus] = useState(null);

  const data = useSelector(selectAuth);

  const { full_name, profile_pic } = data.user;

  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [title, setTitle] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [userName, setUserName] = useState(null);
  const [queryData, setQueryData] = useState(null);
  // const user2 = useSelector(profilePic);

  const pageCount = Math.ceil(historyData.length / ITEMS_PER_PAGE);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDeleteRequest = (itemID) => {
    setShowDeleteModal(true);
    setDeleteItemId(itemID);
  };
  const confirmDeleteRequest = () => {
    DashboardService.deleteAudioRequest(
      {
        headers: {
          Authorization: `token ${data.token}`
        }
      },
      deleteItemId
    )
      .then((response) => {
        toast.success(response?.data?.message);
        setHistoryData((prevData) => prevData.filter((item) => item.id !== deleteItemId));

        // getPaginatedData();
        if (getPaginatedData().length === 0) {
          // Move to the previous page if the current page is empty
          setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
        }
      })
      .catch((error) => {
        console.error("Error deleting item:", error);
      });
    setShowDeleteModal(false); // Close the modal after confirming cancellation
  };
  const fetchHistoryData = (page) => {
    const offset = page * ITEMS_PER_PAGE;

    DashboardService.getHistory(
      {
        headers: {
          Authorization: `token ${data.token}`
        },
        params: {
          offset,
          limit: ITEMS_PER_PAGE,
          status: filterStatus
        }
      },
      queryData
      // queryData
    )
      .then((response) => {
        // if (response?.data?.data[0]?.user_active === false) {
        //   navigate("/pricing"); // Navigate to pricing page
        // } else {
        setHistoryData(response?.data?.data);
        // }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          dispatch(signOutUser());
          return;
        }
      });
  };

  useEffect(() => {
    setLoading(true);

    DashboardService.getHistory({
      headers: {
        Authorization: `token ${data.token}`
      }
    })
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchHistoryData(currentPage);

    const intervalId = setInterval(() => {
      fetchHistoryData(currentPage);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [currentPage, queryData]);

  useEffect(() => {
    if (historyData.length > 0) {
      // Get the latest status from the last item in historyData
      const latestStatus = historyData[historyData.length - 1].status;
      dispatch(updateLatestStatus(latestStatus));
    }
  }, [historyData]);

  const extractFileFormat = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1].toUpperCase(); // Assumes the format is the last part of the filename
  };

  const getStatusProgress = (status) => {
    switch (status) {
      case "pending":
        return { progress: 25, className: "pending-class" };
      case "in_process":
        return { progress: 50, className: "in-progress-class" };
      case "problem_process":
        return { progress: 75, className: "problrm-process-class" };
      case "solution_generated":
        return { progress: 90, className: "solution-generating-class" };
      case "completed":
        return { progress: 100, className: "completed-class" };

      default:
        return { progress: 0, className: "failed-class" };
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "pending":
        return "Problem is Generating";
      case "in_process":
        return "Problem is Generating";
      case "problem_process":
        return "Problem Generated";
      case "solution_generated":
        return "Solution is Generating";

      case "completed":
        return "Solution Generated";

      default:
        return "Failed";
    }
  };

  const handlePaginationClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return historyData.slice(startIndex, endIndex);
  };

  const handleProblemProcessStatus = (record) => {
    if (record.status === "problem_process") {
      navigate("/ai-assistant/2", {
        state: record
      });
    } else if (record.status === "completed") {
      navigate("/ai-assistant/3", {
        state: record
      });
    }
  };
  const handleDownload = (url) => {
    // Check if the URL is not empty or null before navigating
    if (url) {
      window.open(url, "_blank");
    }
  };

  const handleFilter = (page) => {
    const offset = page * ITEMS_PER_PAGE;
    const query = {
      username: userName,
      title: title,
      status_key: selectedStatus
    };

    let queryString = [];
    Object.keys(query).forEach((key) => {
      if (query[key] !== null && query[key] !== "") {
        queryString.push(`${key}=${query[key]}`);
      }
    });

    let buildQuery = queryString.length && queryString.join("&");

    const fullQuery = buildQuery.length ? buildQuery : "";

    setQueryData(fullQuery);

    DashboardService.getHistory(
      {
        headers: {
          Authorization: `token ${data.token}`
        },
        params: {
          offset, // Calculate the offset based on the current page
          limit: ITEMS_PER_PAGE, // API pages are 1-indexed
          status: filterStatus
        }
      },
      fullQuery
    )
      .then((response) => {
        setHistoryData(response?.data?.data); // Set the data in state
      })
      .catch((error) => {
        console.error("Error fetching history data:", error);

        if (error?.response?.status === 401) {
          navigate("/sign-in");
          dispatch(signOutUser());
          return;
        }
      });
  };

  const handleReset = () => {
    setTitle("");
    setSelectedStatus("");
    setUserName("");

    setUserName((prevUserName) => {
      return prevUserName; // Return the same value
    });
    setTitle((prevTitle) => {
      return prevTitle; // Return the same value
    });
    setSelectedStatus((prevSelectedStatus) => {
      return prevSelectedStatus; // Return the same value
    });

    const clearedQueryData = ""; // Reset the query data to empty
    setQueryData(clearedQueryData); // Update the queryData state
    fetchHistoryData(0); // Reset to the first page
  };

  const imageUrl = profile_pic ? profile_pic : Avtar3;

  return (
    <section className="container mt-2">
      {/* Wrap the table in a Bootstrap container */}
      <div className="dashboard-title d-flex  justify-content-between aligin-items-center my-3">
        <div className="commonHeading">History</div>

        <div className="d-flex gap-2 align-items-center">
          <button className="border-0 bg-transparent mt-1 reset-filter-btn" onClick={handleReset}>
            <FiRefreshCw />
          </button>
          <button className="pt-16 filter-button d-flex align-items-center justify-content-center filter-btn">
            <div>
              <img src={Funnel} alt="funnel" />
            </div>
            <span>
              <FilterButton
                title={title}
                setTitle={setTitle}
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
                userName={userName}
                setUserName={setUserName}
                handleFilter={() => handleFilter(currentPage)}
              />
            </span>
          </button>
        </div>
      </div>

      <div className="table-responsive ">
        <table className="history-table table-borderless  mt-3 text-center">
          {/* Add Bootstrap table classes */}
          <thead className="history-table-header pt-16">
            <tr className="text-start">
              <th className="ps-4">Username</th>
              <th>Title</th>
              <th>Format</th>
              <th>Progress</th>
              <th>Status</th>
              <th>Download</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody className="pt-16 ">
            {loading && historyData.length === 0 ? ( // Check if loading is true
              // Show loading spinner
              <tr className="text-center">
                <td colSpan="8" className="">
                  <BallTriangle
                    className=""
                    height={30}
                    width={30}
                    radius={5}
                    color="orange"
                    ariaLabel="ball-triangle-loading"
                    visible={true}
                    wrapperStyle={{
                      display: " flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "50px",
                      marginBottom: "50px"
                    }}
                  />
                  {/* Loading */}
                </td>
              </tr>
            ) : historyData.length === 0 ? (
              // Display a message when data is not available
              <tr className="text-center mt-2">
                <td colSpan="8" className="empty-table">
                  No data available.
                </td>
              </tr>
            ) : (
              getPaginatedData().map((item, index) => {
                const { progress, className } = getStatusProgress(item.status);
                return (
                  <tr key={item.id} className={`text-start ${index % 2 === 0 ? "table-striped" : ""}`}>
                    <td>
                      <div className="px-4 py-2 d-flex gap-2 align-items-center justify-content-start">
                        <img src={imageUrl} alt="profile-icon" className="avtar" />
                        {`${full_name}`}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex gap-2 align-items-center justify-content-start me-2 me-sm-none">
                        <img src={FileIcon} alt="file-icon" />
                        {item?.conversation_title?.length > 25
                          ? `${item?.conversation_title?.substring(0, 25)}...`
                          : item?.conversation_title}
                      </div>
                    </td>
                    <td>{extractFileFormat(item.audio_file)}</td>
                    <td>
                      <div className="">
                        {progress === 100 ? (
                          <MdDoneAll className="complete-process" />
                        ) : progress === 0 ? (
                          <MdError className="failed-process" />
                        ) : progress === 75 ? (
                          <RiAiGenerate className="generated-problem" />
                        ) : progress < 100 && progress > 0 ? (
                          <>
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="orange"
                              ariaLabel="three-dots-loading"
                              wrapperStyle
                              wrapperClass
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                    <td>
                      <button
                        style={{ border: "none" }}
                        className={`badge ${className} me-2 me-sm-none`}
                        onClick={() => handleProblemProcessStatus(item)}
                        disabled={item.status === "failed" || item.status === "pending"}
                      >
                        {getStatusText(item.status)}
                      </button>
                    </td>
                    <td>
                      <button
                        className={`btn ${item.status !== "completed" ? "disabled-button" : ""}`}
                        disabled={item.status !== "completed"}
                      >
                        <img
                          src={Download}
                          alt="download"
                          className="download-button"
                          onClick={() => handleDownload(item.output_audio_file)}
                        />
                      </button>
                    </td>
                    <td>
                      <button
                        className={`btn ${
                          item.status === "pending" || "in_process" || "solution_generated" ? "disabled-button" : ""
                        }`}
                        onClick={() => handleDeleteRequest(item.id)}
                        disabled={
                          item.status === "pending" ||
                          item.status === "in_process" ||
                          item.status === "solution_generated"
                        }
                      >
                        <AiTwotoneDelete className="delete-button" />
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Modal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        contentLabel="Confirm Cancellation"
        ariaHideApp={false}
        className="delete-model"
      >
        <h2 className="pt-30 text-center">Are you sure?</h2>
        <p className="pt-18 text-center">You won't be able to revert this!</p>
        <div className="d-flex gap-4 justify-content-center">
          <div className="delete-yes-popup-button">
            <button onClick={confirmDeleteRequest} className="btn yes-button pt-18">
              Yes, Sure
            </button>
          </div>
          <div className="delete-no-popup-button">
            <button onClick={() => setShowDeleteModal(false)} className="btn no-button pt-18">
              No
            </button>
          </div>
        </div>
      </Modal>

      {historyData.length > 0 && showPagination && (
        <div className="pagination-container history-pagination d-flex justify-content-center align-items-center mt-5 pt-18">
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={handlePaginationClick}
            containerClassName="pagination"
            activeClassName="active"
            pageLinkClassName="page-link"
            previousLabel={
              <button className="page-link">
                <GrPrevious />
              </button>
            }
            nextLabel={
              <button className="page-link">
                <GrNext />
              </button>
            }
            breakLabel={<span className="page-link">...</span>}
            breakClassName="break-me"
            marginPagesDisplayed={1}
            pageRangeDisplayed={3}
            forcePage={currentPage}
          />
        </div>
      )}
    </section>
  );
};

export default HistoryTable;
