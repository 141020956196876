import React from "react";

import { ReactComponent as TitleDesign } from "../../../assets/pricing/TitleDesign.svg";

const TermsOfServiceTitle = () => {
  return (
    <section className="pricing-title-background text-white">
      <div className="pricing-card-padding  d-flex align-items-center justify-content-center">
        <div>
          <h1 className=" prising-title d-flex  align-items-center justify-content-center">Terms Of Service</h1>
          <br />
          <span className="d-flex  align-items-center justify-content-center">Home/Terms Of Service</span>
        </div>

        <TitleDesign className="pricing-title-image px-20px" />
      </div>
    </section>
  );
};

export default TermsOfServiceTitle;
