import React, { useState, useRef, useEffect, createRef } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import login_user from "../../assets/navbar/login_user.png";
import { selectAuth, signOutUser } from "../../redux/authSlice";
import { ReactComponent as AiSalesLogo } from "../../assets/auth/ai-sales-final-logo.svg";
import SupportUs from "./SupportUs";

const Header = ({ setSupportUsRef }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  let isAuthenticated = useSelector(selectAuth);
  const [showPopup, setShowPopup] = useState(false);
  const [active, setActive] = useState();
  let popupRef = createRef(null);

  const tokenData = isAuthenticated?.token;
  const user = useSelector(selectAuth);

  const activeSubscription = isAuthenticated?.user?.active_subscription;

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  useEffect(() => {
    const currentPath = location.pathname;

    const activeIndex = currentPath.split("/")[1];

    setActive(activeIndex);
    setShowPopup(false);
  }, [location.pathname]);

  const handleActive = (val) => {
    setActive(val);
  };

  const handleSignOut = () => {
    dispatch(signOutUser());
    navigate("/sign-in");
    toast.success("You have successfully logged out.");
  };

  const handleViewMore = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const setRef = (ref) => {
    popupRef.current = ref;
  };

  return (
    <header id="header" className="header">
      {/* support us pop-up start */}
      {showPopup && <SupportUs popupref={setRef} handleClosePopup={handleClosePopup} />}
      {/* support us pop-up end */}
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <Link to="/">
            <AiSalesLogo className="logo" aria-label="ai_sales_coach" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse customcollapse d-lg-flex justify-content-lg-between"
            id="navbarSupportedContent"
          >
            <div className="customnav d-flex align-items-center">
              <ul className="navbar-nav mb-2 mb-lg-0 fs-20">
                <li className="nav-item mx-4">
                  {tokenData ? (
                    // <Link className="nav-link" aria-current="page" to="/dashboard">
                    //   Dashboard
                    // </Link>
                    <Link
                      className={`nav-link ${active === "dashboard" && "nav-item-active"}`}
                      aria-current="page"
                      to="/dashboard"
                      // onClick={(e) => {
                      //   if (!activeSubscription) {
                      //     e.preventDefault();
                      //   }
                      // }}
                    >
                      Dashboard
                    </Link>
                  ) : (
                    ""
                  )}
                </li>
                <li className="nav-item mx-4">
                  {!tokenData && (
                    <Link
                      className={`nav-link nav-link-in-responsive ${active === "features" && "nav-item-active"}`}
                      to="/features"
                      onClick={() => handleActive("features")}
                    >
                      Features
                    </Link>
                  )}
                </li>
                <li className="nav-item mx-4">
                  {!tokenData && (
                    <Link
                      className={`nav-link nav-link-in-responsive ${active === "about-us" && "nav-item-active"}`}
                      to="/about-us"
                      onClick={() => handleActive("about-us")}
                    >
                      About Us
                    </Link>
                  )}
                </li>

                <li className="nav-item mx-4">
                  {!tokenData && (
                    <Link
                      className={`nav-link nav-link-in-responsive ${active === "usecases" && "nav-item-active"}`}
                      to="/usecases"
                      onClick={() => handleActive("usecases")}
                    >
                      Use Cases
                    </Link>
                  )}
                </li>
                <li className="nav-item mx-4">
                  <Link
                    to="/pricing"
                    className={`nav-link nav-link-in-responsive ${active === "pricing" && "nav-item-active"}`}
                  >
                    Pricing
                  </Link>
                </li>
                <li className="nav-item mx-4">
                  {!tokenData && (
                    <Link
                      className={`nav-link nav-link-in-responsive ${active === "contact-us" && "nav-item-active"}`}
                      // onClick={handleGetDemo}
                      to="/contact-us"
                      onClick={() => handleActive("contact-us")}
                    >
                      Contact Us
                    </Link>
                  )}
                </li>
              </ul>
              <div className="hederbt d-flex align-items-center">
                {tokenData ? (
                  <>
                    <span className="d-flex align-items-center pointer">
                      <img src={login_user} alt="User" />
                      <Link className="nav-item nav-link nav-link-in-responsive fs-20 ml-2" onClick={handleSignOut}>
                        Logout
                      </Link>
                    </span>
                  </>
                ) : (
                  <>
                    <span className="d-flex align-items-center pointer">
                      <img src={login_user} alt="User" />
                      <Link className="nav-item nav-link nav-link-in-responsive fs-20 ml-2" to="/sign-in">
                        Login
                      </Link>
                    </span>
                  </>
                )}
                {!tokenData && (
                  <button
                    onClick={handleViewMore}
                    className="btn1 btn primary-orange-gradient-button ms-4 fs-20"
                    id="supportus"
                    ref={setSupportUsRef}
                  >
                    Sign Up
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
