import React from "react";
import DashboardLayout from "../../layouts/OuterLayout/DashboardLayout";
import HistoryTable from "../dashboard/components/table/HistoryTable";

const History = () => {
  return (
    <DashboardLayout>
      <HistoryTable showPagination={true} />
    </DashboardLayout>
  );
};

export default History;
