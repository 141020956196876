import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import $ from "jquery";
import Popper from "popper.js";
import "./App.css";
import Home from "./pages/home";
import "./constants/styles.css";
import AppRoutes from "./routes";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from "react-facebook";

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
        <AppRoutes />
      </FacebookProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
