import React, { useEffect, useState, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import "../style.css";
import FeatureTick from "../../../assets/pricing/FeatureTick.png";
import PricingService from "../../../services/PricingService";
import { selectAuth, updateActiveSubscription, signOutUser } from "../../../redux/authSlice";
import SupportUs from "../../../layouts/OuterLayout/SupportUs";

const SalesPricingCard = (data) => {
  const { price, isSecondCard, description, features, interval, name, plan_id, plan_type, id, currency } = data;
  const [disableGetNowBtn, setDisableGetNowBtn] = useState(false);
  const user = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  let popupRef = createRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopup) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopup]);

  const user_data = {
    plan_id: plan_id,
    user_id: String(user?.user?.id)
  };

  const handlePaymentClick = () => {
    if (!user?.token && user?.user?.is_supporter) {
      makePayment();
    } else if (!user?.token && {}) {
      setShowPopup(true);
    } else if (user?.token) {
      setDisableGetNowBtn(true);
      makePayment();
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleFreeTrial = () => {
    const token1 = user.token;

    PricingService.freePlan({
      headers: {
        Authorization: `token ${token1}`
      }
    })
      .then((response) => {
        const updatedPlan = response?.data?.type;
        const updatedUser = { active_subscription: updatedPlan };
        dispatch(updateActiveSubscription(updatedUser));
        toast.success("Congratulations! Your Free Plan is activated");
        navigate("/dashboard");
        setDisableGetNowBtn(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          setDisableGetNowBtn(false);
          return;
        }
        toast.error("You already subscribed for free plan");
        setDisableGetNowBtn(false);
      });
  };

  const makePayment = () => {
    const token1 = user.token;

    if (name === "Free Trial") {
      handleFreeTrial();
    } else {
      if (user?.user?.token && !user?.user?.is_supporter) {
        PricingService.payPalPayment(user_data, {
          headers: {
            Authorization: `token ${token1}`
          }
        })
          .then((response) => {
            const paymentUrl = response?.data?.links[0]?.href;

            if (paymentUrl) {
              window.open(paymentUrl, "_self");
            }
            setDisableGetNowBtn(false);
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              navigate("/sign-in");
              toast.error("Please login again.");
              dispatch(signOutUser());
              return;
            }
            setDisableGetNowBtn(false);
          });
      } else {
        PricingService.payPalPayment(user_data, {
          headers: {}
        })
          .then((response) => {
            const paymentUrl = response?.data?.links[0]?.href;

            if (paymentUrl) {
              window.open(paymentUrl, "_self");
            }
            setDisableGetNowBtn(false);
          })
          .catch((error) => {
            if (error?.response?.status === 401) {
              navigate("/sign-in");
              toast.error("Please login again.");
              dispatch(signOutUser());
              return;
            }
            setDisableGetNowBtn(false);
          });
      }
    }
  };

  const setRef = (ref) => {
    popupRef.current = ref;
  };
  const getIntervalLabel = () => {
    if (interval === "day") {
      return "3 days"; // Display "3 days" for "day" interval
    }
    return interval;
  };

  return (
    <div className={`my-3 col-md-6 col-lg-3 ${isSecondCard ? "second-card-class" : ""}`}>
      {showPopup && <SupportUs popupref={setRef} handleClosePopup={handleClosePopup} />}

      <div className="card sales-pricing-card">
        {(name === "Accelerator (Individual)" || name === "Elite (Individual)") && (
          <>
            <p className=" comming-soon-text   ">Coming Soon, Quarter 1, 2025</p>
          </>
        )}
        {description !== "" && <p className="badge pricing-card-lable mt-2 "> {description}</p>}

        <div
          className={`fs-30 mx-4  ${isSecondCard ? "second-card-style" : ""} ${
            description !== "" ? "" : "pricing-card-header"
          }`}
        >
          {name}
        </div>
        <div className="d-flex mx-3 mt-2 align-items-center gap-2">
          <h1 className={`${isSecondCard ? "second-card-style" : ""}`}>$ {price}</h1>

          <p className="price-type-text">/{getIntervalLabel()}</p>
        </div>
        {plan_type !== "custom" && name !== "Accelerator (Individual)" && name !== "Elite (Individual)" && (
          <div className="start-btn d-flex justify-content-center align-items-center p-2">
            <button
              className="get-started-button btn1 btn d-flex justify-content-center align-items-center pt-20"
              onClick={handlePaymentClick}
              disabled={disableGetNowBtn}
            >
              Get Started Now
            </button>
          </div>
        )}
        {/* {(name === "Accelerator (Individual)" || name === "Elite (Individual)") && (
          <>
            <p className=" comming-soon-text mt-3 mb-1 pt-16">Coming Soon, Quarter 1, 2024</p>
          </>
        )} */}
        <div className="mx-2 mt-4">
          <div className="pricing-feature pt-20">Features</div>
          <div className="pricing-discription mt-2 pt-16">
            {features.map((feature, index) => (
              <div key={index} className="d-flex align-items-start gap-2">
                <img src={FeatureTick} alt="Feature" className="feature-tick" />
                <p className="ml-2" dangerouslySetInnerHTML={{ __html: feature.name }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesPricingCard;
