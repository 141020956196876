import React from "react";
import { Link } from "react-router-dom";

import LoginArt from "../../assets/auth/LoginArt.png";
import "./style.css";
import { ReactComponent as AiSalesLogo } from "../../assets/auth/ai-sales-final-logo.svg";

const AuthLayout = ({ children }) => {
  return (
    <div className="container-fluid authlayout">
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            <div className="logo">
              <Link to="/">
                <AiSalesLogo className="auth-logo" />
              </Link>
            </div>
          </div>
          <div className="row justify-content-center">{children}</div>
        </div>
        <div className="col-md-6 my-3">
          <img src={LoginArt} alt="Signup" className="img-fluid logiart" />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
