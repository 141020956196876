import React from 'react'
import play from './icons/play.png'

const LoadingPlayer = () => {

    return (
        <div className="loadingaudio-player__wrapper d-flex flex-row mt-2">
            <div className="loadingaudio-player__header">
                <span className="loadingaudio-player__button">
                    <img className="audio-player__img" src={play} alt="Play/Pause" />
                </span>
            </div>
            <div className="player loadingaudio-player__body">
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>

            </div>
            <div className="audio-player__footer">
                <span className="audio-player__timer ms-3 d-flex">
                </span>
            </div>
        </div>
    )
}

export default LoadingPlayer
