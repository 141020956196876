import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import facebook from "./../../assets/home/facebook.svg";
import instagram from "./../../assets/home/instagram.svg";
import twitter from "./../../assets/home/twitter.svg";
import linkedIn from "./../../assets/home/linkedin.svg";
import emailVector from "./../../assets/home/emailVector.png";
import { selectAuth, signOutUser } from "../../redux/authSlice";
import LandingPageService from "../../services/LandingPageService";
import { ReactComponent as AiSalesFooterLogo } from "../../assets/auth/ai-sales-white-logo-icon.svg";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectAuth);
  const tokenData = isAuthenticated?.token;

  const handleSignOut = () => {
    dispatch(signOutUser());
    navigate("/sign-in");
    toast.success("You have successfully logged out.");
  };

  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth" // This provides a smooth scrolling animation
    });
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    // Clear the error message when the user starts typing
    setErrorMessage("");
  };
  const handleSubscribe = () => {
    if (!email.trim()) {
      setErrorMessage("Email address cannot be empty");
      return;
    }
    const emailData = { email, category: "news_letter" };
    LandingPageService.hubspot(emailData)
      .then((response) => {
        toast.success(response?.data?.message);
        setEmail("");
      })
      .catch((error) => {
        if (error?.response?.status === 500) {
          toast.error("Something went wrong");
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  return (
    <section className="footer">
      <div className="row">
        <div className="footerdetails col-lg-12 text-center d-flex flex-column align-items-center justify-content-center p-5">
          <h5 className="fs-36">JOIN OUR NEWSLETTER</h5>
          <p className="pt-26">
            Subscribe to the our newsletter for latest updates and special offers and the other information
          </p>
          <div className="pt-3 pb-5 d-flex align-items-center position-relative flex-column my-1">
            <div className="pt-3  d-flex align-items-center position-relative my-1">
              <img src={emailVector} alt="" className="emailvector" />
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="pt-20 bg-black form-control-solid w-250px ps-14 email-input p-3 footer-email"
                placeholder="Your email address"
                value={email}
                inputMode="email"
                onChange={handleEmailChange}
              />
              <button
                className="btn primary-orange-gradient-button px-4 py-3 subscribe-btn pt-20"
                onClick={handleSubscribe}
              >
                Subscribe
              </button>
            </div>
            <div>{errorMessage && <p className="text-danger pt-16">{errorMessage}</p>}</div>
          </div>
          <p className="fs-30">Personalized Sales Coach</p>
          <span className="d-lg-flex gap-3">
            <p className="pt-20">
              <a href="tel:+15853806184" className="contact-number">
                +1 (585) 380-6184
              </a>
            </p>
            <p className="pt-20">
              <a href="mailto:support@aisalescoach.ai" className="contact-mail">
                support@aisalescoach.ai
              </a>
            </p>
          </span>
          <span className="d-flex gap-3">
            <a href="https://www.facebook.com/aisalescoach/" className="footer-icon" target="_blank" rel="noreferrer">
              <img src={facebook} alt="facebook" className="social-link-icons" />
            </a>
            <a href="https://www.instagram.com/aisalescoach/" className="footer-icon" target="_blank" rel="noreferrer">
              <img src={instagram} alt="instagram" className="social-link-icons" />
            </a>
            <a href="https://twitter.com/aisalescoachs" className="footer-icon" target="_blank" rel="noreferrer">
              <img src={twitter} alt="twitter" className="social-link-icons" />
            </a>
            <a
              href="https://www.linkedin.com/company/ai-sales-coach2024/"
              className="footer-icon"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedIn} alt="linkedin" className="linkedin-link-icons" />
            </a>
          </span>
        </div>
      </div>
      <div className="row bg-orange-gradient text-white py-4 text-center fs-6 d-flex mp-2 d-lg-flex flex-lg-row flex-column-reverse align-items-center">
        <div className="col-lg-4 copyrighttext mt-3 mt-lg-0">
          Copyright &copy; {new Date().getFullYear()} AI Sales Coach. All rights reserved.
        </div>
        <div className="d-lg-flex d-md-flex col-lg-6 flex-lg-row flex-column-reverse  flex-md-row gap-3 justify-content-around">
          <div className="footerlink  d-flex  flex-row gap-lg-3 gap-2 text-nowrap align-items-md-start align-items-center justify-content-center text-white">
            <Link to="/features" className="nav-link" onClick={handleLinkClick}>
              Features
            </Link>
            <Link to="/usecases" className="nav-link" onClick={handleLinkClick}>
              Use Cases
            </Link>
            <Link to="/pricing" className="nav-link" onClick={handleLinkClick}>
              Pricing
            </Link>
            <Link to="/about-us" className="nav-link" onClick={handleLinkClick}>
              About Us
            </Link>
            {tokenData ? (
              <Link className="nav-link" onClick={handleSignOut}>
                Log Out
              </Link>
            ) : (
              <Link className="nav-link" to="/sign-in">
                Log in
              </Link>
            )}
          </div>
          <div className="footerlink  d-flex  flex-row gap-3 text-nowrap align-items-md-start align-items-center justify-content-end">
            <Link to="/terms-of-service" className="nav-link" onClick={handleLinkClick}>
              Terms Of Service
            </Link>
            <Link to="/privacy-policy" className="nav-link" onClick={handleLinkClick}>
              Privacy Policy
            </Link>
            <Link to="/refund-policy" className="nav-link" onClick={handleLinkClick}>
              Refund Policy
            </Link>
          </div>
        </div>
        <div className="footerlink col-lg-2  text-white">
          <Link to="/" onClick={handleLinkClick}>
            <AiSalesFooterLogo className="aisales-footer-logo" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Footer;
