import React, { useState } from "react";

const UserNameFilter = ({ userName, setUserName }) => {
  // const [userName, setUserName] = useState("");

  const handleUserNameChange = (event) => {
    setUserName(event.target.value); // Update the state with the input value
  };

  return (
    <div className="fv-row">
      <label htmlFor="exampleInputName" className="form-label">
        User Name
      </label>
      <input
        type="text"
        className="form-control"
        id="exampleInputName"
        name="userName"
        value={userName}
        onChange={handleUserNameChange} // Call the event handler when the input changes
      />
    </div>
  );
};

export default UserNameFilter;
