import React, { useEffect, useState } from "react";
import AuthLayout from "../../../layouts/OuterLayout/AuthLayout";
import "../style.css";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../services/Auth";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { setHeader } from "../../../utils/apiClient";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ConfirmPassword = () => {
  const [confirmPasswordDisableStatus, setConfirmPasswordDisableStatus] = useState(false);

  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // New state variable

  const { token } = location.state || {};

  const confirmpasswordLocation = location.state;

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === "confirmPassword") {
      setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    }
  };

  useEffect(() => {
    // Set the email value from the location state when component mounts
    if (!confirmpasswordLocation) {
      navigate("/");
    }
  });
  // validation schema start
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter your password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*^&#])[A-Za-z\d@$!%*^&#]/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character [!, @, #, $, %, ^, &, or *]"
      )
      .min(8, "Password must be at least 8 characters"),
    confirmPassword: Yup.string()
      .required("Please confirm your password")
      .oneOf([Yup.ref("password")], "Passwords must match")
  });
  // validation schema end

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const navigate = useNavigate();

  const onSubmit = (data) => {
    setConfirmPasswordDisableStatus(true);
    const headers = setHeader({}, token);
    AuthService.resetPassword(data, headers)
      .then((response) => {
        navigate("/success");
        toast.success("Password Updated Successfully");
        setConfirmPasswordDisableStatus(false);
      })
      .catch((error) => {
        toast.error("Something went to wrong");
        setConfirmPasswordDisableStatus(false);
      });
  };
  return (
    <AuthLayout>
      {/* Confirm password page start */}
      <div className="row justify-content-center">
        <h1 className="my-5 d-flex justify-content-center">Forgot password?</h1>
        <div className="col-9 col-lg-7">
          <form onSubmit={handleSubmit(onSubmit)}>
            {/*Password field start */}

            <div className="mb-3 mt-5">
              <div className="formbox">
                <label className="form-label pt-16">Password</label>
                <div className="password-wrapper">
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control form-field pt-16 ${errors.password ? "is-invalid" : ""}`}
                    id="password"
                    placeholder="Password"
                    {...register("password")}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => togglePasswordVisibility("password")}
                  >
                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>
                  {errors.password && <div className="invalid-feedback pt-16">{errors.password.message}</div>}
                </div>
              </div>
            </div>
            {/* Password field end */}

            {/*Confirm Password field start */}

            <div className="mb-3">
              <div className="formbox">
                <label className="form-label pt-16">Confirm Password</label>
                <div className="password-wrapper">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    className={`form-control form-field pt-16 ${errors.confirmPassword ? "is-invalid" : ""}`}
                    id="confirm-password"
                    placeholder="Confirm password"
                    {...register("confirmPassword")}
                  />
                  <button
                    type="button"
                    className="password-toggle-btn"
                    onClick={() => togglePasswordVisibility("confirmPassword")}
                  >
                    {showConfirmPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                  </button>

                  {errors.confirmPassword && (
                    <div className="invalid-feedback pt-16">{errors.confirmPassword.message}</div>
                  )}
                </div>
              </div>
            </div>
            {/* Confirm Password field end */}

            {/* Confirm button start */}
            <div className="mb-3">
              <button
                type="submit"
                className="orange-gradient-auth-button pt-20"
                disabled={confirmPasswordDisableStatus}
              >
                Confirm
              </button>
            </div>
            {/* Confirm button end */}
          </form>
        </div>
        <div className="copyrighttext-position">
          <div className="copyright   footer d-flex justify-content-center mb-3">
            &copy; {new Date().getFullYear()} All rights reserved.
          </div>
        </div>
      </div>

      {/* Confirm password page end */}
    </AuthLayout>
  );
};

export default ConfirmPassword;
