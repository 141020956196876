import React from "react";
import { Helmet } from "react-helmet";

import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import PrivacyPolicyTitle from "./components/PrivacyPolicyTitle";
import PrivacyPolicyContent from "./components/PrivacyPolicyContent";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>AI Sales Coach Privacy Policy: Protecting Your Data</title>
        <meta
          name="description"
          content="AI Sales Coach's privacy policy ensures the privacy and security of your data.
          Learn how we handle and protect your information with care."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <PrivacyPolicyTitle />
        <PrivacyPolicyContent />
      </InnerLayout>
    </>
  );
};

export default PrivacyPolicy;
