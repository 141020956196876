import React from "react";
import { Helmet } from "react-helmet";

import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import RefundPolicyTitle from "./components/RefundPolicyTitle";
import RefundpolicyContent from "./components/RefundpolicyContent";
import "./RefundPolicy.css";

const RefundPolicy = () => {
  return (
    <>
      <Helmet>
        <title>AI Sales Coach Refund Policy: Quick & Reliable Returns</title>
        <meta
          name="description"
          content="AI Sales Coach prioritizes your satisfaction with a transparent refund policy,
          guaranteeing hassle-free returns and efficient reimbursements."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <RefundPolicyTitle />
        <RefundpolicyContent />
      </InnerLayout>
    </>
  );
};

export default RefundPolicy;
