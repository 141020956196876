import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import InnerLayout from "../../layouts/OuterLayout/InnerLayout";
import ContactUsTitle from "./components/ContactUsTitle";
import ContactUsForm from "./components/ContactUsForm";
import * as aboutUsPageCMSService from "../../services/CMSService";

const ContactUs = () => {
  const [teamData, setTeamData] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(true);

  useEffect(() => {
    const aboutUsPageCMSServiceCall = async () => {
      try {
        setImageLoaded(true);
        const [aboutUs] = await Promise.all([aboutUsPageCMSService.aboutUs()]);
        setTeamData(aboutUs?.data?.data?.team);
        setImageLoaded(false);
      } catch (error) {}
    };
    aboutUsPageCMSServiceCall();
  }, []);

  return (
    <>
      <Helmet>
        <title>AI Sales Coach Contact Info: Get in Touch</title>
        <meta
          name="description"
          content="Contact us for AI Sales Coach inquiries and support. Our team is ready to assist
      you with any questions or assistance you need in enhancing your sales
      strategies."
        />
        <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
      </Helmet>
      <InnerLayout>
        <ContactUsTitle />
        <ContactUsForm teamData={teamData} imageLoaded={imageLoaded} />
      </InnerLayout>
    </>
  );
};

export default ContactUs;
