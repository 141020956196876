import React from "react";

const SalesDevelopmentCard = ({ title, subTitle, description, link, image }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 my-3">
      <div className="sales-development-card-outer">
        <div className="sales-development-card-inner secondary-orange-gradient text-white">
          <div className="aipoweredBottom d-flex justify-content-start align-items-center">
            <img src={image} alt={title} className="img-fluid cardimg" style={{ height: "6vw" }} />
          </div>
          <div className="pt-30 mb-4">Step {title}</div>

          <div className="fs-30">{subTitle}</div>
          <div className="pt-16 my-3">{description}</div>
        </div>
      </div>
    </div>
  );
};

export default SalesDevelopmentCard;
