import React from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import "./style.css";

const PasswordChecklist = ({ password }) => {
  const checklistItems = [
    {
      text: "Password has at least 8 characters",
      isValid: password.length >= 8
    },
    {
      text: "Password has at least one uppercase letter",
      isValid: /[A-Z]/.test(password)
    },
    {
      text: "Password has at least one lowercase letter",
      isValid: /[a-z]/.test(password)
    },
    {
      text: "Password has at least one number",
      isValid: /\d/.test(password)
    },
    {
      text: "Password has at least one special character",
      isValid: /[!@#$%^&*]/.test(password)
    }
  ];

  return (
    <div className="password-checklist mt-2">
      {checklistItems.map((item, index) => (
        <div key={index} className="checklist-item d-flex align-items-center justify-content-start gap-2">
          <span>
            {item.isValid ? (
              <AiOutlineCheckCircle className="checklist-icon-valid" />
            ) : (
              <AiOutlineCloseCircle className="checklist-icon-invalid" />
            )}
          </span>
          <span className={` ${item.isValid ? "checklist-text-valid" : "checklist-text-invalid"}`}>{item.text}</span>
        </div>
      ))}
    </div>
  );
};

export default PasswordChecklist;
