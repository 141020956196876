import apiClient from "../utils/apiClient";

const contactUsData = (data) => {
  return apiClient.post("/contact_us/", data);
};

const ContactUsService = {
  contactUsData
};

export default ContactUsService;
