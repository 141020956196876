import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Lottie from "lottie-web";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { selectAuth, signOutUser } from "../../redux/authSlice";
import DashboardIcon from "../../assets/dashboard-navbar/DashboardIcon.png";
import AssistanIcon from "../../assets/dashboard-navbar/AssistanIcon.png";
import HistoryIcon from "../../assets/dashboard-navbar/HistoryIcon.png";
import ResourcesIcon from "../../assets/dashboard-navbar/ResourcesIcon.png";
import TeamIcon from "../../assets/dashboard-navbar/TeamIcon.png";
import ForumIcon from "../../assets/dashboard-navbar/ForumIcon.png";
import { ReactComponent as AiSalesLogoWhite } from "../../assets/dashboard-navbar/ai-sales-final-logo 1.svg";
import AudioRequestService from "../../services/AudioRequestService";
import "./style.css";
import FreeTrialExpire from "../../assets/dashboard-navbar/FreeTrialExpire.json";

const DashboardFooter = () => {
  // constants start
  const freePlanExpired = "Your Free Trial Period has Expired.";
  const freePlanCancelled = "Your Free Trial Period has been Cancelled.";
  const noActivePlan = "You currently have no active plan.";
  const paidPlanCancelled = "Your Paid Plan has been Cancelled.";

  const freePlanDesc =
    "You may choose a package plan to support us in the development of this project, once the platform officially launches you will get three months access for the price of one month for the package you commit to purchasing now. We will let you know by email when the official platform launches with features listed working and ready to use.";
  const paidPlanExpiredDesc =
    'Your plan has expired, and you no longer have access to our premium services. Don\'t miss out on all the benefits we offer. Click "Get Started" below to view our available plans and choose the one that suits you best to continue enjoying our services.';
  const paidPlanCancelledDesc =
    "We're sorry to see that you've cancelled your plan. Your access to our premium services is currently inactive. If you change your mind or want to explore other options, please click \"Get Started\" below to view our available plans.";

  // constants end

  const [showPopover, setShowPopover] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [planTitle, setPlanTitle] = useState();
  const planPopupRef = useRef(null);

  const [planDescription, setPlanDescription] = useState();
  const user = useSelector(selectAuth);

  const { token } = useSelector(selectAuth);

  const planType = user?.user?.free_plan_status;
  const active_subscription = user?.user?.active_subscription;

  const popupRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClosePopup = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#free-trail-expire-loader"),
      animationData: FreeTrialExpire
    });
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClosePopup();
      }
    };

    if (showPopover) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [showPopover]);

  const handleLinkClick = (event) => {
    const check = async () => {
      try {
        if (planType === "expired") {
          setShowPopup(true);
          setPlanTitle(freePlanExpired);
          setPlanDescription(freePlanDesc);
        } else if (planType === "cancel") {
          setShowPopup(true);
          setPlanTitle(freePlanCancelled);
          setPlanDescription(freePlanDesc);
        } else if (active_subscription) {
          const response = await AudioRequestService.audioUploadLimitCheck(token);

          if (response?.data?.status) {
            navigate("/ai-assistant/1", {
              state: true
            });
          }
        } else {
          if (planType === "not") {
            setShowPopup(true);
            setPlanTitle(noActivePlan);
            setPlanDescription(freePlanDesc);
          } else if (planType === "paid" && !active_subscription) {
            setShowPopup(true);
            setPlanTitle(paidPlanCancelled);
            setPlanDescription(paidPlanCancelledDesc);
          }
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          navigate("/dashboard");
          toast.error(error?.response?.data?.message?.error[0]);
        } else if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
        }
      }
      return;
    };

    const isTrue = window.location.pathname.includes("/ai-assistant");
    if (isTrue) {
      event.preventDefault();
    } else {
      check();
    }
  };

  const handleCloseFreeTrialPopup = () => {
    setShowPopup(false);
  };

  return (
    <header id="header" className="dashboard-header d-lg-none  ">
      {showPopup && (
        <div className="popup-overlay-free-trial-expire trial-expire-popup pop-in-responsive">
          <div className="expire-popup aos-init" data-aos="zoom-in" data-aos-duration="1100" ref={planPopupRef}>
            <div className="expire-free-trail-popheader">
              <h2>{planTitle}</h2>
              <button
                className="btn close-chat-btn primary-orange-gradient-ai-assistant-button"
                onClick={handleCloseFreeTrialPopup}
              >
                Close
              </button>
            </div>
            <div className="free-trail-expire-popup-content p-3 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex row justify-content-center align-items-center">
                <div className="col-lg-6 d-flex justify-content-center">
                  <div id="free-trail-expire-loader" className="free-trial-popup-lootie-loader"></div>
                </div>
                <div className="col-lg-6 d-flex flex-column justify-content-center align-items-start">
                  <div className="free-trial-expire-popup-style">{planDescription}</div>
                </div>
                <span className="d-flex align-item-center justify-content-center">
                  <Link className="btn mt-5 close-chat-btn primary-orange-gradient-ai-assistant-button" to="/pricing">
                    Get Started
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <nav className="navbar navbar-expand-lg dashboard-footer ">
        <div className="container-fluid d-flex ">
          <div className=" navbar-collapse d-flex justify-content-center" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 pt-18 dashboardnav dashboard-footer-menu d-flex flex-row justify-content-center align-items-center ">
              <li
                className={`nav-item ${window.location.pathname.includes("dashboard") && "activeTab"} mx-2`}
                data-tooltip-id="dashboard"
              >
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-dashboard"
                  to="/dashboard"
                >
                  <img src={DashboardIcon} alt="dashboard-icon" />
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname.includes("ai-assistant") && "activeTab"} mx-2`}
                data-tooltip-id="your-ai-coach"
              >
                <button
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-salescoach"
                  // to="/ai-assistant/1"
                  onClick={handleLinkClick}
                >
                  <img src={AssistanIcon} alt="dashboard-icon" />
                </button>
              </li>
              <li
                className={`nav-item ${window.location.pathname.includes("history") && "activeTab"} mx-2`}
                data-tooltip-id="history"
              >
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-history"
                  to="/history"
                >
                  <img src={HistoryIcon} alt="dashboard-icon" />
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname.includes("resources") && "activeTab"} mx-2`}
                data-tooltip-id="resources"
              >
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-resources"
                  to="/resources"
                >
                  <img src={ResourcesIcon} alt="dashboard-icon" />
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname.includes("teams") && "activeTab"} mx-2`}
                data-tooltip-id="teams"
              >
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-teams"
                  to="/teams"
                >
                  <img src={TeamIcon} alt="dashboard-icon" />
                </Link>
              </li>
              <li
                className={`nav-item ${window.location.pathname.includes("forum") && "activeTab"} mx-2`}
                data-tooltip-id="forum"
              >
                <Link
                  className="d-flex align-items-center justify-content-center gap-2 nav-link dashbord-nav-forum"
                  to="/forum"
                >
                  <img src={ForumIcon} alt="dashboard-icon" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <ReactTooltip id="dashboard" place="top" content="Dashboard" />
      <ReactTooltip id="your-ai-coach" place="bottom" content="Your AI Coach" />
      <ReactTooltip id="history" place="bottom" content="History" />
      <ReactTooltip id="resources" place="bottom" content="Resources" />
      <ReactTooltip id="teams" place="bottom" content="Teams" />
      <ReactTooltip id="forum" place="bottom" content="Forum" />
    </header>
  );
};

export default DashboardFooter;
