import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ZoomService from "../../services/ZoomService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Joyride from "react-joyride";
import { Helmet } from "react-helmet";

import DashboardLayout from "../../layouts/OuterLayout/DashboardLayout";
import DashboardTitle from "./components/DashboardTitle";
import DashboardCard from "./components/DashboardCard";
import DashboardChart from "./components/DashboardChart";
import HistoryTable from "./components/table/HistoryTable";
import { selectAuth, updatedIsWalkThroughTakenStatus } from "../../redux/authSlice";
import PromocodePopUp from "./partials/PromocodePopUp";
import AudioRequestService from "../../services/AudioRequestService";

const Dashboard = () => {
  const { user, token } = useSelector(selectAuth);

  const socialUser = user?.social_media_type;
  const isWalkThroughTaken = user?.is_walthrough_taken;

  const isVisited = user?.refferal_code_status;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showWalkthrough, setShowWalkthrough] = useState(false);
  const [tourStepIndex, setTourStepIndex] = useState(0);

  useEffect(() => {
    if (!isWalkThroughTaken) {
      setShowWalkthrough(true);
    }
  }, []);

  const startWalkthrough = () => {
    setShowWalkthrough(true);
  };

  const resetTour = () => {
    setShowWalkthrough(false);
    setTourStepIndex(0); // Reset the tour step index to the first step
  };

  const handleJoyrideCallback = async (data) => {
    try {
      const { status, type } = data;
      if (data.action === "skip") {
        const updatedIsWalkThroughStatus = { is_walthrough_taken: true };
        dispatch(updatedIsWalkThroughTakenStatus(updatedIsWalkThroughStatus));
        resetTour();
      }
      if (status === "finished" && type === "step:after") {
        const isSecondToLastStep = tourSteps.length - 2;
        if (isSecondToLastStep) {
          const response = await AudioRequestService.audioUploadLimitCheck(token);
          if (response?.data?.status) {
            navigate("/ai-assistant/1", { state: "walkthrough" });
          }
        }
      }
    } catch (error) {}
  };

  const tourSteps = [
    {
      target: ".dashboardnav",
      content: "Welcome to our navigation menu. Explore the following options to enhance your experience.",
      disableBeacon: "true"
    },
    {
      target: ".dashboard-footer-menu",
      content: "Welcome to our navigation menu. Explore the following options to enhance your experience.",
      disableBeacon: "true"
    },
    {
      target: ".dashbord-nav-profile",
      content: "Manage your personal information and preferences.",
      disableBeacon: true
    },
    {
      target: ".dashboard-card-3",
      content: "Monitor the total number of audio files processed.",
      disableBeacon: true
    },
    {
      target: ".dashboard-card-2",
      content: "Track the total count of AI-generated solutions.",
      disableBeacon: true
    },
    {
      target: ".upgrade-btn",
      content: "Explore our pricing page to unlock premium features and benefits.",
      disableBeacon: true
    },
    {
      target: ".upload-convo-btn",
      content: "Begin the process of uploading your audio files for analysis and insights.",
      disableBeacon: true
    },
    {
      target: ".dashboard-chart",
      content:
        "Analyze the generation of problems and solutions with this graph. Gain insights into the key issues and trends in your data.",
      disableBeacon: true
    },

    {
      target: ".graph-tab-navigation",
      content: "Choose a time frame to view the graph data.",
      disableBeacon: true
    },
    {
      target: ".history-table",
      content: "Explore a detailed table of your historical data and interactions.",
      disableBeacon: true
    },
    {
      target: ".walkthrough-dummy-class",
      content: "This is a dummy step. Click 'Skip' to return to the first step.",
      disableBeacon: true
    }
  ];

  const queryParams = new URLSearchParams(location.search);

  const zoom_code = queryParams.get("code");

  const handleaccessTokenGenerator = () => {
    const data = { code: zoom_code };
    ZoomService.accessTokenGenerator(data, token)
      .then((response) => {
        toast.success("You are ready for start with zoom");
        queryParams.delete("code");
        navigate({ search: queryParams.toString() });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (zoom_code) {
      handleaccessTokenGenerator();
    }
  }, [location]);

  const walkthroughBtnText = {
    last: "Next"
  };

  return (
    <>
      <Helmet>
        <title>AI Sales Coach</title>
        <meta name="description" content="THE PERFECT CLOSING SCRIPT - AI SALES COACH" />
      </Helmet>
      <DashboardLayout>
        {socialUser && (isVisited === "1" || isVisited === "2") && <PromocodePopUp />}
        <Joyride
          steps={tourSteps}
          showSkipButton={true}
          run={showWalkthrough}
          continuous={true}
          callback={handleJoyrideCallback}
          locale={walkthroughBtnText}
          styles={{
            options: {
              arrowColor: "#fff",
              backgroundColor: "#fff",
              primaryColor: "#ed7f5a",
              zIndex: 1000
            },
            tooltip: {
              fontFamily: "lato"
            },
            buttonClose: {
              fontFamily: "lato"
            }
          }}
        />
        <DashboardTitle />
        <div className="d-flex justify-content-center">
          <button onClick={startWalkthrough} className=" btn-1 start-walkthrough  mb-3">
            Start Walkthrough
          </button>
        </div>
        <DashboardCard />
        <div className="container">
          <hr className="customhhr" />
        </div>
        <DashboardChart />
        <HistoryTable showPagination={true} />
      </DashboardLayout>
    </>
  );
};

export default Dashboard;
