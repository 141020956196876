import React from "react";
import DashboardChart1 from "../partials/DashboardChart1";
import DashboardChart2 from "../partials/DashBoardChart2";

const DashboardChart = () => {
  return (
    <div className="container mt-5">
      <div className="row">
        {/* <div className="col-lg-6 col-md-12 mb-4">
        <div className="dashboard-chart">
          <h4 className="commonHeading">User Overview</h4>
          <DashboardChart1 />
        </div>
      </div> */}
        <div className="col-lg-12 col-md-12 mb-4">
          <div className="dashboard-chart">
            <h4 className="commonHeading">Problems Overview</h4>
            <DashboardChart2 />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardChart;
