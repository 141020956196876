import apiClient, { setHeader } from "../utils/apiClient";

const signUp = (data) => {
  return apiClient.post("/register/", data);
};

const signIn = (email, password) => {
  return apiClient.post("/login/", { email, password }, setHeader());
};

const validateOtp = (data) => {
  return apiClient.post("/verify_activation_otp/", data);
};

const socialLogin = (data) => {
  return apiClient.post("/social_login/", data, setHeader());
};

const forgetPassword = (data) => {
  data.type = "FORGOT_PASSWORD_OTP";
  return apiClient.post("/send_otp/", data);
};

const forgetPasswordVerifyOTP = (data) => {
  return apiClient.post("/verify_otp/", data);
};

const resetPassword = (data, headers) => {
  return apiClient.post("/forgot_reset_password/", data, headers);
};

const resendOtp = (data, type) => {
  return apiClient.post("/send_otp/", data, type);
};

const referralCode = (data, token) => {
  return apiClient.post("/check_referral_code/", data, setHeader({}, token));
};
const AuthService = {
  signUp,
  signIn,
  validateOtp,
  socialLogin,
  forgetPassword,
  forgetPasswordVerifyOTP,
  resetPassword,
  resendOtp,
  referralCode
};

export default AuthService;
