import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",

  initialState: {
    user: {},
    profile_pic: {},
    visited: false,
    latestStatus: null
  },

  reducers: {
    signInUser: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },

    signOutUser: (state) => {
      state.user = null;
    },
    updateSubscriptionInfo: (state, action) => {
      state.user.subscriptionInfo = action.payload;
    },
    updateProfilePic: (state, action) => {
      state.profile_pic = action.payload;
    },

    updateActiveSubscription: (state, action) => {
      state.user.user.active_subscription = action.payload.active_subscription;
      state.user.user.free_plan_status = action.payload.free_plan_status;
    },

    updateActivePlan: (state, action) => {
      state.user.user.active_subscription = action.payload.active_subscription;
      state.user.user.free_plan_status = action.payload.free_plan_status;
    },

    firstVisit: (state, action) => {
      state.visited = action.payload.visited;
    },

    removedVisited: (state, action) => {
      state.visited = null;
    },

    updateLatestStatus: (state, action) => {
      state.latestStatus = action.payload;
    },

    updateIsVisitedUser: (state, action) => {
      state.user.user.refferal_code_status = action.payload.refferal_code_status;
    },
    updateZoomAuthorizationStatus: (state, action) => {
      state.user.user.zoom_integration = action.payload.zoom_integration;
    },
    updatedIsWalkThroughTakenStatus: (state, action) => {
      state.user.user.is_walthrough_taken = action.payload.is_walthrough_taken;
    }
  }
});

export const {
  signInUser,
  signOutUser,
  updateSubscriptionInfo,
  updateProfilePic,
  updateActiveSubscription,
  updateActivePlan,
  firstVisit,
  removedVisited,
  updateLatestStatus,
  updateIsVisitedUser,
  updateZoomAuthorizationStatus,
  updatedIsWalkThroughTakenStatus
} = authSlice.actions;

export default authSlice.reducer;

// selector function
export const selectAuth = (state) => {
  return state.user;
};

export const visited = (state) => {
  return state.visited;
};

export const profilePic = (state) => {
  return state.profile_pic;
};

export const selectLatestStatus = (state) => {
  return state.latestStatus;
};
