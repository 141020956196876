import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BallTriangle, ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { GrNext, GrPrevious } from "react-icons/gr";
import ReactPaginate from "react-paginate";

import "../style.css";
import ProfileService from "../../../services/ProfileService";
import { selectAuth, signOutUser } from "../../../redux/authSlice";
import ProfileLayout from "../../../layouts/InnerLayout/ProfileLayout";

const Transaction = () => {
  const data = useSelector(selectAuth);
  const ITEMS_PER_PAGE = 5;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(transactions.length / ITEMS_PER_PAGE);

  useEffect((page) => {
    const offset = page * ITEMS_PER_PAGE;
    ProfileService.getTransaction({
      headers: {
        Authorization: `token ${data.token}`
      },
      params: {
        offset,
        limit: ITEMS_PER_PAGE
      }
    })
      .then((response) => {
        const transactionData = response?.data?.data || [];
        setTransactions(transactionData.reverse());
        setIsLoading(false);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate("/sign-in");
          toast.error("Please login again.");
          dispatch(signOutUser());
          return;
        }
        setIsLoading(false);
      });
  }, []);

  const handlePaginationClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return transactions.slice(startIndex, endIndex);
  };

  return (
    <ProfileLayout>
      <div className="profileform ">
        <div className="formheading subHeading mb-3">Transaction</div>
        {isLoading ? (
          <BallTriangle
            className=""
            height={30}
            width={30}
            radius={5}
            color="orange"
            ariaLabel="ball-triangle-loading"
            visible={true}
            wrapperStyle={{
              display: " flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "50px",
              paddingBottom: "80px"
            }}
          />
        ) : transactions.length > 0 ? (
          <div className="table-responsive ">
            <table className="transaction-table table-borderless  mt-3 text-center">
              <thead className="transaction-table-header pt-16">
                <tr className="text-start">
                  <th className="ps-4 ">Status</th>
                  <th>Transaction Id</th>
                  <th>Start Date</th>
                  {/* <th>End Date</th> */}
                  <th>Price</th>
                  <th>Plan Name</th>
                  {/* <th>Plan Interval</th> */}
                </tr>
              </thead>
              <tbody className="pt-16 ">
                  {getPaginatedData().map((transaction, index) => (                   
                  <tr key={index} className={`text-start ${index % 2 === 0 ? "table-striped" : ""}`}>
                    <td className="pt-16 px-4 py-2">{transaction.status}</td>
                    <td className="pt-16 px-2 py-2">{transaction.transaction_id}</td>
                    <td className="pt-16 px-2 py-2">{new Date(transaction.created_at).toLocaleDateString()}</td>
                    {/* <td className="pt-16 px-2 py-2">{new Date(transaction.end_date).toLocaleDateString()}</td> */}
                    <td className="pt-16 px-2 py-2">{transaction?.amount}</td>
                    <td className="pt-16 px-2 py-2">{transaction?.plan_id.name}</td>
                    {/* <td className="pt-16 px-2 py-2">
                      {transaction.plan_id.interval.charAt(0).toUpperCase() + transaction.plan_id.interval.slice(1)}
                    </td> */}
                  </tr>                    
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="responsive-padding">You have not purchased any plan.</p>
        )}
        {transactions.length > 0 && (
          <div className="pagination-container history-pagination d-flex justify-content-center align-items-center mt-5 pt-18">
            <ReactPaginate
              pageCount={pageCount}
              onPageChange={handlePaginationClick}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLabel={
                <button className="page-link">
                  <GrPrevious />
                </button>
              }
              nextLabel={
                <button className="page-link">
                  <GrNext />
                </button>
              }
              breakLabel={<span className="page-link">...</span>}
              breakClassName="break-me"
              marginPagesDisplayed={1}
              pageRangeDisplayed={3}
              forcePage={currentPage}
            />
          </div>
        )}
      </div>
    </ProfileLayout>
  );
};

export default Transaction;
